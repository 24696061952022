@import 'config/variables';
@import 'vendors/include-media';
@import 'components/header/user-nav/user-nav';

$offcanvas-toggle_icon-background: transparent !default;
$offcanvas-toggle_icon-color: $color_Black3F3F41 !default;
$offcanvas-toggle_icon-background-active: $color_transparent !default;
$offcanvas-toggle_icon-color-active: $color_Black3F3F41 !default;
$offcanvas-toggle_icon-transition-time: $transition-default-time !default;
$offcanvas-toggle_animation-direction: 'left' !default; // 'left' or 'right'
$offcanvas-toggle_text-color: $color_Black3F3F41 !default;
$offcanvas-toggle_text-color-active: $color_Black3F3F41 !default;
$offcanvas-toggle_text-transform: uppercase !default;

.#{$ns}offcanvas-toggle {
    $root: &;

    position: relative;
    display: flex;
    border: 0;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    align-self: stretch;
    height: 100%;
    width: 100%;

    @include media('>=laptop') {
        padding: 0 35px 0 0;
    }

    @if ($global_header-mobile-search-in-offcanvas) {
        width: 5.5rem;

        @include media(
            '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
        ) {
            width: auto;
        }
    }

    &,
    &:active,
    &:focus,
    &:hover {
        border: 0;
        background: $offcanvas-toggle_icon-background;
        box-shadow: none;
        border-radius: 0;
    }

    &:after {
        content: "";

        @include media('<laptop') {
            width: 23px;
            height: 16px;
            background-image: url('../images/menu.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            top: 50%;
            left: 50%;
        }

        @include media('>=laptop') {
            position: absolute;
            height: 2px;
            width: 100%;
            transform: scale(0);
            background: $offcanvas-toggle_icon-background-active;
            transition: transform $offcanvas-toggle_icon-transition-time;
            bottom: 0;

            @if ($offcanvas-toggle_animation-direction == 'right') {
                right: 0;
                transform-origin: right;
            } @else {
                left: 0;
                transform-origin: left;
            }
        }
    }

    &--active {
        &,
        &:active,
        &:focus,
        &:hover {
            background: $offcanvas-toggle_icon-background-active;
        }

        &:after {
            transform: scale(1);
        }

        #{$root}__icon {
            transform: rotate(-45deg);
            width: 2.6rem;

            &,
            &:before,
            &:after {
                background: $offcanvas-toggle_icon-color-active;
            }

            &:before,
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    &-desktop {
        @include media('<laptop') {
            display: none !important;
        }
    }

    &-mobile {
        @include media('>=laptop') {
            display: none !important;
        }
    }

    &__icon {
        padding: 0;
        margin: 0;
        position: relative;

        &,
        &:before,
        &:after {
            width: 1.8rem;
            height: 0.2rem;
            background: $offcanvas-toggle_icon-color;
            transition: background-color $offcanvas-toggle_icon-transition-time,
                transform $offcanvas-toggle_icon-transition-time;

            .page-wrapper-scroll & {
                @include media('>=laptop') {
                    width: 2.5rem;
                }
            }
        }

        .page-wrapper-scroll & {
            @include media('>=laptop') {
                width: 1.9rem;
            }
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
        }

        &:before {
            transform: translateY(-250%);
            transform: translate3d(0, -250%, 0);

            .page-wrapper-scroll & {
                @include media('>=laptop') {
                    transform: translateY(-300%);
                    transform: translate3d(0, -300%, 0);
                }
            }
        }

        &:after {
            transform: translateY(250%);
            transform: translate3d(0, 250%, 0);

            .page-wrapper-scroll & {
                @include media('>=laptop') {
                    transform: translateY(300%);
                    transform: translate3d(0, 300%, 0);
                }
            }
        }

        @if ($global_header-mobile-search-in-offcanvas) {
            display: none;

            @include media(
                '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
            ) {
                display: block;
            }

            #{$root}--active & {
                display: block;
            }
        }

        @include media('<laptop') {
            display: none !important;
        }
    }

    &__text {
        width: 100%;
        text-align: center;
        font-size: 1.6rem;
        color: $offcanvas-toggle_text-color;
        transition: color 0.3s;
        order: 1;
        margin-left: 18px;

        #{$root}--active & {
            color: $offcanvas-toggle_text-color-active;
        }

        @include media('>=tablet') {
            bottom: 2rem;
        }

        @include media('<laptop') {
            display: none !important;
        }

        .page-wrapper-scroll & {
            display: none !important;
        }
    }

    @if ($global_header-mobile-search-in-offcanvas) {
        &__menu-search-icon {
            fill: $header-user-nav_icon-color;
            width: 2.6rem;
            height: 2.6rem;

            @include media(
                '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
            ) {
                display: none;
            }

            &:hover {
                fill: $header-user-nav_icon-color-hover;
            }

            #{$root}--active & {
                display: none;
            }
        }
    }

    &-account {
        .cs-offcanvas-toggle__icon {
            display: none !important;
        }

        @include media('<tablet') {
            padding: 1.5rem 1rem;
        }

        &:after {
            content: "";
            width: 17px;
            height: 18px;
            background-image: url('../images/account.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            top: 50%;
            left: 50%;
        }
    }
}

.#{$ns}offcanvas-toggle-desktop {
    $root: &;

    position: relative;
    display: flex;
    border: 0;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    align-self: stretch;
    height: 100%;
    width: 100%;
    cursor: pointer;

    @include media('>=laptop') {
        padding: 0 15px 0 0;
    }

    @if ($global_header-mobile-search-in-offcanvas) {
        width: 5.5rem;

        @include media(
                '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
        ) {
            width: auto;
        }
    }

    &,
    &:active,
    &:focus,
    &:hover {
        border: 0;
        background: $offcanvas-toggle_icon-background;
        box-shadow: none;
        border-radius: 0;
    }

    &:after {
        content: "";

        @include media('<laptop') {
            width: 23px;
            height: 16px;
            background-image: url('../images/menu.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            top: 50%;
            left: 50%;
        }

        @include media('>=laptop') {
            position: absolute;
            height: 2px;
            width: 100%;
            transform: scale(0);
            background: $offcanvas-toggle_icon-background-active;
            transition: transform $offcanvas-toggle_icon-transition-time;
            bottom: 0;

            @if ($offcanvas-toggle_animation-direction == 'right') {
                right: 0;
                transform-origin: right;
            } @else {
                left: 0;
                transform-origin: left;
            }
        }
    }

    &--active {
        &,
        &:active,
        &:focus,
        &:hover {
            background: $offcanvas-toggle_icon-background-active;
        }

        &:after {
            transform: scale(1);
        }

        #{$root}__icon {
            transform: rotate(-45deg);
            width: 2.6rem;

            &,
            &:before,
            &:after {
                background: $offcanvas-toggle_icon-color-active;
            }

            &:before,
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    &__icon {
        padding: 0;
        margin: 0;
        position: relative;

        &,
        &:before,
        &:after {
            width: 1.8rem;
            height: 0.2rem;
            background: $offcanvas-toggle_icon-color;
            transition: background-color $offcanvas-toggle_icon-transition-time,
            transform $offcanvas-toggle_icon-transition-time;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
        }

        &:before {
            transform: translateY(-250%);
            transform: translate3d(0, -250%, 0);
        }

        &:after {
            transform: translateY(250%);
            transform: translate3d(0, 250%, 0);
        }

        @if ($global_header-mobile-search-in-offcanvas) {
            display: none;

            @include media(
                    '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
            ) {
                display: block;
            }

            #{$root}--active & {
                display: block;
            }
        }

        @include media('<laptop') {
            display: none !important;
        }
    }

    &__text {
        width: 100%;
        text-align: center;
        font-size: 1.6rem;
        color: $offcanvas-toggle_text-color;
        transition: color 0.3s;
        order: 1;
        margin-left: 18px;

        #{$root}--active & {
            color: $offcanvas-toggle_text-color-active;
        }

        @include media('>=tablet') {
            bottom: 2rem;
        }

        @include media('<laptop') {
            display: none !important;
        }

        .page-wrapper-scroll & {
            display: none !important;
        }
    }

    @if ($global_header-mobile-search-in-offcanvas) {
        &__menu-search-icon {
            fill: $header-user-nav_icon-color;
            width: 2.6rem;
            height: 2.6rem;

            @include media(
                    '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
            ) {
                display: none;
            }

            &:hover {
                fill: $header-user-nav_icon-color-hover;
            }

            #{$root}--active & {
                display: none;
            }
        }
    }

    &-account {
        .cs-offcanvas-toggle__icon {
            display: none !important;
        }

        @include media('<tablet') {
            padding: 1.5rem 1rem;
        }

        &:after {
            content: "";
            width: 17px;
            height: 18px;
            background-image: url('../images/account.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            top: 50%;
            left: 50%;
        }
    }
}

@import 'config/variables';
@import 'vendors/include-media';

.cms-home .columns .column.main {
    display: flex;
    flex-direction: column;
}

.Homepage_row_7-loggedin:not(.logged_in) {
    display: none !important;
}

.homepage_search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 0;

    @include media('>=tablet') {
        padding: 15px 0;
    }

    &_text {
        font-size: 10px;
        line-height: 12px;
        color: $color_GrayF8;
        margin: 0 9px 0 16px;

        @include media('>=tablet') {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 1px;
            margin: 0 36px;
        }
    }

    &_link {
        border: 1px solid $color_white;
        border-radius: 1px;
        font-size: 14px;
        line-height: 17px;
        color: $color_GrayF8 !important;
        padding: 12px 15px;
        flex-shrink: 0;

        @include media('>=tablet') {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.02em;
            padding: 17px 36px;
        }


        &:hover,
        &:active {
            color: $color_GrayF8 !important;
            text-decoration: none !important;
        }
    }
}

.homepage_showroom {
    padding: 74px 0 0;

    @include media('<tablet') {
        display: none !important;
    }

    .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper {
        position: static;
        height: 100% !important;

        @include media('>=tablet') {
            display: flex;
            align-items: center;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .cs-image-teaser__content-wrapper {
        padding: 0 0 0 127px !important;
        position: static;
        height: auto;
    }

    .cs-image-teaser__aspect-ratio {
        display: none !important;
    }

    .cs-image-teaser__slogan {
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: 1px;
        color: $color_Black35;
    }

    .cs-image-teaser__description {
        display: flex;
        flex-direction: column;
        margin-top: 18px;

        p {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.135em;
            color: $color_black;
            opacity: 0.18;
            max-width: 531px;
            margin-bottom: 14px !important;
        }

        strong {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.135em;
            color: $color_Black23;
            margin-bottom: 9px;
        }
    }

    .cs-image-teaser__slide .cs-image-teaser__cta-button {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.135em;
        color: $color_GrayD1;
        border-color: $color_GrayD1;
        background: transparent;
    }

    .cs-image-teaser__cta-button-span {
        padding-right: 45px !important;
        border-color: $color_GrayCB !important;
        color: $color_GrayD1 !important;

        &::before {
            content: "";
            position: absolute;
            width: 35px;
            height: 1px;
            background-color: $color_GrayD7D7D7;
            top: 22px;
            right: 16px;
        }

        &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border-color: $color_GrayD7D7D7;
            border-style: solid;
            border-width: 0 1px 1px 0;
            top: 19px;
            right: 16px;
            transform: rotate(-45deg);
        }
    }
}

.homepage_popular {
    margin: 30px 0 0;

    .cs-products-carousel__pagination {
        @include media('>=tablet') {
            display: none !important;
        }
    }

    .cs-products-carousel__nav {
        @include media('<tablet') {
            top: -41px;
        }
    }
}

.homepage-main-slider {
    @include media('<tablet') {
        order: 0;
    }

    .cs-image-teaser__nav {
        @include media('<tablet') {
            display: none !important;
        }
    }
}

.homepage_top_search {
    margin-top: 100px;

    @include media('<tablet') {
        display: none !important;
    }

    .cs-image-teaser__nav,
    .cs-image-teaser__pagination {
        display: none !important;
    }

    .cs-image-teaser__slide {
        width: auto !important;
        padding: 10px 6px;
        display: inline-block !important;
        vertical-align: top;
    }

    .cs-image-teaser__wrapper--content-under .cs-image-teaser__content-wrapper {
        padding: 0;
    }

    .cs-image-teaser__wrapper--content-under .cs-image-teaser__content {
        padding: 0;
    }

    .cs-image-teaser__slides {
        display: block;
        width: 100%;
        max-width: 1010px;
        margin: 0 auto;
        text-align: center;
        transform: none !important;
    }

    .cs-image-teaser__slide .cs-image-teaser__cta-button {
        font-size: 16px;
        background: $color_GoldD1;
        border-radius: 33px;
        padding: 13px 36px;
    }

    .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta-button-span {
        line-height: 19px;
        letter-spacing: 0.04em;
        color: $color_white;
    }

    .cs-image-teaser__content {
        margin: 0 !important;
    }

    .cs-image-teaser__cta {
        margin: 0 !important;
    }
}

.homepage_blog {
    margin: 10px 0 0;
    position: relative;

    @include media('>=tablet') {
        margin: 70px 0 0;
    }

    .widget.block-static-block {
        margin: 0;
    }

    .widget-title {
        display: none;
    }

    .row {
        @include media('>=tablet') {
            display: flex;
        }
    }

    .cs-headline {
        @include media('<tablet') {
            padding: 10px 1.5rem 15px;
        }
    }

    .post-list-container {
        @include media('<tablet') {
            padding: 0 1.5rem;
        }
    }

    .cs-image-teaser__nav {
        @include media('<tablet') {
            display: block;
        }
    }

    .cs-image-teaser__nav--prev {
        @include media('<tablet') {
            right: 61px;
        }
    }

    .cs-image-teaser__nav--next {
        @include media('<tablet') {
            right: 15px;
        }
    }

    .post-list-item {
        margin-bottom: 0;

        @include media('>=tablet') {
            margin: 0 !important;
            width: 33.3333%;
        }
    }

    .post-item-wraper {
        border: 0 none !important;
        padding: 0 !important;

        @include media('>=tablet') {
            padding: 0 57px !important;
        }

        &:hover {
            border: 0 none !important;
            box-shadow: none !important;
        }
    }

    .post-image {
        position: relative;

        @include media('>=tablet') {
            margin: 0 -57px;
        }
    }

    .mp-post-info {
        background: rgba(255, 255, 255, 0.84);
        color: $color_black;
        width: 52px;
        height: 52px;
        position: absolute;
        top: 9px;
        left: 7px;
        font-size: 10px;
        line-height: 12px;
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 50%;
        overflow: hidden;

        @include media('>=tablet') {
            width: 121px;
            height: 121px;
            top: 30px;
            left: 30px;
            font-size: 18px;
            line-height: 22px;
        }

        span {
            color: $color_black;
            font-weight: 600;
            display: block;
            border-bottom: 1px solid $color_GrayCC;
            padding-bottom: 5px;
            margin-bottom: 4px;

            @include media('>=tablet') {
                border-bottom : 0 none;
                padding-bottom: 0;
                margin-bottom: 7px;
            }

            &::after {
                @include media('>=tablet') {
                    content: "";
                    width: 38px;
                    height: 0;
                    border: 1px solid $color_GrayCC;
                    margin: 6px auto 0;
                    display: block;
                }
            }
        }
    }

    .post-info-wraper .mp-post-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $color_Black38;
        margin: 0 0 11px;
        text-transform: none;

        @include media('>=tablet') {
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.01em;
            margin: 0 0 20px;
        }
    }

    .post-short-description {
        font-size: 12px;
        line-height: 14px;
        color: $color_Black38;
        opacity: 0.18;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media('>=tablet') {
            font-size: 16px;
            line-height: 19px;
            -webkit-line-clamp: 3;
        }
    }

    .post-link-title {
        color: $color_Black38;
    }

    .post-static-title {
        display: none;

        @include media('>=tablet') {
            color: $color_Black38;
            font-weight: 300;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.01em;
            display: block;
            margin:  0 0 10px;
        }
    }

    .post-info-wraper {
        padding: 11px 0;

        @include media('>=tablet') {
            padding: 31px 0;
        }
    }

    .mp-read-more {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: $color_black;
        position: relative;
        padding-right: 45px;
        display: none;
        margin-top: 19px;

        @include media('>=tablet') {
            display: inline-block;
        }

        span {
            opacity: 0.18;
        }

        &::before {
            content: "";
            position: absolute;
            width: 35px;
            height: 1px;
            background-color: $color_GrayD6D6D6;
            top: 9px;
            right: 0;
        }

        &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border-color: $color_GrayD6D6D6;
            border-style: solid;
            border-width: 0 1px 1px 0;
            top: 6px;
            right: 0;
            transform: rotate(-45deg);
        }

        &:hover {
            color: $color_black;
            text-decoration: none;
        }
    }
}

.homepage_take_peek {
    margin: 30px 0 0;

    @include media('>=tablet') {
        max-width: 1556px;
        margin: 100px auto 0;
    }

    .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper {
        position: static;
        height: 100% !important;

        @include media('>=tablet') {
            display: flex;
            align-items: center;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .cs-image-teaser__content-wrapper {
        padding: 0 !important;
        position: static;
        height: auto;
        max-width: 600px;
    }

    .cs-image-teaser__aspect-ratio {
        display: none !important;
    }

    .cs-image-teaser__figure {
        order: 1;
    }

    .cs-image-teaser__slogan {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);

        @include media('<tablet') {
            display: none !important;
        }

        @include media('>=tablet') {
            font-weight: 600;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: 1px;
        }
    }

    .cs-image-teaser__description {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        max-width: 531px;

        @include media('>=tablet') {
            margin-top: 48px;
        }

        strong {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $color_Black38;

            @include media('>=tablet') {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 1px;
            }
        }

        p {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.03em;
            color: $color_black;
            opacity: 0.18;
            margin-top: 6px !important;

            @include media('>=tablet') {
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 1px;
                margin-top: 30px !important;
            }
        }
    }

    .cs-image-teaser__slide .cs-image-teaser__cta-button {
        background: none;
        border: 1px solid $color_GrayC1;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 14px;
        display: block;

        @include media('>=tablet') {
            display: inline-block;
            padding: 18px 40px;
        }
    }

    .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta-button-span {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: $color_GrayC1;

        @include media('>=tablet') {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.135em;
            color: $color_GrayD1;
        }
    }

    .cs-image-teaser__cta {
        margin-top: 9px;

        @include media('>=tablet') {
            margin-top: 31px;
        }
    }
}

.homepage_instagram {
    overflow: hidden;
    position: relative;

    @include media('<tablet') {
        margin-top: 30px;
    }

    .block-static-block {
        margin: 0 -2px 0;

        @include media('>=tablet') {
            margin: 120px -3px 0;
        }
    }

    .cs-headline {
        @include media('<tablet') {
            padding: 10px 1.5rem 13px;
        }
    }

    .cs-image-teaser__nav {
        @include media('<tablet') {
            display: block;
        }
    }

    .cs-image-teaser__nav--prev {
        @include media('<tablet') {
            right: 61px;
        }
    }

    .cs-image-teaser__nav--next {
        @include media('<tablet') {
            right: 15px;
        }
    }
}

.homepage_recently_viewed_products {
    .cs-products-carousel__pagination {
        display: none !important;
    }

    .block.products-upsell {
        @include media('>=tablet') {
            display: flex;
            align-items: center;
        }
    }

    .cs-container--headline {
        @include media('>=tablet') {
            flex-shrink: 0;
            padding-right: 60px !important;
            width: auto;
        }
    }

    .cs-headline__title,
    .cs-headline {
        margin: 0;
    }

    .cs-container--products-carousel {
        @include media('>=tablet') {
            width: calc(100% - 446px);
            padding: 0 52px !important;
        }
    }

    .cs-products-carousel:not(.cs-products-carousel--full-width-slider) {
        @include media('>=tablet') {
            .cs-products-carousel__nav--next {
                right: -52px;
            }

            .cs-products-carousel__nav--prev {
                left: -52px;
            }
        }
    }
}

.homepage_collections {
    .cs-container--headline {
        @include media('>=tablet') {
            display: none !important;
        }
    }

    .cs-image-teaser__wrapper {
        margin: 0;
        width: 100%;
    }

    .cs-image-teaser__nav {
        @include media('<tablet') {
            top: -41px;
        }
    }

    .cs-container--hero-teaser {
        @include media('<tablet') {
            overflow: visible;
        }
    }
}

.homepage_shopby_categories {
    .cs-headline {
        margin-bottom: 15px;
    }

    .cs-image-teaser {
        margin: 0;
    }
    .cs-image-teaser__slide {
        width: 50%;
        padding: 0 !important;
        margin: 0 0 14px;
    }

    .cs-image-teaser__content-wrapper {
        position: static;
        padding: 0 !important;
        align-items: center;
    }

    .cs-image-teaser__slide-wrapper {
        height: 100% !important;
        display: flex;
        align-items: center;
        position: static !important;
    }

    .cs-image-teaser__aspect-ratio {
        display: none !important;
    }

    .cs-image-teaser__figure {
        width: 54px;
        padding-right: 12px;
        flex-shrink: 0;
    }

    .cs-image-teaser__slogan {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $color_Black3F3F41;
    }

    .cs-image-teaser__cta {
        margin-top: 0;
    }

    .cs-image-teaser__cta-button {
        width: 100%;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: $color_GrayC1;
        background: transparent;
        border-color: $color_GrayC1;

        span {
            color: $color_GrayC1;
            padding-right: 45px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                width: 20px;
                height: 1px;
                background-color: $color_GrayC1;
                top: 6px;
                right: 16px;
            }

            &::after {
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                border-color: $color_GrayC1;
                border-style: solid;
                border-width: 0 1px 1px 0;
                top: 4px;
                right: 16px;
                transform: rotate(-45deg);
            }
        }
    }
}

.homepage_categories {
    @include media('<tablet') {
        margin-top: 30px;
    }
}

.Homepage_row_0 {
    @include media('<tablet') {
        order: 1;
    }
}

.Homepage_row_1_mobile {
    @include media('<tablet') {
        order: 2;
    }

    @include media('>=tablet') {
        display: none !important;
    }
}

.Homepage_row_1 {
    @include media('<tablet') {
        order: 3;
    }
}

.Homepage_row_2 {
    @include media('<tablet') {
        order: 4;
    }
}

.Homepage_row_3 {
    &.cs-container {
        @include media('<tablet') {
            order: 4;
            margin-top: 10px;
        }
    }
}

.Homepage_row_5,
.homepage_row_55,
.Homepage_row_55 {
    @include media('<tablet') {
        order: 5;
    }
}

.Homepage_row_7 {
    @include media('<tablet') {
        order: 6;
    }
}

.Homepage_row_8 {
    @include media('<tablet') {
        order: 8;
    }
}

.Homepage_row_9 {
    @include media('<tablet') {
        order: 9;
    }
}

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-select {
  min-width: 0.5px;
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  height: 100%;
  padding: 0;
  opacity: 0;
  border: none; }
  .cs-select--mobile {
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    z-index: 4; }

.cs-html-select {
  position: relative;
  display: block;
  min-width: 15rem;
  box-sizing: border-box;
  z-index: 2; }
  .cs-html-select.open {
    z-index: 99; }
  .cs-html-select__trigger {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    width: 100%;
    min-width: 13rem;
    text-align: left;
    z-index: 3;
    overflow: hidden;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #dcdcdc;
    padding: 0.75em 1em;
    padding-right: 2.3em;
    border-radius: 2px;
    box-shadow: none;
    font-size: 1em; }
    .cs-limiter .cs-html-select__trigger,
    .cs-sorter .cs-html-select__trigger {
      border-color: #383838; }
    .cs-html-select__trigger--disabled {
      opacity: 0.65;
      cursor: not-allowed; }
    .cs-html-select--error .cs-html-select__trigger {
      border-color: #f46667;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .cs-html-select--open .cs-html-select__trigger,
    .cs-html-select.open .cs-html-select__trigger {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #383838;
      border-bottom: 1px solid #dcdcdc; }
    .cs-limiter .cs-html-select--open .cs-html-select__trigger,
    .cs-limiter .cs-html-select.open .cs-html-select__trigger,
    .cs-sorter .cs-html-select--open .cs-html-select__trigger,
    .cs-sorter .cs-html-select.open .cs-html-select__trigger {
      border-bottom-color: #383838; }
  .cs-html-select__trigger-text {
    color: #212121;
    line-height: 1.275em;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1em;
    margin-right: 2rem; }
  .cs-html-select__trigger-caret {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .cs-html-select__trigger-caret-arrow {
    display: block;
    position: relative; }
    .cs-html-select__trigger-caret-arrow:after {
      display: none !important; }
    .cs-html-select__trigger-caret-arrow:before {
      content: "";
      width: 0.8rem;
      height: 0.8rem;
      position: absolute;
      border-style: solid;
      border-width: 0.4rem;
      border-color: transparent #383838 #383838 transparent;
      right: 1.7rem;
      transform: rotate(45deg) translate3d(0, 0, 0);
      transition: transform 0.15s;
      top: -7px; }
      .cs-html-select--open .cs-html-select__trigger-caret-arrow:before {
        transform: rotate(225deg) translate3d(0, 0, 0);
        top: -2px; }
  .cs-html-select__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    border: 1px solid #383838;
    border-top: 1px solid #dcdcdc;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: #fff;
    box-shadow: 0 1px 5px 0 rgba(100, 100, 100, 0.2);
    margin-top: -1px;
    min-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    transform: translate3d(0, 0, 0); }
    .cs-limiter .cs-html-select__menu,
    .cs-sorter .cs-html-select__menu {
      top: calc(100% + 4px);
      border-color: #383838; }
    .cs-html-select--open .cs-html-select__menu {
      display: block;
      opacity: 1; }
    .cs-html-select--autosuggest:not(.cs-html-select--open) .cs-html-select__menu {
      padding: 0;
      border: none; }
  .cs-html-select__menu-list {
    margin: 0;
    padding: 0;
    list-style: none; }
  .cs-html-select__menu-item {
    margin: 0;
    padding: 0;
    border-top: none; }
    .cs-html-select__menu-item--hidden {
      display: none; }
  .cs-html-select__menu-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 1em;
    height: 2.8em;
    color: #212121;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.15s linear, color 0.15s linear; }
    .cs-html-select__menu-item--selected .cs-html-select__menu-link {
      color: #fff;
      background-color: #383838; }
    .cs-limiter .cs-html-select__menu-item--selected .cs-html-select__menu-link,
    .cs-sorter .cs-html-select__menu-item--selected .cs-html-select__menu-link {
      color: #EC0057;
      background-color: #fff; }
    .cs-html-select__menu-link:hover, .cs-html-select__menu-link:focus,
    .cs-html-select__menu-item--focused .cs-html-select__menu-link {
      background-color: #383838;
      color: #fff;
      text-decoration: none;
      outline: none; }
    .cs-limiter .cs-html-select__menu-link:hover,
    .cs-sorter .cs-html-select__menu-link:hover, .cs-limiter .cs-html-select__menu-link:focus,
    .cs-sorter .cs-html-select__menu-link:focus {
      background-color: #fff;
      color: #EC0057; }
  .cs-html-select__menu-item-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .cs-html-select__text-image-wrapper {
    display: flex;
    align-items: center; }
  .cs-html-select__thumbnail {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    margin-right: 1rem; }
  .cs-html-select--autosuggest {
    padding: 0; }
    .cs-html-select--autosuggest .cs-html-select__menu {
      overflow-y: hidden;
      max-height: 220px; }
    .cs-html-select--autosuggest .cs-html-select__menu-list {
      max-height: 220px; }
    .cs-html-select--autosuggest:not(.cs-html-select--animate) .cs-html-select__menu {
      padding: 0;
      border: none; }

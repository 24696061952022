/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
@media (min-width: 48em) {
  .cs-instagram-feed-swiper-container {
    padding-left: 32.8% !important; } }

@media (max-width: 47.99em) {
  .cs-instagram-feed-swiper-container .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column; } }

@media (min-width: 48em) {
  .cs-instagram-feed-swiper-container .swiper-wrapper {
    transform: none;
    display: block;
    position: static; }
    .cs-instagram-feed-swiper-container .swiper-wrapper::after {
      content: "";
      clear: both;
      display: block; } }

@media (max-width: 47.99em) {
  .cs-instagram-feed-swiper-container .swiper-slide {
    height: calc((100% - 4px) / 2);
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 2px; }
    .cs-instagram-feed-swiper-container .swiper-slide img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      width: 0;
      min-width: 100%;
      min-height: 100%; } }

@media (min-width: 48em) {
  .cs-instagram-feed-swiper-container .swiper-slide {
    width: 25%;
    height: auto;
    float: left;
    padding: 3px;
    display: none !important; }
    .cs-instagram-feed-swiper-container .swiper-slide:nth-child(1), .cs-instagram-feed-swiper-container .swiper-slide:nth-child(2), .cs-instagram-feed-swiper-container .swiper-slide:nth-child(3), .cs-instagram-feed-swiper-container .swiper-slide:nth-child(4), .cs-instagram-feed-swiper-container .swiper-slide:nth-child(5), .cs-instagram-feed-swiper-container .swiper-slide:nth-child(6), .cs-instagram-feed-swiper-container .swiper-slide:nth-child(7), .cs-instagram-feed-swiper-container .swiper-slide:nth-child(8), .cs-instagram-feed-swiper-container .swiper-slide:nth-child(9) {
      display: block !important; }
    .cs-instagram-feed-swiper-container .swiper-slide:first-child {
      width: 32.8%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0; }
      .cs-instagram-feed-swiper-container .swiper-slide:first-child img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        -o-object-fit: cover;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        width: 0;
        min-width: 100%;
        min-height: 100%; }
    .cs-instagram-feed-swiper-container .swiper-slide img {
      vertical-align: top;
      width: 100%; } }

.cs-instagram-feed-link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .cs-instagram-feed-link::before {
    background-image: url("../images/instagram_bkg_icon.png");
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba(11, 11, 11, 0.64);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 1; }
  .cs-instagram-feed-link:hover::before {
    opacity: 1; }

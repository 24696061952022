@import 'config/variables';
@import 'vendors/include-media';
@import 'components/button/mixin';
@import 'components/field/mixin';
@import 'utils/visually-hidden';
@import 'etc/view.scss';
@import 'utils/deep-get/deep-get.scss';

$minicart-offcanvas: deep-get(
    $view-xml,
    vars,
    Magento_Checkout,
    minicart_offcanvas,
    enabled
) !default;

$minicart-product_action-icon-color: $color_primary-500 !default;
$minicart-product_action-icon-color-hover: $color_primary-800 !default;

$minicart-offcanvas-product_action-icon-color: $color_text-800 !default;
$minicart-offcanvas-product_action-icon-color--hover: $minicart-offcanvas-product_action-icon-color !default;

$minicart-product_brand-font-size: 1.2rem !default;
$minicart-product_brand-color: $color_text-400 !default;
$minicart-product_brand-alignment: left !default;

$minicart-product_overview-font-size: 1.4rem !default;
$minicart-product_name-color: $color_black !default;
$minicart-product_name-color-hover: $color_black !default;
$minicart-product_name-padding: 1.2rem !default;
$minicart-product_name-font-weight: 600 !default;

$minicart-product-options-title-color: $color_text-600 !default;
$minicart-product_options-font-size: 1.2rem !default;
$minicart-product_options-label-color: $color_text-400 !default;
$minicart-product_options-value-color: $color_text-400 !default;
$minicart-product_options_toggle-padding: 1.2rem !default;
$minicart-product-options_toggle-disable: false !default;

$minicart-offcanvas-product-options-title-color: $color_text-800 !default;
$minicart-offcanvas-product_options-title-font-size: 1.3rem !default;
$minicart-offcanvas-product_options-label-font-size: 1.1rem !default;
$minicart-offcanvas-product-options-label-color: $color_text-800 !default;
$minicart-offcanvas-product-options-label-font-weight: 700 !default;

$minicart-offcanvas-product_action-icon-size: 2rem !default;

$minicart-product_price-color: $color_Black38 !default;

$minicart-product_qty-enable: true !default;
$minicart-product_qty-height: 3rem !default;
$minicart-product_qty-font-size: 1.2rem !default;
$minicart-product_qty-button-type: $button_default-type !default;
$minicart-product_qty-button-icon-pos: 'only-icon' !default;

.#{$ns}minicart-product {
    $root: &;

    display: flex;

    &__image-container {
        align-items: center;
        display: flex;
        margin-right: 1.6rem;

        @include media('>=tablet') {
            flex-shrink: 0;
        }

        @include media('<tablet') {
            width: 50px;
        }

        img {
            width: auto !important;
            height: auto !important;
        }

        .checkout-index-index & {
            width: 50px !important;
            height: auto !important;
        }
    }

    &__image {
        max-height: 100%;
        width: auto;
    }

    &__overview {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
        width: 60%;
        padding-right: 10px;
    }

    &__details {
        width: 100%;
        display: flex;

        .checkout-index-index & {
            flex-direction: column;
        }
    }

    &__brand-wrapper {
        text-align: $minicart-product_brand-alignment;
    }

    &__brand {
        font-size: $minicart-product_brand-font-size;
        color: $minicart-product_brand-color;
    }

    &__name-wrapper {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: $color_black;
        flex-basis: 55%;
        padding-bottom: $minicart-product_name-padding;
        text-align: left;
        font-size: $minicart-product_overview-font-size;

        @include media('>=phoneLg') {
            flex-basis: 70%;
        }

        @if $minicart-offcanvas {
            flex-basis: 100% !important;
        }
    }

    &__name {
        color: $minicart-product_name-color;

        &:visited,
        &:hover {
            text-decoration: none;
            color: $minicart-product_name-color-hover;
        }
    }

    &__name-qty {
        display: inline-block;

        &:before {
            content: "(";
        }

        &:after {
            content: ")";
        }

        .count {
            &:before {
                content: "x";
            }
        }
    }

    &__price {
        .price {
            font-size: $minicart-product_overview-font-size;
            font-weight: 400;
            color: $minicart-product_price-color;
        }

        @if $minicart-offcanvas {
            .price-including-tax,
            .price-excluding-tax {
                font-size: 1.6rem;

                .price {
                    font-weight: 400;
                    font-size: 1.6rem;
                }
            }

            .price {
                font-weight: 400;
            }
        }

        .price-including-tax {
            &:before {
                display: none;
            }
        }
    }

    &__price-label,
    &__options-subtitle {
        display: none;
    }

    &__options-title {
        @if ($minicart-product-options_toggle-disable) {
            display: none;
        } @else {
            font-size: $minicart-product_options-font-size;
            color: $minicart-product-options-title-color;
            cursor: pointer;

            &[aria-expanded='true'] {
                > #{$root}__options-title-svg {
                    transform: rotate(180deg);
                    vertical-align: initial;
                }
            }
        }

        @if $minicart-offcanvas {
            font-size: $minicart-offcanvas-product_options-title-font-size;
            color: $minicart-offcanvas-product-options-title-color;
        }
    }

    &__options-title-svg {
        width: 1rem;
        height: 1rem;
        margin-left: 0.5rem;
        transition: transform $transition-default-time
            $transition-easing_slowdown-900;
        vertical-align: middle;
    }

    &__options-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        margin-top: -0.4rem;
        flex-basis: 100%;

        @if $minicart-offcanvas {
            padding-bottom: $minicart-product_options_toggle-padding;
        }
    }

    &__options-group {
        margin: 0;
        width: 100%;
        display: flex;
        align-items: center;

        &--qty {
            margin-top: 0.5rem;

            #{$root}__options-wrapper + & {
                margin-top: 1.5rem;
            }
        }
    }

    &__options-content {
        width: 100%;

        @if ($minicart-product-options_toggle-disable) {
            display: block !important;
        }

        @if $minicart-offcanvas {
            padding-top: 0.5rem;
        }
    }

    &__options-label {
        font-size: $minicart-product_options-font-size;
        color: $minicart-product_options-label-color;
        font-weight: 400;
        margin-bottom: 0;
        text-align: left;
        padding-right: 0.5rem;

        &:after {
            content: ':';
        }

        @if $minicart-offcanvas {
            font-size: $minicart-offcanvas-product_options-label-font-size;
            font-weight: $minicart-offcanvas-product-options-label-font-weight;
            color: $minicart-offcanvas-product-options-label-color;
            text-transform: uppercase;
        }
    }

    &__options-value {
        font-size: $minicart-product_options-font-size;
        color: $minicart-product_options-value-color;
        margin-bottom: 0;
        width: 100%;

        &__value {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .price {
                color: $minicart-product_price-color;
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
    }

    &__action-wrapper {
        &.primary {
            display: none;
        }

        &.secondary {
            width: 15px;
            height: 15px;
            overflow: hidden;
            flex-shrink: 0;

            .action.delete {
                width: 100%;
                height: 100%;
                position: relative;
                display: block;
                transform: rotate(45deg);

                &:before,
                &:after {
                    content: "";
                    width: 100%;
                    height: 0.1rem;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate3d(0, -50%, 0) !important;
                    background: $color_tundora;
                    backface-visibility: hidden;
                }

                &:after {
                    transform: translate3d(0, -50%, 0) rotate(90deg) !important;
                }

                svg {
                    display: none;
                }
            }
        }
    }

    @if ($minicart-product_qty-enable) {
        &__qty,
        .cs-qty-increment {
            //display: flex;
            //flex: 1 0 9rem;
            //flex-wrap: nowrap;
            padding: 0 40px;

            input.item-qty {
                width: 0;
                height: 26px;
                opacity: 0;
                overflow: hidden;
                padding: 0;
                border: 0;
                max-width: 0;
                font-size: 0;
            }
        }

        .cs-qty-increment {
            .cs-qty-increment__button {
                border-color: $color_Black38;
                border-radius: 0;
                max-width: 4rem;
                height: 26px;

                * {
                    fill: $color_Black38;
                }

                &:hover,
                &:active {
                    background-color: $color_Black38;
                    border-color: $color_Black38;

                    * {
                        fill: $color_white;
                    }
                }

                &--decrement {
                    right: auto;
                    left: 0;
                }
            }

            .cs-qty-increment__icon--decrement {
                width: 1rem;
                height: 0.15rem;
            }

            .cs-qty-increment__icon--increment {
                width: 0.838rem;
                height: 0.835rem;
            }
        }

        &__qty-label {
            display: none;
        }

        &__qty-input {
            @include field-input();

            padding: 0;
            text-align: center;
            box-sizing: border-box;
            margin-bottom: 0;
            height: $minicart-product_qty-height;
            width: 4rem;
            max-width: 4rem;
            font-size: $minicart-product_qty-font-size;
        }

        &__qty-button {
            @include button(
                $type: $minicart-product_qty-button-type,
                $icon_pos: $minicart-product_qty-button-icon-pos
            );

            padding: 0;
            width: 3rem;
            height: $minicart-product_qty-height;
            margin-left: 0.5rem;
        }

        &__qty-button-span {
            @include button_span(
                $type: $minicart-product_qty-button-type,
                $icon_pos: $minicart-product_qty-button-icon-pos
            );
        }

        &__qty-button-icon {
            @include button_icon(
                $type: $minicart-product_qty-button-type,
                $icon_pos: $minicart-product_qty-button-icon-pos
            );
        }
    } @else {
        &__qty,
        .cs-qty-increment {
            display: none;
        }
    }

    &__action,
    &__options-title {
        &:hover {
            #{$root}__options-title-svg,
            #{$root}__action-icon {
                * {
                    fill: $minicart-product_action-icon-color-hover;
                }
            }
        }
    }

    &__action-icon {
        width: 1.6rem;
        height: 1.6rem;

        @if $minicart-offcanvas {
            width: $minicart-offcanvas-product_action-icon-size;
            height: $minicart-offcanvas-product_action-icon-size;
        }
    }

    &__action-icon,
    &__options-title-svg {
        * {
            fill: $minicart-product_action-icon-color;
        }
    }

    &__action-label {
        @include visually-hidden();
    }

    @if $minicart-offcanvas {
        &__options-title {
            &:hover {
                #{$root}__options-title-svg {
                    * {
                        fill: $minicart-offcanvas-product_action-icon-color--hover;
                    }
                }
            }
        }

        &__options-title-svg {
            * {
                fill: $minicart-offcanvas-product_action-icon-color;
            }
        }
    }
}

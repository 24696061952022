/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-button {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: none;
  padding: 0.75em 1em;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color; }
  .cs-button:hover, .cs-button:focus, .cs-button:active {
    background-color: #383838 !important; }
  .cs-button:before, .cs-button:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px; }
  .cs-button:before {
    width: 3.8em;
    border-radius: 0 2px 2px 0;
    z-index: -1;
    background: linear-gradient(100deg, transparent 0%, transparent 15%, #383838 15%, #383838 100%); }
  .cs-button:after {
    width: 3em;
    min-width: auto;
    background-color: #383838;
    transition: width 0.25s linear;
    z-index: -2;
    border-radius: 2px; }
  .cs-button:hover:after {
    width: calc(100% + 2px); }
  .cs-button::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-button, .cs-button:visited {
    cursor: pointer;
    border-radius: 2px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #383838; }
    .cs-button *, .cs-button:visited * {
      color: #fff;
      fill: #fff; }
  .cs-button:hover:not([disabled]), .cs-button:focus:not([disabled]), .cs-button:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #383838; }
    .cs-button:hover:not([disabled]) *, .cs-button:focus:not([disabled]) *, .cs-button:active:not([disabled]) * {
      color: #fff;
      fill: #fff; }
  .cs-button[disabled] {
    opacity: 0.65;
    cursor: auto; }
  .cs-button__span {
    margin-top: 0;
    z-index: 1;
    font-weight: 400;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-left: 0;
    padding-right: 4em; }
  .cs-button__icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em; }

.cs-button-component {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: none;
  padding: 0.75em 1em;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color;
  margin: 0 0 3rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  .cs-button-component:hover, .cs-button-component:focus, .cs-button-component:active {
    background-color: #383838 !important; }
  .cs-button-component:before, .cs-button-component:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px; }
  .cs-button-component:before {
    width: 3.8em;
    border-radius: 0 2px 2px 0;
    z-index: -1;
    background: linear-gradient(100deg, transparent 0%, transparent 15%, #383838 15%, #383838 100%); }
  .cs-button-component:after {
    width: 3em;
    min-width: auto;
    background-color: #383838;
    transition: width 0.25s linear;
    z-index: -2;
    border-radius: 2px; }
  .cs-button-component:hover:after {
    width: calc(100% + 2px); }
  .cs-button-component::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-button-component, .cs-button-component:visited {
    cursor: pointer;
    border-radius: 2px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #383838; }
    .cs-button-component *, .cs-button-component:visited * {
      color: #fff;
      fill: #fff; }
  .cs-button-component:hover:not([disabled]), .cs-button-component:focus:not([disabled]), .cs-button-component:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #383838; }
    .cs-button-component:hover:not([disabled]) *, .cs-button-component:focus:not([disabled]) *, .cs-button-component:active:not([disabled]) * {
      color: #fff;
      fill: #fff; }
  .cs-button-component[disabled] {
    opacity: 0.65;
    cursor: auto; }
  @media (min-width: 48em) {
    .cs-button-component {
      margin: 0 0 4rem; } }
  .cs-button-component--skip-spacing {
    margin: 0; }
  .cs-button-component__span {
    margin-top: 0;
    z-index: 1;
    font-weight: 400;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-left: 0;
    padding-right: 4em; }
  .cs-button-component__icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em; }
  .cs-button-component__amount:before {
    content: '('; }
  .cs-button-component__amount:after {
    content: ')'; }

.ui-switchbutton {
  display: inline-block;
  padding: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none; }

.ui-switchbutton input {
  display: none; }

.ui-switchbutton label {
  white-space: nowrap;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 0;
  width: auto;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none; }

.ui-switchbutton label.ui-switchbutton-disabled {
  right: 0;
  text-align: right;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.6); }

.ui-switchbutton label.ui-switchbutton-disabled span {
  display: inline-block;
  text-align: center;
  padding-left: 2px;
  padding-right: 3px; }

.ui-switchbutton.ui-switchbutton-no-labels label span {
  width: 1em; }

.ui-switchbutton label.ui-switchbutton-enabled {
  left: 0;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6); }

.ui-switchbutton label.ui-switchbutton-enabled span {
  display: inline-block;
  text-align: center;
  padding-left: 3px;
  padding-right: 2px; }

.ui-switchbutton .ui-switchbutton-handle {
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer; }

.ui-switchbutton.ui-switchbutton-disabled {
  opacity: .4;
  -ms-filter: alpha(opacity=40);
  filter: alpha(opacity=40);
  filter: Alpha(Opacity=40);
  cursor: default; }

.ui-switchbutton.ui-switchbutton-disabled label, .ui-switchbutton.ui-switchbutton-disabled .ui-switchbutton-handle {
  cursor: default; }

.ui-switchbutton-default {
  height: 24px; }

.ui-switchbutton-default label {
  font-size: 14px;
  line-height: 12px;
  font-weight: bold;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  height: 20px;
  padding-top: 4px; }

.ui-switchbutton-default label.ui-switchbutton-disabled {
  color: #8b8b8b;
  background: #E7E7E7; }

.ui-switchbutton-default label.ui-switchbutton-enabled {
  color: white;
  background: #488fe4;
  padding-right: 4px; }

.ui-switchbutton-default .ui-switchbutton-handle {
  height: 12px;
  background: #383838;
  border: 1px solid #383838;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px; }

.ui-switchbutton-thin {
  height: 12px;
  border-radius: 6px;
  overflow: hidden; }

.ui-switchbutton-thin label {
  font-size: 13px;
  line-height: 16px;
  height: 12px;
  padding-top: 2px; }

.ui-switchbutton-thin .ui-switchbutton-handle {
  height: 12px; }

.ui-switchbutton-ios5 label.ui-switchbutton-disabled {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px; }

.ui-switchbutton-ios5 label.ui-switchbutton-enabled {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0; }

.ui-switchbutton-ios5 label.ui-switchbutton-enabled {
  padding-right: 0; }

.ui-switchbutton-ios5.ui-state-active label.ui-switchbutton-enabled {
  padding-right: 10px; }

.ui-switchbutton-ios5 .ui-switchbutton-handle {
  height: 22px;
  width: 22px !important;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px; }

@import 'config/variables';
@import 'vendors/include-media';

$page-bottom_text-color: $color_text-500 !default;
$page-bottom_divider-color: $color_border-500 !default;

.#{$ns}page-bottom {
    $root: &;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media('>=laptop') {
        justify-content: flex-start;
        height: 56px;
        justify-content: space-between;
    }

    .page-print & {
        display: none;
    }

    &__image {
        height: 3rem;
        width: auto;

        &--circle {
            width: 4.7rem;
            height: 4.7rem;
            min-width: 4.7rem;
            min-height: 4.7rem;
            margin-right: 1rem;
        }
    }

    &__text {
        font-size: 1.2rem;
        margin-bottom: 0;

        &--small {
            font-size: 1rem;
        }

        @include media('>=laptop') {
            font-size: 1.3rem;
        }
    }

    &__group_desktop {
        @include media('<laptop') {
            display: none !important;
        }
    }

    &__group {
        display: flex;
        align-items: center;

        @include media('>=laptop') {
            width: auto;
            padding: 0;
        }

        &:first-child {
            @include media('>=laptop') {
                margin-bottom: auto;
            }
        }

        &--info {
            display: none;
            flex-direction: row-reverse;

            @include media('>=laptop') {
                display: flex;
                flex-basis: 40%;
                justify-content: flex-end;
                padding-right: 1.5rem;

                #{$root}__text {
                    max-width: 20rem;
                }
            }

            @include media('>=laptop') {
                flex-basis: 17%;
                padding-right: 0;

                #{$root}__text {
                    max-width: 100%;
                }
            }
        }

        &--shipping {
            display: none;

            @include media('>=laptop') {
                display: flex;
                flex-basis: 55%;
                padding-left: 1.5rem;
            }

            @include media('>=laptop') {
                flex-basis: 33%;
                padding-left: 4rem;
            }
        }

        &--payment {
            flex-basis: 50%;

            @include media('<laptop') {
                #{$root}__text {
                    display: none;
                }
            }

            @include media('>=laptop') {
                padding-left: 4rem;
            }
        }

        &--shipping,
        &--payment {
            @include media('>=laptop') {
                position: relative;

                &:before {
                    margin: 0 1rem;
                    content: '';
                    position: absolute;
                    left: 1rem;
                    top: 50%;
                    width: 0.2rem;
                    height: 2rem;
                    background: $page-bottom_divider-color;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &__logo {
        display: flex;
        flex-shrink: 1;
        height: 2rem;

        @include media('<laptop') {
            width: auto;
        }

        &--hermes {
            width: 12.2rem;
        }

        &--dhl {
            height: auto;
            max-height: 2rem;
            min-width: 8.6rem;
            padding-left: 10px;
        }

        &--paypal {
            width: 6.2rem;
        }

        &--visa {
            height: auto;
            max-height: 2rem;
            min-width: 5.5rem;
        }

        &--mastercard {
            width: 3.3rem;
        }

        &--klarna-sofort {
            height: auto;
            max-height: 2.5rem;
            min-height: 2.5rem;
        }

        &--klarna {
            width: 7rem;
        }

        &--gls {
            width: 5rem;
        }

        &--giropay {
            width: 4.8rem;
        }

        &--ec {
            width: 1.8rem;
        }

        &--paydirekt {
            width: 6rem;
        }

        &--lastschrift {
            width: 6.15rem;
        }

        &--vorkasse {
            width: 8.3rem;
        }

        &--rechnung {
            width: 6.15rem;
        }

        & + & {
            margin-left: 0.5rem;
        }
    }
}

.cs-container {
    &--page-bottom {
        max-width: none;
        padding-top: 82px;
        background: $color_GrayF8;

        @include media('<laptop') {
            padding-bottom: 17px;
        }

        @include media('>=tablet') {
            padding-top: 51px;
        }

        @include media('>=laptop') {
            background: $color_white;
        }
    }
}

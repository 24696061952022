/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
.cs-offcanvas-navigation {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff; }
  .cs-offcanvas-navigation__list {
    z-index: 1;
    width: 100%;
    min-width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    display: none; }
    .cs-offcanvas-navigation__list--level_0, .cs-offcanvas-navigation__list--current, .cs-offcanvas-navigation__list--active {
      display: block; }
    .cs-offcanvas-navigation__list--level_0, .cs-offcanvas-navigation__list--current {
      overflow-y: auto; }
    .cs-offcanvas-navigation__list--current > .cs-offcanvas-navigation__item > .cs-offcanvas-navigation__list {
      display: block; }
    .cs-offcanvas-navigation__list .cs-offcanvas-navigation__list {
      position: absolute;
      left: 105%;
      height: 100%;
      top: 0;
      transition: transform 0.3s; }
      .cs-offcanvas-navigation__list .cs-offcanvas-navigation__list--active {
        transform: translateX(-105%);
        transform: translate3d(-105%, 0, 0); }
  .cs-offcanvas-navigation__flyout {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 150px;
    background: #fff;
    box-shadow: 0px 18px 18px rgba(0, 0, 0, 0.09);
    padding: 54px 0;
    height: calc(100% - 150px); }
    @media (min-width: 64em) {
      .cs-offcanvas-navigation__flyout {
        overflow: hidden; } }
    .cs-offcanvas-navigation__flyout .cs-container__inner {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden; }
    .cs-offcanvas-navigation__flyout .cs-container {
      height: 100%; }
    .cs-offcanvas-navigation__item_cms_parent .cs-offcanvas-navigation__flyout {
      display: block;
      padding: 0;
      height: 0;
      max-height: calc(100% - 150px); }
      .cs-offcanvas-navigation__item_cms_parent .cs-offcanvas-navigation__flyout .cs-container {
        padding-top: 54px;
        padding-bottom: 54px; }
  .cs-offcanvas-navigation__collection {
    display: flex;
    flex-wrap: wrap; }
  .cs-offcanvas-navigation__collection_item {
    color: #3F3F41;
    font-size: 16px;
    line-height: 19px;
    width: calc(25% - 53px);
    margin-left: 70px;
    margin-bottom: 50px; }
    .cs-offcanvas-navigation__collection_item:nth-child(4n+1) {
      margin-left: 0; }
    .cs-offcanvas-navigation__collection_item a + a {
      color: #3F3F41;
      margin-top: 17px;
      display: inline-block; }
  .cs-offcanvas-navigation__item {
    margin: 0; }
    @media (max-width: 63.99em) {
      .cs-offcanvas-navigation__item {
        width: 100%;
        display: flex;
        overflow: hidden; } }
    @media (min-width: 64em) {
      .cs-offcanvas-navigation__item {
        padding: 0 5px; } }
    .cs-offcanvas-navigation__item--with-icon .cs-offcanvas-navigation__text {
      position: relative;
      padding-left: 3.5rem; }
    .cs-offcanvas-navigation__item--b2b_switcher {
      padding: 0 32px 24px;
      border-bottom: 1px solid rgba(33, 33, 33, 0.08);
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: right;
      letter-spacing: 0.08em;
      text-transform: uppercase; }
      .cs-offcanvas-navigation__item--b2b_switcher .b2b-common-text {
        margin-right: 8px; }
      .cs-offcanvas-navigation__item--b2b_switcher .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 12px; }
        .cs-offcanvas-navigation__item--b2b_switcher .switch .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #E7E7E7;
          -webkit-transition: .4s;
          transition: .4s; }
          .cs-offcanvas-navigation__item--b2b_switcher .switch .slider:before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 0;
            bottom: 0;
            background-color: #383838;
            -webkit-transition: .4s;
            transition: .4s; }
          .cs-offcanvas-navigation__item--b2b_switcher .switch .slider.round {
            border-radius: 34px; }
            .cs-offcanvas-navigation__item--b2b_switcher .switch .slider.round:before {
              border-radius: 50%; }
        .cs-offcanvas-navigation__item--b2b_switcher .switch input {
          opacity: 0;
          width: 0;
          height: 0; }
          .cs-offcanvas-navigation__item--b2b_switcher .switch input:checked + .slider {
            background-color: #488fe4; }
            .cs-offcanvas-navigation__item--b2b_switcher .switch input:checked + .slider:before {
              -webkit-transform: translateX(18px);
              -ms-transform: translateX(18px);
              transform: translateX(18px); }
  .cs-offcanvas-navigation__link {
    min-width: 100%;
    width: 100%;
    display: flex;
    padding: 1.6rem 2.3rem 1.6rem 3.5rem;
    font-size: 1.4rem;
    align-items: center;
    font-weight: 400;
    position: relative;
    letter-spacing: 0.1px; }
    .cs-offcanvas-navigation__item--level_0 > .cs-offcanvas-navigation__link {
      font-weight: 600; }
    @media (min-width: 64em) {
      .cs-offcanvas-navigation__link {
        padding: 0;
        white-space: nowrap;
        font-size: 1.3rem;
        height: 64px; } }
    @media (min-width: 80em) {
      .cs-offcanvas-navigation__link {
        font-size: 1.6rem; } }
    .cs-offcanvas-navigation__link, .cs-offcanvas-navigation__link:visited {
      color: #212121; }
    .cs-offcanvas-navigation__link:hover {
      color: #212121;
      text-decoration: none; }
    .cs-offcanvas-navigation__link--sign-in, .cs-offcanvas-navigation__link--sign-out {
      padding-right: 5rem; }
      .cs-offcanvas-navigation__link--sign-in, .cs-offcanvas-navigation__link--sign-in:visited, .cs-offcanvas-navigation__link--sign-out, .cs-offcanvas-navigation__link--sign-out:visited {
        color: #383838; }
      .cs-offcanvas-navigation__link--sign-in:hover, .cs-offcanvas-navigation__link--sign-out:hover {
        color: #383838; }
        .cs-offcanvas-navigation__link--sign-in:hover .cs-offcanvas-navigation__icon path, .cs-offcanvas-navigation__link--sign-out:hover .cs-offcanvas-navigation__icon path {
          fill: #383838; }
      .cs-offcanvas-navigation__link--sign-in .cs-offcanvas-navigation__icon, .cs-offcanvas-navigation__link--sign-out .cs-offcanvas-navigation__icon {
        width: 2.3rem;
        height: 2.7rem;
        right: 2.3rem; }
        .cs-offcanvas-navigation__link--sign-in .cs-offcanvas-navigation__icon path, .cs-offcanvas-navigation__link--sign-out .cs-offcanvas-navigation__icon path {
          fill: #383838; }
    .cs-offcanvas-navigation__link--parent .cs-offcanvas-navigation__icon, .cs-offcanvas-navigation__link--return .cs-offcanvas-navigation__icon {
      width: 0.7rem;
      height: 0.7rem; }
    .cs-offcanvas-navigation__link--parent {
      padding-right: 5rem; }
      .cs-offcanvas-navigation__link--parent .cs-offcanvas-navigation__icon {
        right: 2.3rem; }
    .cs-offcanvas-navigation__link--return {
      padding-left: 4rem;
      font-size: 18px;
      font-weight: 500;
      border-bottom: 1px solid rgba(33, 33, 33, 0.08); }
      .cs-offcanvas-navigation__link--return .cs-offcanvas-navigation__icon {
        left: 3.5rem; }
    .cs-offcanvas-navigation__link--next {
      position: relative; }
      .cs-offcanvas-navigation__link--next:before, .cs-offcanvas-navigation__link--next:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 0.7rem;
        height: 0.1rem;
        left: auto;
        background-color: #3F3F41;
        backface-visibility: hidden; }
      .cs-offcanvas-navigation__link--next[aria-expanded="true"]:before, .cs-offcanvas-navigation__link--next[aria-expanded="true"]:after {
        background-color: #888; }
      .cs-offcanvas-navigation__link--next:before {
        transform: rotate(135deg) translate3d(50%, 0, 0);
        right: 2.3rem; }
      .cs-offcanvas-navigation__link--next:after {
        right: 2.3rem;
        transform: rotate(-135deg) translate3d(50%, 0, 0); }
      .cs-offcanvas-navigation__link--next[aria-expanded="true"]:before {
        transform: rotate(225deg) translate3d(0, 0, 0);
        right: 0; }
      .cs-offcanvas-navigation__link--next[aria-expanded="true"]:after {
        transform: rotate(-45deg) translate3d(0, 0, 0);
        right: 2.3rem; }
    .cs-offcanvas-navigation__link--prev {
      position: relative; }
      .cs-offcanvas-navigation__link--prev:before, .cs-offcanvas-navigation__link--prev:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 0.7rem;
        height: 0.1rem;
        left: auto;
        background-color: #3F3F41;
        backface-visibility: hidden; }
      .cs-offcanvas-navigation__link--prev[aria-expanded="true"]:before, .cs-offcanvas-navigation__link--prev[aria-expanded="true"]:after {
        background-color: #888; }
      .cs-offcanvas-navigation__link--prev:before {
        transform: rotate(45deg) translate3d(50%, -50%, 0);
        right: 0; }
      .cs-offcanvas-navigation__link--prev:after {
        right: 0;
        transform: rotate(-45deg) translate3d(50%, 50%, 0); }
      .cs-offcanvas-navigation__link--prev[aria-expanded="true"]:before {
        transform: rotate(-45deg) translate3d(0, 0, 0);
        right: auto; }
      .cs-offcanvas-navigation__link--prev[aria-expanded="true"]:after {
        transform: rotate(-135deg) translate3d(0, 0, 0);
        right: 0; }
      .cs-offcanvas-navigation__link--prev:before, .cs-offcanvas-navigation__link--prev:after {
        left: 1.5rem; }
  .cs-offcanvas-navigation .active > .cs-offcanvas-navigation__link {
    color: #383838; }
  .cs-offcanvas-navigation__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .cs-offcanvas-navigation__icon path {
      fill: #3F3F41; }
  .cs-offcanvas-navigation__category-icon-wrapper {
    position: relative;
    width: 4rem;
    margin-right: 1rem; }
  .cs-offcanvas-navigation__category-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    max-height: 4rem; }

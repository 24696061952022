/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Mixins for flexible containers declaration.
 */
/**
 * Defines page container. This mixin makes sure that the content has always equal padding
 * for mobile screens on both size of the device.
 */
/**
 * Negates parent container's padding and sets it own.
 * Somethimes this has to be done to make background even to other containers' content.
 */
/**
 * Defines container wrapper that makes sure contnet doesn't extend above certain width and is always centered.
 */
.cs-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box; }
  @media (min-width: 64em) {
    .cs-container {
      padding-left: 6rem;
      padding-right: 6rem; } }
  .cms-home .column.main .cs-container {
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 48em) {
    .homepage_collections .cs-container {
      overflow: visible; } }
  .cs-container__inner, .cs-container__reviews {
    max-width: 160rem;
    width: 100%;
    margin: 0 auto;
    position: relative; }
    .cs-container__inner:before, .cs-container__reviews:before, .cs-container__inner:after, .cs-container__reviews:after {
      content: '';
      display: table;
      clear: both; }
    .cs-container--page-bottom .cs-container__inner, .cs-container--page-bottom .cs-container__reviews {
      max-width: 150rem; }
    .cs-container--page-category .cs-container__inner, .cs-container--page-category .cs-container__reviews {
      max-width: 180rem; }
    .cs-container__inner--expand {
      max-width: 1800px;
      margin: 0 auto;
      padding-left: 6rem;
      padding-right: 6rem; }
      @media (max-width: 63.99em) {
        .cs-container__inner--expand {
          padding-left: 1.5rem;
          padding-right: 1.5rem; } }
      .cs-container__inner--expand .cs-container__inner, .cs-container__inner--expand .cs-container__reviews {
        max-width: 100%; }
    @media (min-width: 48em) {
      .cs-container__inner--expand_hp_collection_bkg {
        padding: 0 0 40px;
        position: relative;
        margin-top: 90px; } }
    @media (min-width: 48em) {
      .cs-container__inner--expand_hp_collection_bkg::after {
        background: #F8F8F8;
        content: "";
        width: 60%;
        height: 50%;
        left: 0;
        bottom: 0;
        position: absolute; } }
    @media (min-width: 48em) {
      .cs-container__inner--expand_hp_recently_viewed_products {
        padding: 0 0 40px;
        position: relative;
        margin-top: 90px; } }
    @media (min-width: 48em) {
      .cs-container__inner--expand_hp_recently_viewed_products::after {
        background: #F8F8F8;
        content: "";
        width: 100%;
        height: 50%;
        left: 0;
        bottom: 0;
        position: absolute; } }
    .cs-container__inner--contract {
      max-width: 160rem;
      width: 100%;
      margin: 0 auto; }
    .cs-container__inner--flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      @media (min-width: 48em) {
        .cs-container__inner--flex {
          flex-wrap: nowrap; } }
      .cs-container__inner--flex:after {
        display: none; }
    .cms-home .column.main .cs-container__inner, .cms-home .column.main .cs-container__reviews {
      max-width: 100%; }
  .cs-container__aside {
    width: 100%; }
    @media (min-width: 48em) {
      .cs-container__aside {
        width: 40%; } }
    @media (min-width: 64em) {
      .cs-container__aside {
        width: 32%; } }
    @media (min-width: 90em) {
      .cs-container__aside {
        width: 30%; } }
  .cs-container__main-column {
    width: 56%;
    position: relative; }
    @media (min-width: 64em) {
      .cs-container__main-column {
        width: 65%; } }
    @media (min-width: 90em) {
      .cs-container__main-column {
        width: 67%; } }
    @media (max-width: 47.99em) {
      .cs-container__main-column {
        max-width: 100% !important;
        width: 100% !important; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .page-product-bundle .cs-container__main-column {
    width: 100%;
    margin-right: 0; } }
    @media (min-width: 64em) {
      .cs-container__main-column--space-top {
        padding-top: 3rem; } }
  .cs-container__reviews {
    padding-bottom: 2rem; }
    @media (min-width: 48em) {
      .cs-container__reviews {
        background: #fff;
        margin-top: 3rem;
        padding: 0 2rem; } }
    @media (min-width: 90em) {
      .cs-container__reviews {
        padding: 2rem 0 0; } }
    .cs-container__reviews:after {
      content: '';
      display: table;
      clear: both; }
  .cs-container__cart-coupons {
    display: inline-flex;
    flex-wrap: wrap; }
    @media (min-width: 64em) {
      .cs-container__cart-coupons {
        flex-wrap: nowrap;
        justify-content: space-between; } }
    @media (max-width: 29.99em) {
      .cs-container__cart-coupons {
        width: 100%; } }
  .cs-container--contrast {
    background-color: #f0f0f0; }
    @media (min-width: 64em) {
      .cs-container--contrast {
        background-image: url("../images/background.png");
        backgroud-size: cover;
        background-repeat: no-repeat; } }
  .cs-container--top-bar {
    background-color: #383838;
    max-width: none;
    padding: 0;
    height: 30px !important; }
  .cs-container--header {
    padding: 0;
    height: 9.3rem !important; }
    @media (min-width: 48em) {
      .cs-container--header {
        height: 13.5rem !important; } }
  .cs-container--spacious {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media (min-width: 64em) {
      .cs-container--spacious {
        padding-top: 3rem;
        padding-bottom: 3rem; } }
    @media (min-width: 48em) {
      .catalog-category-view .cs-container--spacious,
      .catalogsearch-result-index .cs-container--spacious {
        padding-top: 0; } }
  .cs-container--image-teaser-window-width, .cs-container--store-locator {
    padding-left: 0;
    padding-right: 0; }
  .cs-container--grid-teaser {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden; }
  .cs-container--hero-teaser {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0; }
  .cs-container--noscroll {
    overflow: hidden; }
  @media (min-width: 64em) {
    .cs-container--pdp {
      padding-top: 0; } }
  .cs-container--usps .cs-container--headline {
    padding: 0; }
  .cs-container--create-account-page, .cs-container--login-page, .cs-container--success-page, .cs-container--logout-page {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media (min-width: 64em) {
      .cs-container--create-account-page, .cs-container--login-page, .cs-container--success-page, .cs-container--logout-page {
        padding-top: 3rem;
        padding-bottom: 5rem; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-container--my-account-page {
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }
  .cs-container--ontop {
    z-index: 301; }
  .cs-container--viewport-width {
    max-width: none; }
  @media (max-width: 47.99em) {
    .cs-container--page-pdp-details-aw_pq_ajax_container {
      padding: 0; } }
  .cs-container--cart-page .cs-container--products-carousel {
    padding-left: 0;
    padding-right: 0; }
  .cs-container--cart-page .cs-container--headline {
    padding-left: 0;
    padding-right: 0; }
  .cs-container--cms-teaser {
    margin-top: 2rem; }
  @supports (display: grid) and (grid-auto-flow: row) {
    .cs-container--grid-teaser {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      overflow: visible; } }
  .cs-container--footer-usps .cs-image-teaser__content {
    margin: 0 !important; }

.cms-page-view #maincontent,
.cms-no-route #maincontent {
  padding: 0 1.5rem;
  width: 100%;
  max-width: 172rem;
  margin: 0 auto; }
  @media (min-width: 64em) {
    .cms-page-view #maincontent,
    .cms-no-route #maincontent {
      padding: 0 6rem; } }

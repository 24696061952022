@import 'config/variables';
@import 'utils/aspect-ratio';
@import 'vendors/include-media';

$navigation_font-size: 1rem !default;

$navigation_overlay-show: false !default;
// Box shadow effect applied for the flyout.
$navigation_flyout-box-shadow: 2px 2px 5px rgba($color_background-800, 0.15) !default;
// Flyout background color.
$navigation_flyout-background: $color_background-200 !default;
// Color of the link.
$navigation_link-color: $color_Black3F3F41 !default;
// Color of the link in the flyout on hover.
$navigation_link-color-hover: $color_Black3F3F41 !default;
// Bottom border configuration for flyout category links.
$navigation_link-border: 1px solid $color_border-500 !default;
$navigation_lists-mark-parent-on-hover: true !default;

$navigation_link-icon-size-width: 7.2rem !default;
$navigation_link-icon-size-height: 5.7rem !default;
// works only for svg icons
$navigation_link-icon-color: $color_text-600 !default;
$navigation_link-icon-color--hover: $color_primary-500 !default;

.#{$ns}navigation {
    $root: &;

    box-sizing: border-box;
    font-size: $navigation_font-size;
    height: 100%;

    &-bkg {
        @include media('>=laptop') {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $color_transparent;
            transition: background-color 0.3s;
        }
    }

    &-header {
        @include media('>=laptop') {
            display: flex;
            padding: 0 0 24px;
            justify-content: flex-start;
            margin-right: 16px;
        }
    }

    &-addtional {
        &_links {
            border-top: 1px solid $color_Graye0e0e0;
            margin-right: 16px;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
            }
        }

        &_link {
            margin: 20px 0 0;

            a {
                color: $color_prussian-blue;
                font-size: 14px;
                line-height: 22px;
                display: block;

                &.cs-navigation-addtional_link_image {
                    line-height: 24px;
                }
            }

            &_image {
                padding-left: 40px;
                position: relative;

                span {
                    line-height: 22px;
                }
            }

            &_icon {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        &--main {
            //position: relative;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &--all-categories {
            width: 100%;
            columns: auto 1;
            column-gap: 0;
            flex: 1 0 auto;
        }

        &--level {
            &_1 {
                width: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
            }

            &_2 {
                flex-wrap: wrap;
            }
        }

        &--hidden {
            display: none;

            & + .#{$ns}navigation__extras {
                display: none;
            }
        }

        #{$root}__item--no-children {
            &:nth-child(2) {
                padding-top: 1rem;
            }

            &:last-of-type {
                padding-bottom: 1rem;
            }
        }
    }

    &__item {
        font-size: 16px;
        padding: 0 15px;
        display: flex;
        align-items: center;

        &--level_1 {
            @include media('>=laptop') {
                margin: 40px 0 0;
                width: 25%;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    margin: 0;
                }
            }

            @include media('>=laptopLg') {
                margin: 80px 0 0;
                width: 14.2857%;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    margin: 0;
                }
            }
        }

        @if ($navigation_lists-mark-parent-on-hover) {
            &--active,
            &.active,
            &:hover {
                &:not([data-category-identifier='all-categories']) {
                    > #{$root}__link {
                        color: $navigation_link-color-hover;
                    }
                }
            }
        } @else {
            &--main {
                &:hover,
                &.active,
                &#{$root}__item--active {
                    &:not([data-category-identifier='all-categories']) {
                        > #{$root}__link {
                            color: $navigation_link-color-hover;
                        }
                    }
                }
            }
        }

        &--main {
            width: auto;
            font-size: 16px;
            line-height: 19px;
            position: static;
            padding: 0;
            margin: 0;

            &:first-of-type {
                > #{$root}__link:not(#{$root}__link--with-icon) {
                    @include media('<laptop') {
                        padding-left: 0;
                    }
                }
            }

            &:last-of-type {
                > #{$root}__link {
                    @include media('<laptop') {
                        padding-right: 0;
                    }
                }
            }
        }

        &--all-categories {
            padding: 1rem;
            // Prevents element breaking because of column layout.
            page-break-inside: avoid; /* Theoretically FF 20+ */
            break-inside: avoid-column; /* IE 11 */
            display: table;
        }

        &--hidden {
            display: none;
        }
    }

    &__link {
        display: block;
        width: 100%;
        text-align: center;

        &,
        &:visited {
            color: $navigation_link-color;
        }

        .cs-navigation__item--active & {
            background-color: $color_white;
            color: $color_Black3F3F41;
        }

        &:hover {
            text-decoration: none;
            background-color: $color_white;
            color: $navigation_link-color-hover;

            #{$root}__link-icon--svg {
                path {
                    fill: $navigation_link-icon-color--hover;
                }
            }
        }

        &--main {
            position: relative;
            padding: 1.9rem 5.4rem;
            text-align: left;

            #{$root}__link-icon-wrapper {
                position: absolute;
                width: $navigation_link-icon-size-width;
                height: $navigation_link-icon-size-height;
                top: 0;
                left: 55px;
                margin: 0;
            }

            &#{$root}__link--with-icon {
                position: relative;
                padding-left: 143px;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.cs-navigation__item--active {
                span {
                    font-weight: 500;
                }
            }

            &-bkg {
                .cs-navigation__item--with-flyout.cs-navigation__item--active & {
                    &::before {
                        border-color: $color_white;
                    }
                }

                .cs-navigation__item--main.cs-navigation__item--active & {
                    color: $color_white;

                    span {
                        color: $color_white;
                    }
                }

                .cs-navigation__item--main:not(.cs-navigation__item--active) & {
                    span {
                        background-color: $color_transparent !important;
                    }
                }
            }
        }

        &--all-categories {
            font-weight: 700;
            margin: 0;
            border-bottom: $navigation_link-border;
            padding-bottom: 1.6em;
        }

        &--view-all {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: underline;

            &:after {
                content: "";
                width: 27px;
                height: 8px;
                background-image: url('../images/menu_arrow.png');
                background-size: cover;
                background-repeat: no-repeat;
                display: block;
                margin-left: 9px;
            }
        }

        &--level {
            &_1,
            &_2 {
                padding: 0;
                line-height: 22px;
            }
        }

        &-icon-wrapper {
            display: block;
            margin-bottom: 15px;
            //position: absolute;
            //width: $navigation_link-icon-size-width;
            //height: $navigation_link-icon-size-height;
            //top: 0;
            //left: 55px;
        }

        &-icon--img {
            vertical-align: top;
            max-width: 106px;
            margin: 0 auto;
        }

        &-icon {

            #{$root}__link--category & {
                margin-top: -0.5rem;
            }

            &--svg {
                path {
                    fill: $navigation_link-icon-color;
                }
            }
        }

        &-products-qty {
            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }
    }

    //Styling for categories level_1 with no subcategories
    #{$root}__item--no-children {
        padding: 0;
    }
    #{$root}__item--no-children > #{$root}__link--level_1 {
        text-transform: none;
        font-size: 1.12em;
        font-weight: 400;
        padding: 0.9em 1.5em;
        border: none;
        color: $navigation_link-color;
    }

    &__flyout {
        position: absolute;
        display: none;
        background: $navigation_flyout-background;
        left: 0;
        backface-visibility: hidden;
        z-index: 1001; // Make sure flyouts cover gallery nav.
        width: calc(100% - 362px);
        max-width: calc(100% - 362px);
        top: 0;
        bottom: 0;
        padding: 80px 65px;
        flex-direction: column;

        &--visible {
            display: flex;
            left: 362px;
            transform: none !important;
        }

        svg {
            pointer-events: none;
        }

        li {
            @supports (-ms-ime-align: auto) {
                list-style: none;
                list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
                list-style-type: none;
            }
        }
    }

    &__extras {

        .cs {
            &-container {
                padding: 0;
            }

            &-image-teaser {
                margin: 0;
                padding: 0;

                &__slide {
                    padding: 0;

                    &-wrapper {
                        position: static !important;
                        height: auto !important;
                        display: flex;
                        width: 100% !important;
                        flex-direction: column;
                    }
                }

                &-wrapper {
                    margin: 0;
                    width: 100%;
                }

                &__aspect-ratio {
                    display: none !important;
                }

                &__figure {
                    position: static !important;
                    transform: none !important;
                    order: 1;
                }

                &__content-wrapper {
                    position: static;
                    padding: 0 !important;
                    justify-content: flex-start;
                }

                &__slogan {
                    font-size: 18px;
                    line-height: 26px;
                    text-align: left;
                    font-weight: 500;
                    padding: 8px 3px 3px;
                }
            }
        }
    }

    &--top_section {
        display: flex;
        width: 100% !important;
    }

    &--bottom_section {
        width: 100% !important;
        padding-right: 24px;
    }

    &--brands {
        border-top: 1px solid $color_Graye0e0e0;
        padding-top: 16px;
    }

    &--brand {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 40px;
        }

        a {
            display: block;
            padding: 5px;
        }

        &-title {
            color: $color_prussian-blue;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            margin-bottom: 10px;
        }
    }

    &--image-teaser {
        margin: 0;
        flex-basis: 229px;
        flex-shrink: 0;
        padding: 20px 21px 20px 33px;

        &_extra {
            &_links {
                padding-top: 5px;
                margin-top: 5px;
                border-top: 1px solid $color_prussian-blue;
            }

            &_link {
                display: block;
                font-size: 12px;
                line-height: 18px;
                margin: 0 0 4px;
            }
        }

        &_tag {
            color: $color_bistre-brown;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            padding: 3px 5px;
            display: inline-block;
            margin-right: 14px;
            background: $color_white;
            position: relative;
            border-radius: 4px 0 0 0;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: -14px;
                bottom: 0;
                display: block;
                width: 14px;
                border-style: solid;
                border-width: 14px 7px;
                border-color: white transparent transparent white;
                border-radius: 0 4px 0 0;
            }
        }
    }

    &__teaser {
        position: relative;
        max-height: 100%;
        padding: 0;
        overflow: hidden;
        margin-bottom: 0 !important;

        &--hidden {
            display: none;
        }

        //.#{$ns}image-teaser__figure {
        //    position: absolute;
        //    left: 50%;
        //    top: 50%;
        //    transform: translate(-50%, -50%);
        //}

        .cs-image-teaser__aspect-ratio {
            display: none;
        }

        .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper {
            position: static !important;
            display: flex;
            flex-direction: column;
        }

        .cs-image-teaser__content-wrapper {
            position: static !important;
            display: block;
            height: auto;
            order: -1;
        }

        .cs-image-teaser__slogan {
            font-size: 14px;
            line-height: normal;
            text-align: left;
        }
    }

    &__products {
        padding: 0.5em 1em;
        min-width: 24rem;

        &-header {
            display: block;
            color: $navigation_link-color;
            text-transform: uppercase;
            width: 100%;
            font-weight: 700;
            margin: 0;
            border-bottom: $navigation_link-border;
            padding: 1.1em 1.05em 1.55em;
            font-size: 0.9em;
        }

        &-list {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    &__product {
        @include aspect-ratio(
            $width: $tile_aspect-ratio-width,
            $height: $tile_aspect-ratio-height,
            $childClass: #{$ns}product-tile,
            $autoposition: true
        );
    }

    &__overlay {
        display: none;
        position: absolute;
        z-index: 1000;
        background-color: #000;
        opacity: 0;
        left: 0;
        width: 100%;
        height: 0;
        transform: translate3d(0, 3rem, 0);
        transition: opacity 0.15s linear,
            transform 0.2s cubic-bezier(0, 0.97, 0.05, 0.99);

        @if $navigation_overlay-show {
            @include media('>=tablet') {
                display: block;
            }
        }

        &--visible {
            opacity: 0.8;
            transform: translate3d(0, 0, 0);
        }
    }

    .page-print & {
        display: none;
    }

    @supports (display: grid) and (grid-auto-flow: row) {
        &__product {
            @include aspect-ratio-reset(
                $childClass: #{$ns}product-tile,
                $autoposition: true
            );
        }
    }
}

.#{$ns}container {
    &--navigation {
        display: none;

        @include media('>=laptop') {
            display: block;
            position: fixed;
            transition: transform 0.3s;
            z-index: 300;
            padding: 0;
            top: 165px;
            bottom: 0;
            left: 0;
            right: auto;
            transform: translate3d(-105%, 0, 0);
        }

        &.active {
            @include media('>=laptop') {
                transform: translate3d(0, 0, 0) !important;
            }

            .cs-navigation-bkg {
                @include media('>=laptop') {
                    background-color: rgba($color_prussian-blue, 0.3);
                }
            }
        }

        > .cs-container__inner {
            max-width: 362px;
            margin-left: 0;
            background: $color_GrayF8;
            padding: 26px 0;
            position: static;
            height: 100%;

            .cs-container--header-top + .cs-offcanvas--navigation + & {
                padding-top: 57px;

                @include media('>=laptopLg') {
                    padding-top: 64px;
                }
            }
        }
    }
}

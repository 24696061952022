/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Using psuedo elements to maintain an elements aspect ratio, even as it scales
 * It's using only for IE11 and Edge (version which doesn't support native CSS grid) browsers
 * For browsers which support native CSS grid, we are using mixins contain `reset` in mixin name
 * to override default mixins (progressive enhancement)
 * https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
 * @param {number} $width: width of the element;
 * @param {number} $height: height of the element;
 * @param {string} $contentClass: class of the direct child element to be positioned absolutely;
 * @param {boolean} $autoposition: tells if item shall take 100% of parent size or if it can be wider/higher if needed;
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-navigation {
  box-sizing: border-box;
  font-size: 1rem;
  height: 100%; }
  @media (min-width: 64em) {
    .cs-navigation-bkg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: transparent;
      transition: background-color 0.3s; } }
  @media (min-width: 64em) {
    .cs-navigation-header {
      display: flex;
      padding: 0 0 24px;
      justify-content: flex-start;
      margin-right: 16px; } }
  .cs-navigation-addtional_links {
    border-top: 1px solid #e0e0e0;
    margin-right: 16px; }
    .cs-navigation-addtional_links ul {
      margin: 0;
      padding: 0;
      list-style: none; }
  .cs-navigation-addtional_link {
    margin: 20px 0 0; }
    .cs-navigation-addtional_link a {
      color: #062033;
      font-size: 14px;
      line-height: 22px;
      display: block; }
      .cs-navigation-addtional_link a.cs-navigation-addtional_link_image {
        line-height: 24px; }
    .cs-navigation-addtional_link_image {
      padding-left: 40px;
      position: relative; }
      .cs-navigation-addtional_link_image span {
        line-height: 22px; }
    .cs-navigation-addtional_link_icon {
      position: absolute;
      left: 0;
      top: 0; }
  .cs-navigation__list {
    list-style: none;
    padding: 0;
    margin: 0; }
    .cs-navigation__list--main {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden; }
    .cs-navigation__list--all-categories {
      width: 100%;
      columns: auto 1;
      column-gap: 0;
      flex: 1 0 auto; }
    .cs-navigation__list--level_1 {
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap; }
    .cs-navigation__list--level_2 {
      flex-wrap: wrap; }
    .cs-navigation__list--hidden {
      display: none; }
      .cs-navigation__list--hidden + .cs-navigation__extras {
        display: none; }
    .cs-navigation__list .cs-navigation__item--no-children:nth-child(2) {
      padding-top: 1rem; }
    .cs-navigation__list .cs-navigation__item--no-children:last-of-type {
      padding-bottom: 1rem; }
  .cs-navigation__item {
    font-size: 16px;
    padding: 0 15px;
    display: flex;
    align-items: center; }
    @media (min-width: 64em) {
      .cs-navigation__item--level_1 {
        margin: 40px 0 0;
        width: 25%; }
        .cs-navigation__item--level_1:nth-child(1), .cs-navigation__item--level_1:nth-child(2), .cs-navigation__item--level_1:nth-child(3), .cs-navigation__item--level_1:nth-child(4) {
          margin: 0; } }
    @media (min-width: 80em) {
      .cs-navigation__item--level_1 {
        margin: 80px 0 0;
        width: 14.2857%; }
        .cs-navigation__item--level_1:nth-child(1), .cs-navigation__item--level_1:nth-child(2), .cs-navigation__item--level_1:nth-child(3), .cs-navigation__item--level_1:nth-child(4), .cs-navigation__item--level_1:nth-child(5), .cs-navigation__item--level_1:nth-child(6), .cs-navigation__item--level_1:nth-child(7) {
          margin: 0; } }
    .cs-navigation__item--active:not([data-category-identifier='all-categories']) > .cs-navigation__link, .cs-navigation__item.active:not([data-category-identifier='all-categories']) > .cs-navigation__link, .cs-navigation__item:hover:not([data-category-identifier='all-categories']) > .cs-navigation__link {
      color: #3F3F41; }
    .cs-navigation__item--main {
      width: auto;
      font-size: 16px;
      line-height: 19px;
      position: static;
      padding: 0;
      margin: 0; }
      @media (max-width: 63.99em) {
        .cs-navigation__item--main:first-of-type > .cs-navigation__link:not(.cs-navigation__link--with-icon) {
          padding-left: 0; } }
      @media (max-width: 63.99em) {
        .cs-navigation__item--main:last-of-type > .cs-navigation__link {
          padding-right: 0; } }
    .cs-navigation__item--all-categories {
      padding: 1rem;
      page-break-inside: avoid;
      /* Theoretically FF 20+ */
      break-inside: avoid-column;
      /* IE 11 */
      display: table; }
    .cs-navigation__item--hidden {
      display: none; }
  .cs-navigation__link {
    display: block;
    width: 100%;
    text-align: center; }
    .cs-navigation__link, .cs-navigation__link:visited {
      color: #3F3F41; }
    .cs-navigation__item--active .cs-navigation__link {
      background-color: #fff;
      color: #3F3F41; }
    .cs-navigation__link:hover {
      text-decoration: none;
      background-color: #fff;
      color: #3F3F41; }
      .cs-navigation__link:hover .cs-navigation__link-icon--svg path {
        fill: #383838; }
    .cs-navigation__link--main {
      position: relative;
      padding: 1.9rem 5.4rem;
      text-align: left; }
      .cs-navigation__link--main .cs-navigation__link-icon-wrapper {
        position: absolute;
        width: 7.2rem;
        height: 5.7rem;
        top: 0;
        left: 55px;
        margin: 0; }
      .cs-navigation__link--main.cs-navigation__link--with-icon {
        position: relative;
        padding-left: 143px; }
      .cs-navigation__link--main span {
        display: flex;
        align-items: center;
        justify-content: center; }
      .cs-navigation__link--main.cs-navigation__item--active span {
        font-weight: 500; }
      .cs-navigation__item--with-flyout.cs-navigation__item--active .cs-navigation__link--main-bkg::before {
        border-color: #fff; }
      .cs-navigation__item--main.cs-navigation__item--active .cs-navigation__link--main-bkg {
        color: #fff; }
        .cs-navigation__item--main.cs-navigation__item--active .cs-navigation__link--main-bkg span {
          color: #fff; }
      .cs-navigation__item--main:not(.cs-navigation__item--active) .cs-navigation__link--main-bkg span {
        background-color: transparent !important; }
    .cs-navigation__link--all-categories {
      font-weight: 700;
      margin: 0;
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 1.6em; }
    .cs-navigation__link--view-all {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: underline; }
      .cs-navigation__link--view-all:after {
        content: "";
        width: 27px;
        height: 8px;
        background-image: url("../images/menu_arrow.png");
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        margin-left: 9px; }
    .cs-navigation__link--level_1, .cs-navigation__link--level_2 {
      padding: 0;
      line-height: 22px; }
    .cs-navigation__link-icon-wrapper {
      display: block;
      margin-bottom: 15px; }
    .cs-navigation__link-icon--img {
      vertical-align: top;
      max-width: 106px;
      margin: 0 auto; }
    .cs-navigation__link--category .cs-navigation__link-icon {
      margin-top: -0.5rem; }
    .cs-navigation__link-icon--svg path {
      fill: #484848; }
    .cs-navigation__link-products-qty:before {
      content: '('; }
    .cs-navigation__link-products-qty:after {
      content: ')'; }
  .cs-navigation .cs-navigation__item--no-children {
    padding: 0; }
  .cs-navigation .cs-navigation__item--no-children > .cs-navigation__link--level_1 {
    text-transform: none;
    font-size: 1.12em;
    font-weight: 400;
    padding: 0.9em 1.5em;
    border: none;
    color: #3F3F41; }
  .cs-navigation__flyout {
    position: absolute;
    display: none;
    background: #fff;
    left: 0;
    backface-visibility: hidden;
    z-index: 1001;
    width: calc(100% - 362px);
    max-width: calc(100% - 362px);
    top: 0;
    bottom: 0;
    padding: 80px 65px;
    flex-direction: column; }
    .cs-navigation__flyout--visible {
      display: flex;
      left: 362px;
      transform: none !important; }
    .cs-navigation__flyout svg {
      pointer-events: none; }
    @supports (-ms-ime-align: auto) {
      .cs-navigation__flyout li {
        list-style: none;
        list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
        list-style-type: none; } }
  .cs-navigation__extras .cs-container {
    padding: 0; }
  .cs-navigation__extras .cs-image-teaser {
    margin: 0;
    padding: 0; }
    .cs-navigation__extras .cs-image-teaser__slide {
      padding: 0; }
      .cs-navigation__extras .cs-image-teaser__slide-wrapper {
        position: static !important;
        height: auto !important;
        display: flex;
        width: 100% !important;
        flex-direction: column; }
    .cs-navigation__extras .cs-image-teaser-wrapper {
      margin: 0;
      width: 100%; }
    .cs-navigation__extras .cs-image-teaser__aspect-ratio {
      display: none !important; }
    .cs-navigation__extras .cs-image-teaser__figure {
      position: static !important;
      transform: none !important;
      order: 1; }
    .cs-navigation__extras .cs-image-teaser__content-wrapper {
      position: static;
      padding: 0 !important;
      justify-content: flex-start; }
    .cs-navigation__extras .cs-image-teaser__slogan {
      font-size: 18px;
      line-height: 26px;
      text-align: left;
      font-weight: 500;
      padding: 8px 3px 3px; }
  .cs-navigation--top_section {
    display: flex;
    width: 100% !important; }
  .cs-navigation--bottom_section {
    width: 100% !important;
    padding-right: 24px; }
  .cs-navigation--brands {
    border-top: 1px solid #e0e0e0;
    padding-top: 16px; }
  .cs-navigation--brand {
    display: inline-block; }
    .cs-navigation--brand:not(:last-child) {
      margin-right: 40px; }
    .cs-navigation--brand a {
      display: block;
      padding: 5px; }
    .cs-navigation--brand-title {
      color: #062033;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 10px; }
  .cs-navigation--image-teaser {
    margin: 0;
    flex-basis: 229px;
    flex-shrink: 0;
    padding: 20px 21px 20px 33px; }
    .cs-navigation--image-teaser_extra_links {
      padding-top: 5px;
      margin-top: 5px;
      border-top: 1px solid #062033; }
    .cs-navigation--image-teaser_extra_link {
      display: block;
      font-size: 12px;
      line-height: 18px;
      margin: 0 0 4px; }
    .cs-navigation--image-teaser_tag {
      color: #422918;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      padding: 3px 5px;
      display: inline-block;
      margin-right: 14px;
      background: #fff;
      position: relative;
      border-radius: 4px 0 0 0; }
      .cs-navigation--image-teaser_tag::after {
        content: "";
        position: absolute;
        top: 0;
        right: -14px;
        bottom: 0;
        display: block;
        width: 14px;
        border-style: solid;
        border-width: 14px 7px;
        border-color: white transparent transparent white;
        border-radius: 0 4px 0 0; }
  .cs-navigation__teaser {
    position: relative;
    max-height: 100%;
    padding: 0;
    overflow: hidden;
    margin-bottom: 0 !important; }
    .cs-navigation__teaser--hidden {
      display: none; }
    .cs-navigation__teaser .cs-image-teaser__aspect-ratio {
      display: none; }
    .cs-navigation__teaser .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper {
      position: static !important;
      display: flex;
      flex-direction: column; }
    .cs-navigation__teaser .cs-image-teaser__content-wrapper {
      position: static !important;
      display: block;
      height: auto;
      order: -1; }
    .cs-navigation__teaser .cs-image-teaser__slogan {
      font-size: 14px;
      line-height: normal;
      text-align: left; }
  .cs-navigation__products {
    padding: 0.5em 1em;
    min-width: 24rem; }
    .cs-navigation__products-header {
      display: block;
      color: #3F3F41;
      text-transform: uppercase;
      width: 100%;
      font-weight: 700;
      margin: 0;
      border-bottom: 1px solid #dcdcdc;
      padding: 1.1em 1.05em 1.55em;
      font-size: 0.9em; }
    .cs-navigation__products-list {
      margin: 0;
      padding: 0;
      list-style: none; }
  .cs-navigation__product {
    position: relative; }
    .cs-navigation__product:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 170%; }
    .cs-navigation__product .cs-product-tile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .cs-navigation__overlay {
    display: none;
    position: absolute;
    z-index: 1000;
    background-color: #000;
    opacity: 0;
    left: 0;
    width: 100%;
    height: 0;
    transform: translate3d(0, 3rem, 0);
    transition: opacity 0.15s linear, transform 0.2s cubic-bezier(0, 0.97, 0.05, 0.99); }
    .cs-navigation__overlay--visible {
      opacity: 0.8;
      transform: translate3d(0, 0, 0); }
  .page-print .cs-navigation {
    display: none; }
  @supports (display: grid) and (grid-auto-flow: row) {
    .cs-navigation__product {
      position: relative; }
      .cs-navigation__product:before {
        content: none; }
      .cs-navigation__product .cs-product-tile {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto; } }

.cs-container--navigation {
  display: none; }
  @media (min-width: 64em) {
    .cs-container--navigation {
      display: block;
      position: fixed;
      transition: transform 0.3s;
      z-index: 300;
      padding: 0;
      top: 165px;
      bottom: 0;
      left: 0;
      right: auto;
      transform: translate3d(-105%, 0, 0); } }
  @media (min-width: 64em) {
    .cs-container--navigation.active {
      transform: translate3d(0, 0, 0) !important; } }
  @media (min-width: 64em) {
    .cs-container--navigation.active .cs-navigation-bkg {
      background-color: rgba(6, 32, 51, 0.3); } }
  .cs-container--navigation > .cs-container__inner {
    max-width: 362px;
    margin-left: 0;
    background: #F8F8F8;
    padding: 26px 0;
    position: static;
    height: 100%; }
    .cs-container--header-top + .cs-offcanvas--navigation + .cs-container--navigation > .cs-container__inner {
      padding-top: 57px; }
      @media (min-width: 80em) {
        .cs-container--header-top + .cs-offcanvas--navigation + .cs-container--navigation > .cs-container__inner {
          padding-top: 64px; } }

@import 'config/variables';

$links-block-addto_wishlist-icon-color: $color_Black3F3F41 !default;
$links-block-addto_compare-icon-color: $link-color_base !default;
$links-block-addto_color: $color_text-500 !default;
$links-block-addto_hover-color: $link-color_base-hover !default;
$links-block-addto_font-size: 1.2rem !default;
$links-block-addto_icon-width: 2rem !default;
$links-block-addto_icon-height: 1.7rem !default;

.#{$ns}links-block-addto {
    $root: &;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .page-product-bundle & {
        margin-top: 1rem;
    }

    .#{$ns}product-tile__addon & {
        padding-top: 0;
    }

    &--centered {
        justify-content: center;
    }

    &__item {
        margin: 0;

        &:nth-child(2n) {
            text-align: right;

            #{$root}__icon {
                order: 2;
                margin-left: 0.5rem;
            }
        }

        &--share {
            position: relative;

            .addthis_toolbox {
                display: none;
                position: absolute;
                right: 0;
                top: 12px;
                background: $color_GrayF8;
                padding: 14px 10px;
                overflow: hidden;
                left: 0;
                right: 0;
                align-items: center;
                justify-content: center;
                z-index: 1;

                a {
                    margin: 0;

                    .at-icon-wrapper {
                        vertical-align: top;
                    }
                }
            }

            &.active {
                .addthis_toolbox {
                    display: flex;
                }
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        font-size: $links-block-addto_font-size;
        color: $links-block-addto_hover-color;
        transition: color 0.15s linear;

        &:hover {
            color: $links-block-addto_hover-color;
            text-decoration: none;
        }

        .cs-product-tile__addto & {
            background-color: $color_GrayF8;
            height: 45px;
            justify-content: center;
            margin: 1.2rem 0 4rem;

            &.towishlist:not(.loggedin) {
                background-color: $color_Black38;
            }

            &.toshare-tile {
                position: relative;

                &:hover {
                    .cs-links-block-addto__link-label {
                        background-color: rgba(56, 56, 56, 0.87);
                        border-radius: 3px;
                        font-size: 16px;
                        line-height: 19px;
                        letter-spacing: 0.03em;
                        color: $color_white;
                        display: block !important;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: calc(100% + 10px);
                        line-height: 53px;
                        text-align: center;

                        &:after {
                            content: "";
                            width: 10px;
                            height: 10px;
                            border-style: solid;
                            border-width: 5px;
                            border-color: $color_transparent rgba(56, 56, 56, 0.87) rgba(56, 56, 56, 0.87) $color_transparent;
                            position: absolute;
                            bottom: -5px;
                            left: 50%;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }

    &__icon {
        width: $links-block-addto_icon-width;
        height: $links-block-addto_icon-height;
        margin-right: 0.4rem;

        path {
            fill: $color_gray;
        }

        &--wishlist {
            path {
                //fill: $links-block-addto_wishlist-icon-color;
                fill: none;
                stroke: $color_white;
                stroke-width: 20px;

                .cs-links-block-addto__link.loggedin & {
                    stroke: $color_Black3F3F41;
                    stroke-width: 20px;
                }

                .cs-links-block-addto__link.wishlisticon.loggedin & {
                    fill: $color_Black3F3F41;
                }
            }
        }

        &--compare {
            path {
                fill: $links-block-addto_compare-icon-color;
            }
        }
    }
}

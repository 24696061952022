@import 'config/variables';
@import 'vendors/include-media';
@import 'components/headline/hook';

$headline_title-color: $color_text-800 !default;
$headline_title-font-size: 4rem !default;
$headline_title-font-weight: 600 !default;
$headline_title-text-transform: uppercase !default;
// Show flexible decoration lines at the sides of the title.
$headline_title-lines-enabled: false !default;
$headline_title-lines-color: $color_border-500 !default;

$headline_subtitle-color: $color_black !default;
$headline_subtitle-font-size: 1.6rem !default;
$headline_subtitle-font-weight: 400 !default;
$headline_subtitle-text-transform: none !default;
$headline_subtitle-margin: 0 !default;

@mixin headline {
    font-size: 1rem;
    clear: both;
    padding: 2em 0;
    margin: 2em 0;

    @include headline-hook();

    @include media('<tablet') {
        margin: 0;
        padding: 10px 0;
    }
}

@mixin headline-title(
    $color: $headline_title-color,
    $font-size: $headline_title-font-size,
    $font-weight: $headline_title-font-weight,
    $text-transform: $headline_title-text-transform,
    $lines-enabled: $headline_title-lines-enabled,
    $lines-color: $headline_title-lines-color
) {
    display: block;
    align-items: center;
    margin: 0;
    font-size: $font-size;
    font-weight: $font-weight;
    color: $color;
    text-transform: $text-transform;
    margin-bottom: 16px;

    @include media('>=tablet') {
        text-align: center;
    }

    @include media('<tablet') {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.15px;
        text-transform: none;
        margin: 0;
    }

    @if $lines-enabled {
        &:before,
        &:after {
            content: '';
            display: none;
            height: 1px;
            flex-grow: 1;
            background-color: $lines-color;

            @include media('>=tablet') {
                display: flex;
            }
        }

        &:before {
            margin-right: 0.6em;
        }

        &:after {
            margin-left: 0.6em;
        }
    }

    @include headline-title-hook();
}

@mixin headline-subtitle(
    $color: $headline_subtitle-color,
    $font-size: $headline_subtitle-font-size,
    $font-weight: $headline_subtitle-font-weight,
    $text-transform: $headline_subtitle-text-transform
) {
    color: $color;
    font-size: $font-size;
    font-weight: $font-weight;
    text-transform: $text-transform;
    margin: $headline_subtitle-margin;
    display: none;
    text-align: center;
    opacity: 0.18;

    @include media('>=tablet') {
        display: block;
    }

    @include headline-subtitle-hook();
}

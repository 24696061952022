@charset "UTF-8";
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
.cs-breadcrumbs {
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
  font-weight: 400;
  letter-spacing: 0.01em; }
  @media (min-width: 48em) {
    .cs-breadcrumbs {
      letter-spacing: 0.03em;
      padding-top: 4.4rem;
      padding-bottom: 4.4rem; } }
  .catalog-product-view .cs-breadcrumbs {
    padding-right: 100px; }
    @media (min-width: 48em) {
      .catalog-product-view .cs-breadcrumbs {
        padding-left: 260px;
        padding-right: 260px; } }
  .cs-breadcrumbs__list {
    margin: 0;
    padding: 0;
    list-style: none; }
  .cs-breadcrumbs__item {
    position: relative;
    display: inline-block;
    margin: 0;
    cursor: pointer;
    font-size: 1.2rem; }
    @media (min-width: 48em) {
      .cs-breadcrumbs__item {
        font-size: 1.4rem; } }
    .cs-breadcrumbs__item:last-child {
      padding: 0;
      color: #383838;
      cursor: default; }
    .cs-breadcrumbs__item:not(:last-child):after {
      content: "⁄";
      transform: translateY(-50%);
      line-height: 1;
      color: #C4C4C4;
      margin: 0 0.2rem; }
  .cs-breadcrumbs__link {
    position: relative;
    display: inline-block;
    color: #C4C4C4 !important; }
    .cs-breadcrumbs__link:visited {
      color: #C4C4C4 !important; }
    .cs-breadcrumbs__link:hover {
      text-decoration: none;
      color: #C4C4C4 !important; }
  .cs-breadcrumbs__separator-icon {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
    width: 0.8em;
    height: 0.8em;
    fill: #C4C4C4; }

.cs-container--breadcrumbs {
  max-width: none; }

@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';
@import 'etc/view.scss';
@import 'utils/deep-get/deep-get.scss';

$breadcrumbs_separator-custom-show: deep-get(
    $view-xml,
    vars,
    Magento_Theme,
    breadcrumbs,
    custom_separator,
    display
) !default;

$breadcrumbs_background-color: $color_background-500 !default;
$breadcrumbs_background-image: url('../images/breadcrumbs.png') !default;
$breadcrumbs_background: $breadcrumbs_background-color
    $breadcrumbs_background-image no-repeat right / auto 6.8rem !default;

$breadcrumbs_item-font-size: 1.2rem !default;
$breadcrumbs_item-color: $color_GrayC4C4C4 !default;
$breadcrumbs_item-hover-color: $color_GrayC4C4C4 !default;
$breadcrumbs_item-visited-color: $breadcrumbs_item-color !default;
$breadcrumbs_last-item-color: $color_Black38 !default;

$breadcrumbs_separator-color: $breadcrumbs_item-color !default;
$breadcrumbs_separator-content: '\2044' !default;

$breadcrumbs_vertical-space: 2.2rem !default;

.#{$ns}breadcrumbs {
    $root: &;
    padding-top: $breadcrumbs_vertical-space;
    padding-bottom: $breadcrumbs_vertical-space;
    font-weight: 400;
    letter-spacing: 0.01em;

    @include media('>=tablet') {
        letter-spacing: 0.03em;
        padding-top: $breadcrumbs_vertical-space*2;
        padding-bottom: $breadcrumbs_vertical-space*2;
    }

    .catalog-product-view & {
        padding-right: 100px;

        @include media('>=tablet') {
            padding-left: 260px;
            padding-right: 260px;
        }
    }

    &__list {
        @include reset-list-style();
    }

    &__item {
        position: relative;
        display: inline-block;
        margin: 0;
        cursor: pointer;
        font-size: $breadcrumbs_item-font-size;

        @include media('>=tablet') {
            font-size: 1.4rem;
        }

        &:last-child {
            padding: 0;
            color: $breadcrumbs_last-item-color;
            cursor: default;
        }

        &:not(:last-child):after {
            content: $breadcrumbs_separator-content;
            transform: translateY(-50%);
            line-height: 1;
            color: $breadcrumbs_separator-color;
            margin: 0 0.2rem;
        }

        @if ($breadcrumbs_separator-custom-show) {
            &:after {
                display: none;
            }
        }
    }

    &__link {
        position: relative;
        display: inline-block;
        color: $breadcrumbs_item-color !important;

        &:visited {
            color: $breadcrumbs_item-visited-color !important;
        }

        &:hover {
            text-decoration: none;
            color: $breadcrumbs_item-hover-color !important;
        }
    }

    &__separator-icon {
        position: absolute;
        top: 50%;
        right: 0.5em;
        transform: translateY(-50%);
        width: 0.8em;
        height: 0.8em;
        fill: $breadcrumbs_separator-color;
    }
}

.cs-container {
    &--breadcrumbs {
        max-width: none;

    }
}

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-social-media-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  padding: 4.1rem 0 1.5rem;
  justify-content: center;
  align-items: center; }
  @media (min-width: 64em) {
    .cs-social-media-list {
      justify-content: flex-start; } }
  @media (min-width: 64em) {
    .cs-social-media-list {
      padding-bottom: 0;
      padding-top: 0; } }
  .cs-social-media-list__item {
    margin: 0 1.1em;
    padding: 0; }
    @media (min-width: 64em) {
      .cs-social-media-list__item {
        margin: 0 1.8em; } }
  .cs-social-media-list__icon {
    fill: #fff; }
    .cs-social-media-list__item--fb .cs-social-media-list__icon {
      width: 0.86em;
      height: 1.65em; }
      @media (min-width: 64em) {
        .cs-social-media-list__item--fb .cs-social-media-list__icon {
          width: 1.5em;
          height: 2.8em; } }
    .cs-social-media-list__item--tw .cs-social-media-list__icon {
      width: 1.52em;
      height: 1.24em; }
      @media (min-width: 64em) {
        .cs-social-media-list__item--tw .cs-social-media-list__icon {
          width: 2.6em;
          height: 2.2em; } }
    .cs-social-media-list__item--yt .cs-social-media-list__icon {
      width: 1.5em;
      height: 1.06em; }
      @media (min-width: 64em) {
        .cs-social-media-list__item--yt .cs-social-media-list__icon {
          width: 2.6em;
          height: 1.8em; } }
    .cs-social-media-list__item--pt .cs-social-media-list__icon {
      width: 1.26em;
      height: 1.62em; }
      @media (min-width: 64em) {
        .cs-social-media-list__item--pt .cs-social-media-list__icon {
          width: 2.2em;
          height: 2.8em; } }
    .cs-social-media-list__item--ld .cs-social-media-list__icon {
      width: 1.33em;
      height: 1.33em; }
      @media (min-width: 64em) {
        .cs-social-media-list__item--ld .cs-social-media-list__icon {
          width: 3.0em;
          height: 3.0em; } }
    .cs-social-media-list__item--gp .cs-social-media-list__icon {
      width: 2em;
      height: 2em;
      margin-top: 0.1em; }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-free-shipping-indicator__content {
  border-top: 1px solid #dcdcdc;
  padding: 1.5rem 2rem 2rem;
  color: #696969;
  font-size: 1.4rem;
  text-align: left; }

.cs-free-shipping-indicator__cell {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-right: 0 !important; }

.cs-free-shipping-indicator__total-left-text span {
  font-weight: 700; }

.cs-free-shipping-indicator__total-fulfilled {
  padding-left: 2.8rem;
  background: url("../images/icons/messages/icon-success.svg") 0 50% no-repeat;
  background-size: 2rem; }

.cs-free-shipping-indicator__bar-wrapper {
  display: flex;
  align-items: center; }

.cs-free-shipping-indicator__bar-label {
  flex-shrink: 0;
  color: #37b841;
  font-weight: 700; }
  .cs-free-shipping-indicator__bar-label--start {
    margin-right: 1rem; }
  .cs-free-shipping-indicator__bar-label--end {
    margin-left: 1rem; }

.cs-free-shipping-indicator__bar {
  width: 100%;
  height: 2rem;
  padding: 0.4rem;
  border: 1px solid #b0b0b0;
  border-radius: 2.8px; }

.cs-free-shipping-indicator__bar-progress-wrapper {
  height: 100%;
  background: transparent;
  border-radius: 2px; }

.cs-free-shipping-indicator__bar-progress {
  height: 100%;
  width: 0;
  background: #37b841;
  border-radius: 2px;
  transition: width 0.55s cubic-bezier(0, 0.66, 0.32, 0.99); }

.cs-free-shipping-indicator__icon {
  flex-shrink: 0;
  width: 3.4rem;
  height: 2rem;
  margin: 0 0 0 2.5rem;
  fill: #888;
  transition: fill 0.3s; }
  .cs-free-shipping-indicator__bar-wrapper--free-shipping-active .cs-free-shipping-indicator__icon {
    fill: #37b841; }

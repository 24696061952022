/* stylelint-disable block-no-empty */
@mixin field-hook() {
}

@mixin field-error-hook() {
}

@mixin field-label-hook() {
}

@mixin field-label-required-hook() {
}

@mixin field-control-hook() {
}

@mixin field-input-hook() {
}

@mixin field-input-error-hook() {
}

@mixin field-icon-hook($type: '') {
}

@mixin field-note-hook() {
}

@mixin field-error-hook() {
}

@mixin field-warning-hook() {
}

@mixin field-select-hook() {
}

@mixin field-radio-hook() {
}

@mixin field-radio-label-hook() {
}

@mixin field-checkbox-hook() {
}

@mixin field-checkbox-label-hook() {
}

@mixin field-dob-hook() {
}
/* stylelint-enable */

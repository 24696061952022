@import 'config/variables';
@import 'utils/font-family';

$input_remove-number-appearance: true !default;
$input_remove-search-appearance: true !default;

html {
    box-sizing: border-box;
    font-size: 62.5%;
    background: $page_background;
    /* stylelint-disable */
    overflow-x: hidden; // hides horizontal scrollbar on Edge and IE
    /* stylelint-enable */
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    @include font-family($font_family-primary);
    -webkit-overflow-scrolling: touch;
    margin: 0;
    font-size: 1.4rem;
    font-display: swap;
}

button,
input,
textarea,
select {
    font-family: inherit;
}

/* Hide HTML5 Up and Down arrows. */
@if $input_remove-number-appearance {
    input[type='number'] {
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

/* Hide HTML5 'X' in search input */
@if $input_remove-search-appearance {
    input[type='search'] {
        /* Clears the 'X' from Internet Explorer */
        &::-ms-clear,
        &::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }

        /* Clears the 'X' from Chrome */
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }
}

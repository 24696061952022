/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-topbar {
  background: #383838;
  width: 100%;
  max-width: 100%;
  font-size: 12px; }
  .cs-topbar--hidden {
    transform: translateY(-105%); }
  .page-print .cs-topbar {
    display: none; }
  .cs-topbar__wrapper {
    align-items: center;
    height: 30px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #383838;
    z-index: 301;
    transition: top 0.5s; }
    .page-wrapper-scroll .cs-topbar__wrapper {
      top: -30px; }
  .cs-topbar-carousel__carousel {
    padding: 0 80px;
    margin: 0 auto;
    overflow: hidden;
    position: relative; }
  .cs-topbar__item {
    color: #fff;
    height: 100%; }
    .cs-topbar__item--usps {
      margin-right: auto;
      margin-left: auto;
      display: block; }
      .page-wrapper--checkout .cs-topbar__item--usps {
        display: none !important; }
    .cs-topbar__item--hotline {
      align-items: center; }
      @media (max-width: 79.99em) {
        .cs-topbar__item--hotline {
          flex: 1;
          justify-content: flex-end; } }
    .cs-topbar__item--language {
      height: 100%;
      display: none !important; }
  .cs-topbar__list,
  .cs-topbar__item > ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0; }
  .cs-topbar__list-item {
    margin-bottom: 0;
    height: 30px;
    display: flex;
    align-items: center;
    position: relative;
    flex-shrink: 0; }
    @media (min-width: 64em) {
      .cs-topbar__list-item {
        height: 30px !important; } }
    @media (max-width: 63.99em) {
      .cs-topbar__list-item {
        justify-content: center;
        padding: 0;
        margin: 0;
        opacity: 0; } }
    .cs-topbar__list-item:last-child {
      margin-right: 0;
      padding-right: 0; }
    @media (max-width: 63.99em) {
      .cs-topbar__list-item.swiper-slide-active {
        opacity: 1; } }
    .cs-topbar__list-item p {
      margin: 0;
      display: block;
      width: 100%;
      white-space: normal;
      text-align: center;
      line-height: normal; }
  .cs-topbar__text {
    color: #fff; }
  .cs-topbar__link {
    margin-right: 0.9rem; }
    .cs-topbar__link, .cs-topbar__link:visited {
      color: #383838;
      text-decoration: none; }
    .cs-topbar__link:hover, .cs-topbar__link:focus, .cs-topbar__link:active, .cs-topbar__link:visited:hover, .cs-topbar__link:visited:focus {
      color: #232222;
      text-decoration: none; }
    .cs-topbar__link:hover {
      color: #000; }
    @media (max-width: 63.99em) {
      .cs-topbar__link {
        display: none; } }
  .cs-topbar__hotline {
    display: flex;
    align-items: center; }
  .cs-topbar__phone-number {
    color: #000;
    font-weight: 700;
    margin-right: 0.9rem; }
    .cs-topbar__phone-number:hover {
      color: #000; }
  .cs-topbar-carousel, .cs-topbar-carousel__wrapper {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    height: 30px; }
  @media (max-width: 63.99em) {
    .cs-topbar-carousel {
      padding: 0 65px; } }
  .cs-topbar-button-next, .cs-topbar-button-prev {
    position: absolute;
    right: 50px;
    top: 9px;
    cursor: pointer; }
    .cs-topbar-button-next img, .cs-topbar-button-prev img {
      display: block; }
  .cs-topbar-button-prev {
    right: auto;
    left: 50px; }
  .cs-topbar-button-close {
    position: absolute;
    right: 12px;
    top: 9px;
    cursor: pointer; }
    @media (min-width: 64em) {
      .cs-topbar-button-close {
        right: 36px; } }
    .cs-topbar-button-close img {
      display: block; }

.cs-header__b2b-item {
  pointer-events: none;
  opacity: 0.5; }

@import 'config/colors';
@import 'config/variables';
@import 'vendors/include-media';
@import 'components/typography/mixin';

.#{$ns}catalogues {
    $root: &;

    &_top {
        text-align: center;
    }

    &_top_title {
        font-family: $font-family-name__arizonia;
        font-size: 56px;
        font-weight: 400;
        letter-spacing: 0.07em;
        color: $color_GoldD1;
        margin: 0;

        @include media('>=tablet') {
            font-size: 82px;
        }
    }

    &_top_subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 1px;
        color: $color_Black38;
        margin: 20px 0 23px;

        @include media('>=tablet') {
            font-size: 40px;
            line-height: 48px;
        }
    }

    &_top_description {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.03em;
        color: $color_black;
        opacity: 0.18;
        max-width: 677px;
        margin: 0 auto 35px;
        text-align: left;

        @include media('>=laptop') {
            margin-bottom: 100px;
        }
    }

    &_bottom {
        @include media('>=laptop') {
            padding: 0 56px;
        }

        &.active {
            .cs-catalogues_view-all {
                display: none !important;
            }

            .cs-catalogues_bottom_pagination,
            .cs-catalogues_bottom-list {
                height: auto;
            }
        }
    }

    &_bottom-list {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &_bottom-container {
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;

        .swiper-wrapper {
            @include media('>=laptop') {
                position: relative;
                width: 100%;
                height: 100%;
                z-index: 1;
                display: flex;
                transition-property: transform;
                box-sizing: content-box;
            }

            @include media('<laptop') {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @include media('<tablet') {
                grid-template-columns: minmax(0, 1fr);
            }
        }
    }

    &_bottom_item {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
        margin: 0;
        padding: 0;
        list-style: none;
        height: auto;
        display: flex;
        justify-content: center;

        @include media('<laptop') {
            padding: 0 10px;
        }
    }

    &_bottom_item_content {
        text-align: center;

        img {
            margin: 0 0 27px;
        }

        h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.03em;
            color: $color_Black23;
            margin: 0 0 17px;
        }

        a {
            background: $color_Black38;
            border: 1px solid $color_Black38;
            border-radius: 2px;
            color: $color_white;
            display: inline-block;
            height: 48px;
            line-height: 48px;
            width: 100%;
            max-width: 232px;
            font-size: 14px;
            letter-spacing: 0.03em;
            margin-bottom: 75px;

            &:hover {
                color: $color_Black38;
                background: $color_white;
            }
        }
    }

    &-button-prev,
    &-button-next {
        background: $color_Black38;
        border-radius: 2px;
        width: 56px;
        height: 52px;
        position: absolute;
        top: 50%;
        margin-top: -95px;
        left: 0;

        &:not(.swiper-button-disabled) {
            cursor: pointer;
        }

        &:before {
            content: "";
            border-bottom: 1px solid $color_white;
            width: 26px;
            height: 1px;
            position: absolute;
            top: 24px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        &:after {
            content: "";
            width: 6px;
            height: 6px;
            border-style: solid;
            border-width: 1px;
            border-color: $color_transparent $color_white $color_white $color_transparent;
            transform: rotate(135deg);
            position: absolute;
            left: 15px;
            top: 22px;
        }
    }

    &-button-next {
        left: auto;
        right: 0;

        &:after {
            transform: rotate(-45deg);
            left: auto;
            right: 15px;
        }
    }

    &_bottom_pagination,
    &_bottom-list {
        height: 0;
        overflow: hidden;
    }

    &_view-all {
        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        background: $color_white;
        border: 1px solid $color_Black38;
        border-radius: 2px;
        color: $color_Black38;
        height: 58px;
        line-height: 58px;
        width: 100%;
        max-width: 232px;
        margin: 0 auto;
        text-align: center;

        span {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
            padding-right: 35px;
            position: relative;

            &:before {
                content: "";
                border-bottom: 1px solid $color_Black38;
                width: 26px;
                height: 1px;
                position: absolute;
                top: 8px;
                right: 0;
            }

            &:after {
                content: "";
                width: 5px;
                height: 5px;
                border-style: solid;
                border-width: 1px;
                border-color: $color_transparent $color_Black38 $color_Black38 $color_transparent;
                transform: rotate(-45deg);
                position: absolute;
                right: 0;
                top: 6px;
            }
        }

        &:hover {
            background: $color_Black38;
            color: $color_white;

            span {
                &:before {
                    border-color: $color_white;
                }

                &:after {
                    border-color: $color_transparent $color_white $color_white $color_transparent;
                }
            }
        }
    }
}

.simple-pagination {
    ul {
        margin: 0 0 20px;
        padding: 0;
        list-style: none;
        text-align: center;
    }

    li {
        display: inline-block;
        margin-right: 14px;
        vertical-align: top;

        a,
        span {
            color: $color_Black38;
            font-size: 16px;
            width: 43px;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            border: 1px solid $color_GrayE7;
            border-radius: 50%;
            background-color: $color_white;

            &:hover {
                color: $color_white;
                background-color: $color_Black38;
                border-color: $color_Black38;
            }
        }
    }

    .current {
        color: $color_white;
        background-color: $color_Black38;
        border-color: $color_Black38;
    }

    .prev,
    .next {
        font-size: 0;
        position: relative;

        &:before {
            content: "";
            border-style: solid;
            border-width: 1px;
            border-color: $color_transparent $color_GrayD7 $color_GrayD7 $color_transparent;
            width: 8px;
            height: 8px;
            display: block;
            position: absolute;
            top: 16px;
            left: 15px;
            transform: rotate(-45deg);
        }
    }

    .prev {
        &:before {
            border-color: $color_GrayD7 $color_transparent $color_transparent $color_GrayD7;
            left: auto;
            right: 14px;
        }
    }
}

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-tile-gallery {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  z-index: 4;
  left: 0;
  right: auto;
  top: auto;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.15s linear; }
  @media (min-width: 64em) {
    .cs-tile-gallery {
      display: flex; } }
  .touch-device .cs-tile-gallery {
    display: none !important; }
  @media (min-width: 64em) {
    .cs-product-tile:hover .cs-tile-gallery.active {
      opacity: 1; } }
  .cs-tile-gallery__item {
    margin: 0 0.3rem 0 0;
    padding: 0;
    cursor: pointer; }
  .cs-tile-gallery__thumbnail {
    display: block;
    width: 4rem;
    height: 4rem;
    border: 1px solid #888;
    transition: border-color 0.15s linear;
    overflow: hidden;
    position: relative;
    background-image: url("../images/icons/tile-gallery/tile-gallery-spinner.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #fff; }
    .cs-tile-gallery__item--active .cs-tile-gallery__thumbnail {
      border-color: #232222; }
    .cs-tile-gallery__thumbnail > source,
    .cs-tile-gallery__thumbnail > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

.cs-product-tile__image--animatable {
  opacity: 0;
  transform: scale(2.5);
  transition: transform 0.5s cubic-bezier(0, 0.66, 0.32, 0.99), opacity 0.45s linear; }

.cs-product-tile__image--animate {
  opacity: 1;
  transform: scale(1); }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-dailydeal {
  display: none; }
  .cs-dailydeal__header {
    display: flex;
    width: 100%;
    margin: 0 0 0.5rem;
    font-size: 1.2rem;
    justify-content: space-between;
    align-items: flex-start; }
  .cs-dailydeal__title {
    margin: 0;
    text-transform: uppercase;
    font-weight: 600; }
  .cs-dailydeal__items-amount {
    font-weight: 600; }
  .cs-dailydeal__countdown {
    display: flex;
    margin: 0;
    justify-content: stretch;
    align-items: stretch; }
  .cs-dailydeal__countdown-element {
    position: relative;
    z-index: 3;
    width: 100%;
    padding: 0.3rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background: none;
    border-radius: 2px;
    margin-left: 3px;
    margin-right: 3px; }
    .cs-dailydeal__countdown-element:before {
      content: ' ';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: #f0f0f0;
      border-radius: inherit;
      transform: skew(-12deg); }
    .cs-dailydeal__countdown-element:first-child {
      margin-left: 0;
      background: #f0f0f0;
      margin-right: 6px; }
      .cs-dailydeal__countdown-element:first-child:before {
        transform-origin: bottom left; }
    .cs-dailydeal__countdown-element:last-child:not(:only-child) {
      margin-right: 0;
      background: #f0f0f0;
      margin-left: 6px; }
      .cs-dailydeal__countdown-element:last-child:not(:only-child):before {
        left: auto;
        right: 0;
        transform-origin: top right; }
    .cs-dailydeal__countdown-element--special {
      width: 50%;
      background: #e41f12;
      z-index: 5; }
      .cs-dailydeal__countdown-element--special:first-child, .cs-dailydeal__countdown-element--special:first-child:before {
        background: #e41f12; }
    .cs-dailydeal--tile .cs-dailydeal__countdown-element {
      margin-left: 1px;
      margin-right: 1px; }
      .cs-dailydeal--tile .cs-dailydeal__countdown-element:first-child {
        margin-right: 5px; }
      .cs-dailydeal--tile .cs-dailydeal__countdown-element:last-child:not(:only-child) {
        margin-left: 5px; }
    .cs-dailydeal--teaser .cs-dailydeal__countdown-element {
      min-height: 4rem; }
  .cs-dailydeal__countdown-icon {
    width: 1.9rem;
    height: 2.4rem;
    fill: #fff; }
  .cs-dailydeal__countdown-value {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    color: #e41f12;
    padding: 2px 0 0; }
  .cs-dailydeal__countdown-label {
    display: block;
    font-size: 1.1rem;
    font-weight: inherit;
    color: #888;
    padding: 2px 0 0; }
  .cs-dailydeal--tile {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3; }
    .cs-dailydeal--tile .cs-dailydeal__availability {
      position: relative;
      background: #888;
      font-size: 1rem;
      color: #fff;
      padding: 2px 4px;
      z-index: 4;
      font-weight: 300;
      border-radius: 2px; }
      .cs-dailydeal--tile .cs-dailydeal__availability:before {
        content: ' ';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: #888;
        border-radius: inherit;
        transform: skew(-12deg); }
      .cs-dailydeal--tile .cs-dailydeal__availability:first-child {
        margin-left: 0;
        background: #888; }
        .cs-dailydeal--tile .cs-dailydeal__availability:first-child:before {
          transform-origin: bottom left; }
      .cs-dailydeal--tile .cs-dailydeal__availability:last-child:not(:only-child) {
        margin-right: 0;
        background: #888; }
        .cs-dailydeal--tile .cs-dailydeal__availability:last-child:not(:only-child):before {
          left: auto;
          right: 0;
          transform-origin: top right; }

@media (min-width: 23.125em) and (max-width: 599px) {
  .cs-dailydeal--tile .cs-dailydeal__countdown-element {
    margin-left: 0;
    margin-right: 0; }
    .cs-dailydeal--tile .cs-dailydeal__countdown-element:last-child:not(:only-child) {
      margin-left: 0; }
    .cs-dailydeal--tile .cs-dailydeal__countdown-element:last-child .cs-dailydeal__countdown-value:after {
      content: none; }
    .cs-dailydeal--tile .cs-dailydeal__countdown-element--special {
      width: auto; }
    .cs-dailydeal--tile .cs-dailydeal__countdown-element:not(.cs-dailydeal__countdown-element--special) {
      border-radius: 0;
      width: auto;
      padding: 0; }
      .cs-dailydeal--tile .cs-dailydeal__countdown-element:not(.cs-dailydeal__countdown-element--special):before {
        transform: none; }
      .cs-dailydeal--tile .cs-dailydeal__countdown-element:not(.cs-dailydeal__countdown-element--special):nth-child(2) {
        padding-left: 1.8rem;
        margin-left: -1rem !important; }
      .cs-dailydeal--tile .cs-dailydeal__countdown-element:not(.cs-dailydeal__countdown-element--special):last-child {
        padding-right: 0.8rem;
        border-radius: 0 2px 2px 0; }
  .cs-dailydeal--tile .cs-dailydeal__countdown-value {
    font-size: 1.3rem; }
    .cs-dailydeal--tile .cs-dailydeal__countdown-value:after {
      content: ':'; }
  .cs-dailydeal--tile .cs-dailydeal__countdown-label {
    display: none; } }
  .cs-dailydeal__badge-pdp-icon {
    width: 1.5rem;
    height: 1.5rem;
    z-index: 10;
    fill: #fff; }

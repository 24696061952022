/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-logo {
  width: 70px;
  height: 29px; }
  @media (min-width: 48em) {
    .cs-logo {
      width: 157px;
      height: 66px; } }
  @media (min-width: 64em) {
    .cs-logo {
      position: absolute;
      left: 14px; }
      .page-wrapper-scroll .cs-logo {
        left: 41px; } }
  .cs-logo__image {
    height: 100%; }
    .cs-logo__image--desktop {
      display: none; }
      .checkout-index-index .cs-logo__image--desktop {
        display: block; }
      @media (min-width: 48em) {
        .cs-logo__image--desktop {
          display: block; } }
    .cs-logo__image--mobile {
      display: block; }
      @media (min-width: 48em) {
        .cs-logo__image--mobile {
          display: none; } }
      .checkout-index-index .cs-logo__image--mobile {
        display: none; }

@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';

@import 'components/qty-counter/mixin';

$header-user-nav_items: 3 !default; // number of items in header user nav (without search item)

$header-user-nav_icon-color: $color_Black3F3F41 !default;
$header-user-nav_icon-color-hover: $color_Black3F3F41 !default;
$header-user-nav_icon-width: 1.7rem !default;
$header-user-nav_icon-height: 1.9rem !default;

// Set up when label in header user nav should be visible
// Supported values: true (always) | false (never) | breakpoint
$header-user-nav_label-visible: '>=tablet' !default;
$header-user-nav_label-font-size: 1rem !default;
$header-user-nav_label-font-weight: 300 !default;
$header-user-nav_label-text-transform: uppercase !default;
$header-user-nav_label-color: $color_text-500 !default;
$header-user-nav_label-color-hover: $color_text-500 !default;
$header-user-nav_label-margin-top: 0.3rem !default;

$header-user-nav_qty-counter-wishlist-show-empty: false !default;
$header-user-nav_qty-counter-minicart-show-empty: false !default;
$header-user-nav_qty-counter-animation-show: true !default;
$header-user-nav_qty-counter-animation-time: $transition-default-time !default;

$header-user-nav_qty-counter-top-offset: -0.5rem !default;
$header-user-nav_qty-counter-right-offset-mobile: -0.5rem !default;
$header-user-nav_qty-counter-right-offset-tablet: -1rem !default;
$header-user-nav_qty-counter-right-offset-desktop: -1rem !default;

.#{$ns}header-user-nav {
    $root: &;

    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    order: 5;

    @include media('<laptop') {
        order: 3;
    }

    //@include media('>=laptop') {
    //    margin-right: -10px;
    //}

    &__item {
        margin-bottom: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &--search {
            display: none !important;

            @if ($global_header-hide-search-item-trigger-breakpoint) {
                @if (
                    $global_header-hide-search-item-trigger-breakpoint == true
                ) {
                    display: none;
                } @else {
                    @include media(
                        $global_header-hide-search-item-trigger-breakpoint
                    ) {
                        display: none;
                    }
                }
            }
        }

        &--account {
            @include media('<laptop') {
                display: none !important;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        width: 100%;
        padding: 1rem;

        &:hover {
            text-decoration: none;

            #{$root}__icon {
                path {
                    fill: $header-user-nav_icon-color-hover;
                }
            }

            #{$root}__label {
                color: $header-user-nav_label-color-hover;
            }
        }

        &--search {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__icon-wrapper {
        position: relative;
    }

    &__icon {
        width: $header-user-nav_icon-width;
        height: $header-user-nav_icon-height;
        display: block;
        margin: 0 auto;

        @include media('>=laptop') {
            width: 23px;
        }

        path {
            fill: $header-user-nav_icon-color;
        }

        &--wishlist {
            width: 1.9rem;
            height: 1.7rem;
        }
    }

    &__label {
        text-transform: $header-user-nav_label-text-transform;
        font-size: $header-user-nav_label-font-size;
        font-weight: $header-user-nav_label-font-weight;
        margin-top: $header-user-nav_label-margin-top;
        color: $header-user-nav_label-color;
        text-decoration: none;

        @include visually-hidden();
    }

    &__qty-counter {
        @include qty-counter();

        position: absolute;
        right: $header-user-nav_qty-counter-right-offset-mobile;
        top: $header-user-nav_qty-counter-top-offset;
        opacity: 0;

        @if (
            $header-user-nav_qty-counter-right-offset-mobile !=
                $header-user-nav_qty-counter-right-offset-tablet
        ) {
            @include media('>=tablet') {
                right: $header-user-nav_qty-counter-right-offset-tablet;
            }
        }

        @if (
            $header-user-nav_qty-counter-right-offset-tablet !=
                $header-user-nav_qty-counter-right-offset-desktop
        ) {
            @include media('>=laptopLg') {
                right: $header-user-nav_qty-counter-right-offset-desktop;
            }
        }

        @if ($header-user-nav_qty-counter-animation-show) {
            transition: opacity $header-user-nav_qty-counter-animation-time;
        }

        &--wishlist {
            @include qty-counter_type('wishlist');
        }

        &--minicart {
            @include qty-counter_type('minicart');
        }

        &--have-items {
            opacity: 1;
        }

        &--empty {
            &#{$root}__qty-counter--wishlist {
                @if ($header-user-nav_qty-counter-wishlist-show-empty) {
                    opacity: 1;
                }
            }

            &#{$root}__qty-counter--minicart {
                @if ($header-user-nav_qty-counter-minicart-show-empty) {
                    opacity: 1;
                }
            }
        }
    }

    &__qty-counter-span {
        @include qty-counter_span();
    }
}

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cms-home .columns .column.main {
  display: flex;
  flex-direction: column; }

.Homepage_row_7-loggedin:not(.logged_in) {
  display: none !important; }

.homepage_search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0; }
  @media (min-width: 48em) {
    .homepage_search {
      padding: 15px 0; } }
  .homepage_search_text {
    font-size: 10px;
    line-height: 12px;
    color: #F8F8F8;
    margin: 0 9px 0 16px; }
    @media (min-width: 48em) {
      .homepage_search_text {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1px;
        margin: 0 36px; } }
  .homepage_search_link {
    border: 1px solid #fff;
    border-radius: 1px;
    font-size: 14px;
    line-height: 17px;
    color: #F8F8F8 !important;
    padding: 12px 15px;
    flex-shrink: 0; }
    @media (min-width: 48em) {
      .homepage_search_link {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        padding: 17px 36px; } }
    .homepage_search_link:hover, .homepage_search_link:active {
      color: #F8F8F8 !important;
      text-decoration: none !important; }

.homepage_showroom {
  padding: 74px 0 0; }
  @media (max-width: 47.99em) {
    .homepage_showroom {
      display: none !important; } }
  .homepage_showroom .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper {
    position: static;
    height: 100% !important; }
    @media (min-width: 48em) {
      .homepage_showroom .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper {
        display: flex;
        align-items: center; } }
    .homepage_showroom .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper:hover {
      text-decoration: none; }
  .homepage_showroom .cs-image-teaser__content-wrapper {
    padding: 0 0 0 127px !important;
    position: static;
    height: auto; }
  .homepage_showroom .cs-image-teaser__aspect-ratio {
    display: none !important; }
  .homepage_showroom .cs-image-teaser__slogan {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 1px;
    color: #353535; }
  .homepage_showroom .cs-image-teaser__description {
    display: flex;
    flex-direction: column;
    margin-top: 18px; }
    .homepage_showroom .cs-image-teaser__description p {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.135em;
      color: #000;
      opacity: 0.18;
      max-width: 531px;
      margin-bottom: 14px !important; }
    .homepage_showroom .cs-image-teaser__description strong {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.135em;
      color: #232222;
      margin-bottom: 9px; }
  .homepage_showroom .cs-image-teaser__slide .cs-image-teaser__cta-button {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.135em;
    color: #D1D1D1;
    border-color: #D1D1D1;
    background: transparent; }
  .homepage_showroom .cs-image-teaser__cta-button-span {
    padding-right: 45px !important;
    border-color: #CBCBCB !important;
    color: #D1D1D1 !important; }
    .homepage_showroom .cs-image-teaser__cta-button-span::before {
      content: "";
      position: absolute;
      width: 35px;
      height: 1px;
      background-color: #D7D7D7;
      top: 22px;
      right: 16px; }
    .homepage_showroom .cs-image-teaser__cta-button-span::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-color: #D7D7D7;
      border-style: solid;
      border-width: 0 1px 1px 0;
      top: 19px;
      right: 16px;
      transform: rotate(-45deg); }

.homepage_popular {
  margin: 30px 0 0; }
  @media (min-width: 48em) {
    .homepage_popular .cs-products-carousel__pagination {
      display: none !important; } }
  @media (max-width: 47.99em) {
    .homepage_popular .cs-products-carousel__nav {
      top: -41px; } }

@media (max-width: 47.99em) {
  .homepage-main-slider {
    order: 0; } }

@media (max-width: 47.99em) {
  .homepage-main-slider .cs-image-teaser__nav {
    display: none !important; } }

.homepage_top_search {
  margin-top: 100px; }
  @media (max-width: 47.99em) {
    .homepage_top_search {
      display: none !important; } }
  .homepage_top_search .cs-image-teaser__nav,
  .homepage_top_search .cs-image-teaser__pagination {
    display: none !important; }
  .homepage_top_search .cs-image-teaser__slide {
    width: auto !important;
    padding: 10px 6px;
    display: inline-block !important;
    vertical-align: top; }
  .homepage_top_search .cs-image-teaser__wrapper--content-under .cs-image-teaser__content-wrapper {
    padding: 0; }
  .homepage_top_search .cs-image-teaser__wrapper--content-under .cs-image-teaser__content {
    padding: 0; }
  .homepage_top_search .cs-image-teaser__slides {
    display: block;
    width: 100%;
    max-width: 1010px;
    margin: 0 auto;
    text-align: center;
    transform: none !important; }
  .homepage_top_search .cs-image-teaser__slide .cs-image-teaser__cta-button {
    font-size: 16px;
    background: #D1C9AB;
    border-radius: 33px;
    padding: 13px 36px; }
  .homepage_top_search .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta-button-span {
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #fff; }
  .homepage_top_search .cs-image-teaser__content {
    margin: 0 !important; }
  .homepage_top_search .cs-image-teaser__cta {
    margin: 0 !important; }

.homepage_blog {
  margin: 10px 0 0;
  position: relative; }
  @media (min-width: 48em) {
    .homepage_blog {
      margin: 70px 0 0; } }
  .homepage_blog .widget.block-static-block {
    margin: 0; }
  .homepage_blog .widget-title {
    display: none; }
  @media (min-width: 48em) {
    .homepage_blog .row {
      display: flex; } }
  @media (max-width: 47.99em) {
    .homepage_blog .cs-headline {
      padding: 10px 1.5rem 15px; } }
  @media (max-width: 47.99em) {
    .homepage_blog .post-list-container {
      padding: 0 1.5rem; } }
  @media (max-width: 47.99em) {
    .homepage_blog .cs-image-teaser__nav {
      display: block; } }
  @media (max-width: 47.99em) {
    .homepage_blog .cs-image-teaser__nav--prev {
      right: 61px; } }
  @media (max-width: 47.99em) {
    .homepage_blog .cs-image-teaser__nav--next {
      right: 15px; } }
  .homepage_blog .post-list-item {
    margin-bottom: 0; }
    @media (min-width: 48em) {
      .homepage_blog .post-list-item {
        margin: 0 !important;
        width: 33.3333%; } }
  .homepage_blog .post-item-wraper {
    border: 0 none !important;
    padding: 0 !important; }
    @media (min-width: 48em) {
      .homepage_blog .post-item-wraper {
        padding: 0 57px !important; } }
    .homepage_blog .post-item-wraper:hover {
      border: 0 none !important;
      box-shadow: none !important; }
  .homepage_blog .post-image {
    position: relative; }
    @media (min-width: 48em) {
      .homepage_blog .post-image {
        margin: 0 -57px; } }
  .homepage_blog .mp-post-info {
    background: rgba(255, 255, 255, 0.84);
    color: #000;
    width: 52px;
    height: 52px;
    position: absolute;
    top: 9px;
    left: 7px;
    font-size: 10px;
    line-height: 12px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    overflow: hidden; }
    @media (min-width: 48em) {
      .homepage_blog .mp-post-info {
        width: 121px;
        height: 121px;
        top: 30px;
        left: 30px;
        font-size: 18px;
        line-height: 22px; } }
    .homepage_blog .mp-post-info span {
      color: #000;
      font-weight: 600;
      display: block;
      border-bottom: 1px solid #CCCCCC;
      padding-bottom: 5px;
      margin-bottom: 4px; }
      @media (min-width: 48em) {
        .homepage_blog .mp-post-info span {
          border-bottom: 0 none;
          padding-bottom: 0;
          margin-bottom: 7px; } }
      @media (min-width: 48em) {
        .homepage_blog .mp-post-info span::after {
          content: "";
          width: 38px;
          height: 0;
          border: 1px solid #CCCCCC;
          margin: 6px auto 0;
          display: block; } }
  .homepage_blog .post-info-wraper .mp-post-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #383838;
    margin: 0 0 11px;
    text-transform: none; }
    @media (min-width: 48em) {
      .homepage_blog .post-info-wraper .mp-post-title {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.01em;
        margin: 0 0 20px; } }
  .homepage_blog .post-short-description {
    font-size: 12px;
    line-height: 14px;
    color: #383838;
    opacity: 0.18;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (min-width: 48em) {
      .homepage_blog .post-short-description {
        font-size: 16px;
        line-height: 19px;
        -webkit-line-clamp: 3; } }
  .homepage_blog .post-link-title {
    color: #383838; }
  .homepage_blog .post-static-title {
    display: none; }
    @media (min-width: 48em) {
      .homepage_blog .post-static-title {
        color: #383838;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.01em;
        display: block;
        margin: 0 0 10px; } }
  .homepage_blog .post-info-wraper {
    padding: 11px 0; }
    @media (min-width: 48em) {
      .homepage_blog .post-info-wraper {
        padding: 31px 0; } }
  .homepage_blog .mp-read-more {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #000;
    position: relative;
    padding-right: 45px;
    display: none;
    margin-top: 19px; }
    @media (min-width: 48em) {
      .homepage_blog .mp-read-more {
        display: inline-block; } }
    .homepage_blog .mp-read-more span {
      opacity: 0.18; }
    .homepage_blog .mp-read-more::before {
      content: "";
      position: absolute;
      width: 35px;
      height: 1px;
      background-color: #D6D6D6;
      top: 9px;
      right: 0; }
    .homepage_blog .mp-read-more::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-color: #D6D6D6;
      border-style: solid;
      border-width: 0 1px 1px 0;
      top: 6px;
      right: 0;
      transform: rotate(-45deg); }
    .homepage_blog .mp-read-more:hover {
      color: #000;
      text-decoration: none; }

.homepage_take_peek {
  margin: 30px 0 0; }
  @media (min-width: 48em) {
    .homepage_take_peek {
      max-width: 1556px;
      margin: 100px auto 0; } }
  .homepage_take_peek .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper {
    position: static;
    height: 100% !important; }
    @media (min-width: 48em) {
      .homepage_take_peek .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper {
        display: flex;
        align-items: center; } }
    .homepage_take_peek .cs-image-teaser__aspect-ratio ~ .cs-image-teaser__slide-wrapper:hover {
      text-decoration: none; }
  .homepage_take_peek .cs-image-teaser__content-wrapper {
    padding: 0 !important;
    position: static;
    height: auto;
    max-width: 600px; }
  .homepage_take_peek .cs-image-teaser__aspect-ratio {
    display: none !important; }
  .homepage_take_peek .cs-image-teaser__figure {
    order: 1; }
  .homepage_take_peek .cs-image-teaser__slogan {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87); }
    @media (max-width: 47.99em) {
      .homepage_take_peek .cs-image-teaser__slogan {
        display: none !important; } }
    @media (min-width: 48em) {
      .homepage_take_peek .cs-image-teaser__slogan {
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: 1px; } }
  .homepage_take_peek .cs-image-teaser__description {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    max-width: 531px; }
    @media (min-width: 48em) {
      .homepage_take_peek .cs-image-teaser__description {
        margin-top: 48px; } }
    .homepage_take_peek .cs-image-teaser__description strong {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #383838; }
      @media (min-width: 48em) {
        .homepage_take_peek .cs-image-teaser__description strong {
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 1px; } }
    .homepage_take_peek .cs-image-teaser__description p {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.03em;
      color: #000;
      opacity: 0.18;
      margin-top: 6px !important; }
      @media (min-width: 48em) {
        .homepage_take_peek .cs-image-teaser__description p {
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 1px;
          margin-top: 30px !important; } }
  .homepage_take_peek .cs-image-teaser__slide .cs-image-teaser__cta-button {
    background: none;
    border: 1px solid #C1C1C1;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 14px;
    display: block; }
    @media (min-width: 48em) {
      .homepage_take_peek .cs-image-teaser__slide .cs-image-teaser__cta-button {
        display: inline-block;
        padding: 18px 40px; } }
  .homepage_take_peek .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta-button-span {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #C1C1C1; }
    @media (min-width: 48em) {
      .homepage_take_peek .cs-image-teaser__slide--scheme-dark .cs-image-teaser__cta-button-span {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.135em;
        color: #D1D1D1; } }
  .homepage_take_peek .cs-image-teaser__cta {
    margin-top: 9px; }
    @media (min-width: 48em) {
      .homepage_take_peek .cs-image-teaser__cta {
        margin-top: 31px; } }

.homepage_instagram {
  overflow: hidden;
  position: relative; }
  @media (max-width: 47.99em) {
    .homepage_instagram {
      margin-top: 30px; } }
  .homepage_instagram .block-static-block {
    margin: 0 -2px 0; }
    @media (min-width: 48em) {
      .homepage_instagram .block-static-block {
        margin: 120px -3px 0; } }
  @media (max-width: 47.99em) {
    .homepage_instagram .cs-headline {
      padding: 10px 1.5rem 13px; } }
  @media (max-width: 47.99em) {
    .homepage_instagram .cs-image-teaser__nav {
      display: block; } }
  @media (max-width: 47.99em) {
    .homepage_instagram .cs-image-teaser__nav--prev {
      right: 61px; } }
  @media (max-width: 47.99em) {
    .homepage_instagram .cs-image-teaser__nav--next {
      right: 15px; } }

.homepage_recently_viewed_products .cs-products-carousel__pagination {
  display: none !important; }

@media (min-width: 48em) {
  .homepage_recently_viewed_products .block.products-upsell {
    display: flex;
    align-items: center; } }

@media (min-width: 48em) {
  .homepage_recently_viewed_products .cs-container--headline {
    flex-shrink: 0;
    padding-right: 60px !important;
    width: auto; } }

.homepage_recently_viewed_products .cs-headline__title,
.homepage_recently_viewed_products .cs-headline {
  margin: 0; }

@media (min-width: 48em) {
  .homepage_recently_viewed_products .cs-container--products-carousel {
    width: calc(100% - 446px);
    padding: 0 52px !important; } }

@media (min-width: 48em) {
  .homepage_recently_viewed_products .cs-products-carousel:not(.cs-products-carousel--full-width-slider) .cs-products-carousel__nav--next {
    right: -52px; }
  .homepage_recently_viewed_products .cs-products-carousel:not(.cs-products-carousel--full-width-slider) .cs-products-carousel__nav--prev {
    left: -52px; } }

@media (min-width: 48em) {
  .homepage_collections .cs-container--headline {
    display: none !important; } }

.homepage_collections .cs-image-teaser__wrapper {
  margin: 0;
  width: 100%; }

@media (max-width: 47.99em) {
  .homepage_collections .cs-image-teaser__nav {
    top: -41px; } }

@media (max-width: 47.99em) {
  .homepage_collections .cs-container--hero-teaser {
    overflow: visible; } }

.homepage_shopby_categories .cs-headline {
  margin-bottom: 15px; }

.homepage_shopby_categories .cs-image-teaser {
  margin: 0; }

.homepage_shopby_categories .cs-image-teaser__slide {
  width: 50%;
  padding: 0 !important;
  margin: 0 0 14px; }

.homepage_shopby_categories .cs-image-teaser__content-wrapper {
  position: static;
  padding: 0 !important;
  align-items: center; }

.homepage_shopby_categories .cs-image-teaser__slide-wrapper {
  height: 100% !important;
  display: flex;
  align-items: center;
  position: static !important; }

.homepage_shopby_categories .cs-image-teaser__aspect-ratio {
  display: none !important; }

.homepage_shopby_categories .cs-image-teaser__figure {
  width: 54px;
  padding-right: 12px;
  flex-shrink: 0; }

.homepage_shopby_categories .cs-image-teaser__slogan {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #3F3F41; }

.homepage_shopby_categories .cs-image-teaser__cta {
  margin-top: 0; }

.homepage_shopby_categories .cs-image-teaser__cta-button {
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #C1C1C1;
  background: transparent;
  border-color: #C1C1C1; }
  .homepage_shopby_categories .cs-image-teaser__cta-button span {
    color: #C1C1C1;
    padding-right: 45px;
    position: relative; }
    .homepage_shopby_categories .cs-image-teaser__cta-button span::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 1px;
      background-color: #C1C1C1;
      top: 6px;
      right: 16px; }
    .homepage_shopby_categories .cs-image-teaser__cta-button span::after {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      border-color: #C1C1C1;
      border-style: solid;
      border-width: 0 1px 1px 0;
      top: 4px;
      right: 16px;
      transform: rotate(-45deg); }

@media (max-width: 47.99em) {
  .homepage_categories {
    margin-top: 30px; } }

@media (max-width: 47.99em) {
  .Homepage_row_0 {
    order: 1; } }

@media (max-width: 47.99em) {
  .Homepage_row_1_mobile {
    order: 2; } }

@media (min-width: 48em) {
  .Homepage_row_1_mobile {
    display: none !important; } }

@media (max-width: 47.99em) {
  .Homepage_row_1 {
    order: 3; } }

@media (max-width: 47.99em) {
  .Homepage_row_2 {
    order: 4; } }

@media (max-width: 47.99em) {
  .Homepage_row_3.cs-container {
    order: 4;
    margin-top: 10px; } }

@media (max-width: 47.99em) {
  .Homepage_row_5,
  .homepage_row_55,
  .Homepage_row_55 {
    order: 5; } }

@media (max-width: 47.99em) {
  .Homepage_row_7 {
    order: 6; } }

@media (max-width: 47.99em) {
  .Homepage_row_8 {
    order: 8; } }

@media (max-width: 47.99em) {
  .Homepage_row_9 {
    order: 9; } }

@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/component-spacing';
@import 'components/headline/mixin';

.#{$ns}headline {
    @include headline();

    margin: 0;
    padding: 0;

    @include component-spacing();

    .block.products-upsell &,
    .block.products-recently & {
        margin: 0;

        @include media('<tablet') {
            padding: 10px 0 0;
            min-height: 42px;
        }

        .cs-headline__title {
            margin: 0;

            @include media('<tablet') {
                padding-right: 100px;
            }
        }
    }

    &__title {
        @include headline-title();
    }

    &__subtitle {
        @include headline-subtitle();
    }
}

@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/get-value-from-list';
@import 'components/pseudoicon/mixin';
@import 'utils/deep-get/deep-get.scss';

$offcanvas-navigation_background: $color_background-200 !default;

$offcanvas-navigation_item-background: $offcanvas-navigation_background !default;
$offcanvas-navigation_item-border: 1px $color_alto solid !default;

$offcanvas-navigation_search-enabled: deep-get(
    $view-xml,
    vars,
    Magento_Theme,
    header,
    mobile_search_in_offcanvas
) !default;

$offcanvas-navigation_search-enabled-list-level1-top-position: 14% !default;

$offcanvas-navigation_link-color: $color_text-800 !default;
$offcanvas-navigation_link-color--hover: $offcanvas-navigation_link-color !default;
$offcanvas-navigation_link-font-size: 1.4rem !default;
$offcanvas-navigation_link-font-weight: 400 !default;
$offcanvas-navigation_link-sign-color: $color_primary-500 !default;
$offcanvas-navigation_link-sign-color--hover: $offcanvas-navigation_link-sign-color !default;
$offcanvas-navigation_link-active-color: $color_primary-500 !default;
$offcanvas-navigation_link-padding: 1.6rem 2.3rem 1.6rem 3.5rem !default;

$offcanvas-navigation_icon-use-pseudoicon-instead-svg: true !default;
$offcanvas-navigation_icon-width: 0.7rem !default;
$offcanvas-navigation_icon-height: 0.1rem !default;
$offcanvas-navigation_icon-color: $color_Black3F3F41 !default;
$offcanvas-navigation_icon-color--hover: $color_Black3F3F41 !default;
// Below navigation_icon settings are usable only if you use pseudoicon
$offcanvas-navigation_icon-type: 'arrow' !default;
$offcanvas-navigation_icon-default--next: 'right' !default;
$offcanvas-navigation_icon-default--prev: 'left' !default;
$offcanvas-navigation_icon-animation: false !default;
$offcanvas-navigation_icon-arrow-left-offset: 0 !default;

$offcanvas-navigation_icon-sign-color: $color_primary-500 !default;
$offcanvas-navigation_icon-sign-color--hover: $offcanvas-navigation_icon-sign-color !default;

$offcanvas-navigation_category-icon_max-height: 4rem !default;
$offcanvas-navigation_category-icon_margin-right: 1rem !default;

.#{$ns}offcanvas-navigation {
    $root: &;

    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $offcanvas-navigation_background;

    &__list {
        z-index: 1;
        width: 100%;
        min-width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: $offcanvas-navigation_item-background;
        overflow-y: hidden;
        overflow-x: hidden;
        height: 100%;
        -webkit-overflow-scrolling: touch;
        display: none;

        &--level_0,
        &--current,
        &--active {
            display: block;
        }

        &--level_0,
        &--current {
            overflow-y: auto;
        }

        &--current {
            > #{$root}__item {
                > #{$root}__list {
                    display: block;
                }
            }
        }

        & & {
            position: absolute;
            left: 105%;
            height: 100%;
            top: 0;
            transition: transform 0.3s;

            // Prevent overlapping of search on navigation list
            @if ($offcanvas-navigation_search-enabled) {
                top: $offcanvas-navigation_search-enabled-list-level1-top-position;
            }

            &--active {
                transform: translateX(-105%);
                transform: translate3d(-105%, 0, 0);
            }
        }
    }

    &__flyout {
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        top: 150px;
        background: #fff;
        box-shadow: 0px 18px 18px rgba(0, 0, 0, 0.09);
        padding: 54px 0;
        height: calc(100% - 150px);

        @include media('>=laptop') {
            overflow: hidden;
        }

        .cs-container__inner {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .cs-container {
            height: 100%;
        }

        .cs-offcanvas-navigation__item_cms_parent & {
            display: block;
            padding: 0;
            height: 0;
            max-height: calc(100% - 150px);

            .cs-container {
                padding-top: 54px;
                padding-bottom: 54px;
            }
        }
    }

    &__collection {
        display: flex;
        flex-wrap: wrap;
    }

    &__collection_item {
        color: $color_Black3F3F41;
        font-size: 16px;
        line-height: 19px;
        width: calc(25% - 53px);
        margin-left: 70px;
        margin-bottom: 50px;

        &:nth-child(4n+1) {
            margin-left: 0;
        }

        a + a {
            color: $color_Black3F3F41;
            margin-top: 17px;
            display: inline-block;
        }
    }

    &__item {
        margin: 0;

        @include media('<laptop') {
            width: 100%;
            display: flex;
            overflow: hidden;
        }

        @include media('>=laptop') {
            padding: 0 5px;
        }

        &--with-icon {
            #{$root}__text {
                position: relative;
                padding-left: get-value-from-list(
                    $offcanvas-navigation_link-padding,
                    'left'
                );
            }
        }

        &--b2b_switcher {
            padding: 0 32px 24px;
            border-bottom: 1px solid rgba(33, 33, 33, 0.08);
            display: flex;
            justify-content: flex-end;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.08em;
            text-transform: uppercase;

            .b2b-common-text {
                margin-right: 8px;
            }

            .switch {
                position: relative;
                display: inline-block;
                width: 30px;
                height: 12px;

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $color_GrayE7;
                    -webkit-transition: .4s;
                    transition: .4s;

                    &:before {
                        position: absolute;
                        content: "";
                        height: 12px;
                        width: 12px;
                        left: 0;
                        bottom: 0;
                        background-color: $color_Black38;
                        -webkit-transition: .4s;
                        transition: .4s;
                    }

                    &.round {
                        border-radius: 34px;

                        &:before {
                            border-radius: 50%;
                        }
                    }
                }

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;

                    &:checked + .slider {
                        background-color: $color_Blue488fe4;

                        &:before {
                            -webkit-transform: translateX(18px);
                            -ms-transform: translateX(18px);
                            transform: translateX(18px);
                        }
                    }
                }
            }
        }
    }

    &__link {
        min-width: 100%;
        width: 100%;
        display: flex;
        padding: $offcanvas-navigation_link-padding;
        font-size: $offcanvas-navigation_link-font-size;
        align-items: center;
        font-weight: $offcanvas-navigation_link-font-weight;
        position: relative;
        letter-spacing: 0.1px;

        .cs-offcanvas-navigation__item--level_0 > & {
            font-weight: 600;
        }

        @include media('>=laptop') {
            padding: 0;
            white-space: nowrap;
            font-size: 1.3rem;
            height: 64px;
        }

        @include media('>=laptopLg') {
            font-size: 1.6rem;
        }

        &,
        &:visited {
            color: $offcanvas-navigation_link-color;
        }

        &:hover {
            color: $offcanvas-navigation_link-color--hover;
            text-decoration: none;

            @if not($offcanvas-navigation_icon-use-pseudoicon-instead-svg) {
                #{$root}__icon {
                    path {
                        fill: $offcanvas-navigation_icon-color--hover;
                    }
                }
            }

            //& + .cs-offcanvas-navigation__flyout {
            //    display: block;
            //    position: fixed;
            //    left: 0;
            //    right: 0;
            //    top: 152px;
            //    background: #fff;
            //    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.09);
            //    padding: 54px 0;
            //}

        }

        &--sign-in,
        &--sign-out {
            padding-right: 5rem;

            &,
            &:visited {
                color: $offcanvas-navigation_link-sign-color;
            }

            &:hover {
                color: $offcanvas-navigation_link-sign-color--hover;

                #{$root}__icon {
                    path {
                        fill: $offcanvas-navigation_icon-sign-color--hover;
                    }
                }
            }

            #{$root}__icon {
                width: 2.3rem;
                height: 2.7rem;
                right: get-value-from-list(
                    $offcanvas-navigation_link-padding,
                    'right'
                );

                path {
                    fill: $offcanvas-navigation_icon-sign-color;
                }
            }
        }

        &--parent,
        &--return {
            #{$root}__icon {
                width: $offcanvas-navigation_icon-width;
                height: $offcanvas-navigation_icon-width;
            }
        }

        &--parent {
            padding-right: 5rem;

            #{$root}__icon {
                right: get-value-from-list(
                    $offcanvas-navigation_link-padding,
                    'right'
                );
            }
        }

        &--return {
            padding-left: 4rem;
            font-size: 18px;
            font-weight: 500;
            border-bottom: 1px solid rgba(33, 33, 33, 0.08);

            #{$root}__icon {
                left: get-value-from-list(
                    $offcanvas-navigation_link-padding,
                    'left'
                );
            }
        }

        @if ($offcanvas-navigation_icon-use-pseudoicon-instead-svg) {
            &--next {
                @include pseudoicon(
                    $type: $offcanvas-navigation_icon-type,
                    $animated: $offcanvas-navigation_icon-animation,
                    $arrow-default: $offcanvas-navigation_icon-default--next,
                    $arrow-offset: $offcanvas-navigation_icon-arrow-left-offset,
                    $width: $offcanvas-navigation_icon-width,
                    $height: $offcanvas-navigation_icon-height,
                    $color: $offcanvas-navigation_icon-color,
                    $color-hover: $offcanvas-navigation_icon-color--hover,
                    $position-side-offset:
                        get-value-from-list(
                            $offcanvas-navigation_link-padding,
                            'right'
                        )
                );
            }

            &--prev {
                @include pseudoicon(
                    $type: $offcanvas-navigation_icon-type,
                    $animated: $offcanvas-navigation_icon-animation,
                    $arrow-default: $offcanvas-navigation_icon-default--prev,
                    $arrow-offset: $offcanvas-navigation_icon-arrow-left-offset,
                    $width: $offcanvas-navigation_icon-width,
                    $height: $offcanvas-navigation_icon-height,
                    $color: $offcanvas-navigation_icon-color,
                    $color-hover: $offcanvas-navigation_icon-color--hover,
                    $position-side-offset: auto
                );

                &:before,
                &:after {
                    left: 1.5rem;
                }
            }
        }
    }

    .active {
        > #{$root}__link {
            color: $offcanvas-navigation_link-active-color;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        path {
            fill: $offcanvas-navigation_icon-color;
        }
    }

    &__category-icon-wrapper {
        position: relative;
        width: $offcanvas-navigation_category-icon_max-height;
        margin-right: $offcanvas-navigation_category-icon_margin-right;
    }

    &__category-icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        max-height: $offcanvas-navigation_category-icon_max-height;
    }

    @if ($global_header-mobile-search-in-offcanvas) {
        &__search {
            position: relative;
            padding: $offcanvas-navigation_link-padding;
            border-bottom: $offcanvas-navigation_item-border;

            @include media(
                '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
            ) {
                display: none;
            }
        }
    }
}

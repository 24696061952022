/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-offcanvas-tabs {
  display: flex;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  position: absolute;
  left: 0;
  right: 0;
  top: 0; }

.cs-offcanvas-tab {
  width: 50%;
  text-align: center;
  background: #383838;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px; }
  .cs-offcanvas-tab svg {
    fill: #fff;
    margin-right: 12px; }
    .cs-offcanvas-tab svg path {
      stroke: #fff; }
  .cs-offcanvas-tab.active {
    background: #fff;
    color: #383838; }
    .cs-offcanvas-tab.active svg {
      fill: #383838; }
      .cs-offcanvas-tab.active svg path {
        stroke: #383838; }

.cs-offcanvas-tabs-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }
  .cs-offcanvas-tabs-content .cs-stack-nav__trigger {
    display: none !important; }
  .cs-offcanvas-tabs-content .nav.cs-stack-nav__nav {
    display: block !important; }

.cs-offcanvas-content {
  opacity: 0;
  height: 0;
  overflow: hidden; }
  .cs-offcanvas-content.active {
    opacity: 1;
    z-index: 1;
    height: auto; }

@media (max-width: 63.99em) {
  .cs-offcanvas .block-customer-login {
    margin: 0;
    padding: 0 12px; }
    .cs-offcanvas .block-customer-login .fieldset {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-offcanvas .block-customer-login .field .label {
      display: none !important; }
    .cs-offcanvas .block-customer-login .field .input-text {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: rgba(150, 150, 150, 0.8);
      padding: 1.5rem 1.8rem; }
    .cs-offcanvas .block-customer-login .field + .field {
      margin-top: 0.8rem; }
    .cs-offcanvas .block-customer-login .actions-toolbar .action.primary {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      width: 100%;
      border: 1px solid #383838;
      border-radius: 2px;
      display: block;
      text-align: center;
      padding: 0.9rem; }
      .cs-offcanvas .block-customer-login .actions-toolbar .action.primary.login {
        background: #383838;
        color: #fff;
        margin: 1.5rem 0 0; }
      .cs-offcanvas .block-customer-login .actions-toolbar .action.primary.create {
        background: #fff;
        color: #383838;
        margin: 1rem 0 0; }
        .cs-offcanvas .block-customer-login .actions-toolbar .action.primary.create:hover {
          text-decoration: none; }
    .cs-offcanvas .block-customer-login .actions-toolbar .secondary {
      margin-top: 20px; }
    .cs-offcanvas .block-customer-login .actions-toolbar .action.remind {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: #212121; } }

.cs-offcanvas-tabs-close.cs-offcanvas-toggle {
  background: #B4B4B4;
  color: #fff;
  padding: 1.25rem;
  height: auto; }
  .cs-offcanvas-tabs-close.cs-offcanvas-toggle:after {
    display: none; }

.cs-offcanvas-tabs-close-icon {
  background-image: url("../images/menu_close.png");
  background-position: center center;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  margin: 0 8px; }

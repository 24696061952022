@import 'config/variables';
@import 'vendors/include-media';
@import 'components/container/mixin';
@import 'utils/deep-get/deep-get.scss';

$header_font-size: 1rem !default;
$header_border-bottom: 1px solid $color_border-200 !default;

$header_background: $color_background-200 !default;

// Horizontal align of the searchbox if it's displayed as a layer - `$global_header-searchbox-as-layer-breakpoint`
$header_searchbox-justify-content: center !default;

// If you want to cover 'search' item in header by searchbox, provide proper negative margin based on your design
// It works only if '$global_header-searchbox-as-layer-breakpoint' has valid breakpoint and `$header_searchbox-justify-content` = flex-end
$header_searchbox-margin-to-cover-search-item: -6.8rem !default;

// Change in etc/view.xml if necessary
$header_is-sticky-on-mobile: deep-get(
    $view-xml,
    vars,
    Magento_Theme,
    header,
    mobile_is_sticky
) !default;

.#{$ns}header {
    $root: &;

    font-size: $header_font-size;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $color_white;
    z-index: 301;
    transition: top 0.5s, height 0.5s;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.09);

    @if ($header_is-sticky-on-mobile) {
        @if ($global_header-is-bottom-on-mobile) {
            height: 0;
        } @else {
            height: $global_header-height-mobile;
        }

        @include media('>=tablet') {
            height: $global_header-height-tablet;
        }
    }

    @include media('>=laptop') {
        height: $global_header-height-desktop;
        padding: 0 10px;
    }

    @include media('>=laptopLg') {
        padding: 0 6rem;
    }

    .cs-container--top-bar + .cs-container--header & {
        top: 30px;
    }

    .page-wrapper-scroll .cs-container--top-bar + .cs-container--header & {
        top: 0;
    }

    .page-wrapper-scroll & {
        @include media('>=laptop') {
            height: 9.6rem;
        }
    }

    &__content {
        height: $global_header-height-mobile;
        z-index: 300;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        will-change: z-index, height, position;
        background-color: $header_background;
        position: relative;
        max-width: 180rem;
        margin: 0 auto;

        @if ($global_header-is-bottom-on-mobile) {
            @include media('<tablet') {
                bottom: 0;
            }
        } @else {
            top: 0;
        }

        @include media('>=tablet') {
            height: $global_header-height-tablet;
        }

        @include media('>=laptop') {
            flex-wrap: nowrap;
            height: $global_header-height-desktop;
            padding-top: 56px;
            padding-bottom: 15px;
            position: relative;
            transition: padding-top 0.5s;

            .page-wrapper-scroll & {
                padding-top: 15px;
            }
        }

        .page-wrapper-scroll & {
            @include media('>=laptop') {
                height: 9.6rem;
            }
        }
    }

    &__offcanvas-toggle {
        height: 100%;
        flex: 0 1 4rem;
        order: 0;

        &-account {
            @include media('<laptop') {
                order: 2;
            }

            @include media('>=laptop') {
                display: none;
            }
        }
    }

    &__logo {
        display: flex;
        flex: 1 1;
        margin-bottom: 0;
        height: 100%;
        padding: 0.7rem 0;
        align-items: center;

        @include media('<laptop') {
            order: 1;
        }

        @include media('>=laptop') {
            flex: 0 1 auto;
            padding: 0 14px;
            order: 2;
            width: 185px;
            flex-shrink: 0;
            position: relative;

            .page-wrapper-scroll & {
                position: static;
            }
        }

        .page-print & {
            @include container-wrapper();
            position: relative;
            height: auto;
            padding: 2rem 0;

            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }
    }

    &__links-prefix {
        order: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        //width: 18%;
        margin-left: auto;

        @include media('<laptop') {
            display: none !important;
        }

        .page-wrapper-scroll & {
            display: none !important;
        }

        .cs-offcanvas-navigation__item {
            @include media('>=laptopLg') {
                padding: 0;
                margin-right: 20px;
            }

            @include media('>=desktop') {
                margin-right: 40px;
            }

            @include media('>=tv') {
                margin-right: 70px;
            }
        }
    }

    &__links-suffix {
        order: 3;
        display: flex;
        align-items: center;
        justify-content: space-around;
        //margin-right: auto;
        //width: 25%;

        @include media('<laptop') {
            display: none !important;
        }

        .page-wrapper-scroll & {
            display: none !important;
        }

        .cs-offcanvas-navigation__item {
            @include media('>=laptopLg') {
                padding: 0;
                margin-left: 20px;
            }

            @include media('>=desktop') {
                margin-left: 40px;
            }

            @include media('>=tv') {
                margin-left: 70px;
            }
        }
    }

    &__language {
        margin-right: 10px;
        margin-left: auto;

        @include media('<laptop') {
            order: 1;
        }

        @include media('>=laptop') {
            order: 4;
        }

        .page-wrapper-scroll & {
            display: none !important;
        }
    }

    &__b2b-items {
        display: none;

        @include media('>=laptop') {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 25px;
            right: 0;
            width: 193px;

            .page-wrapper-scroll & {
                display: none !important;
            }
        }
    }

    &__b2b-item {
        &--b2b_switcher {
            color: $color_Black3F3F41;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.08em;
            text-transform: uppercase;

            .b2b-common-text {
                margin-right: 8px;
            }

            .switch {
                position: relative;
                display: inline-block;
                width: 30px;
                height: 12px;

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $color_GrayE7;
                    -webkit-transition: .4s;
                    transition: .4s;

                    &:before {
                        position: absolute;
                        content: "";
                        height: 12px;
                        width: 12px;
                        left: 0;
                        bottom: 0;
                        background-color: $color_Black38;
                        -webkit-transition: .4s;
                        transition: .4s;
                    }

                    &.round {
                        border-radius: 34px;

                        &:before {
                            border-radius: 50%;
                        }
                    }
                }

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;

                    &:checked + .slider {
                        background-color: $color_Blue488fe4;

                        &:before {
                            -webkit-transform: translateX(18px);
                            -ms-transform: translateX(18px);
                            transform: translateX(18px);
                        }
                    }
                }
            }
        }

        &--logout {
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.03em;
            color: $color_Black3F3F41;
            padding-right: 20px;
            position: relative;
            white-space: nowrap;

            &:after {
                content: "";
                background-image: url('../images/power.png');
                background-size: cover;
                background-repeat: no-repeat;
                width: 11px;
                height: 10px;
                position: absolute;
                top: 1px;
                right: 0;
                display: block;
            }
        }
    }

    &__search {
        display: flex;
        width: 100%;
        background-color: $header_background;
        padding: 0 1.5rem 1.5rem;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 300;
        top: 100%;

        @include media('<laptop') {
            box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.09);
        }

        @include media('>=laptop') {
            position: absolute;
            padding: 0;
            top: 15px;
            left: auto;
            width: 431px;
            transform: translateX(0);
            //transition:  top 0.5s, transform 0.5s;

            .page-wrapper-scroll & {
                top: 50%;
                transform: translate(-50%, -50%);
                width: 521px;
                left: 50%;
            }

            .cs-header__b2b-items + & {
                right: 218px;
            }

            .page-wrapper-scroll .cs-header__b2b-items + & {
                right: 0;
            }
        }

        @if (
            $global_header-hide-search-item-trigger-breakpoint and
                $header_searchbox-justify-content ==
                'flex-end'
        ) {
            @if ($global_header-hide-search-item-trigger-breakpoint == true) {
                margin: 0;
            } @else {
                margin-right: $header_searchbox-margin-to-cover-search-item;

                @include media(
                    $global_header-hide-search-item-trigger-breakpoint
                ) {
                    margin: 0;
                }
            }
        } @else {
            @if ($header_searchbox-justify-content == 'flex-end') {
                margin-right: $header_searchbox-margin-to-cover-search-item;
            }
        }
    }

    &-search__close {
        display: none;
    }

    &__user-nav {
        display: flex;
        justify-content: flex-end;
    }

    &__back-button {
        border-right: 1px solid $color_border-500;
        height: 100%;
        display: flex;
        padding: 0 1rem;
        align-items: center;
        justify-content: center;
        min-width: 4rem;

        @include media('>=phone') {
            padding: 0 1.5rem;
        }

        @include media('>=tablet') {
            display: none;
        }
    }

    &__back-button-icon {
        fill: $color_border-700;
        height: 2.5rem;

        @include media('<phone') {
            height: 2rem;
        }
    }
}

@if not($header_is-sticky-on-mobile) {
    @include media('<laptop') {
        .cs-container--header {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

form {
    &.amform-form {
        .action {
            &.primary {
                cursor: pointer;
                background: $color_Black38;
                border-color: $color_Black38;
                color: $color_white;
                padding: 0.75em 1em;

                &:hover {
                    background: $color_Black38;
                    border-color: $color_Black38;
                }
            }
        }
    }
}

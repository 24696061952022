@import 'config/variables';
@import 'vendors/include-media';
@import 'components/container/mixin';

$container_background-top-bar: $color_Black38 !default;

.#{$ns}container {
    $root: &;

    @include container();
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;

    @include media('>=laptop') {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .cms-home .column.main & {
        padding-left: 0;
        padding-right: 0;
    }

    .homepage_collections & {
        @include media('>=tablet') {
            overflow: visible;
        }
    }

    &__inner {
        @include container-wrapper();
        position: relative;

        &:before,
        &:after {
            content: '';
            display: table;
            clear: both;
        }

        .cs-container--page-bottom & {
            max-width: 150rem;
        }

        .cs-container--page-category & {
            max-width: 180rem;
        }

        &--expand {
            max-width: 1800px;
            margin: 0 auto;
            padding-left: 6rem;
            padding-right: 6rem;

            @include media('<laptop') {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }

            //&_bg {
            //    max-width: initial;
            //    margin-left: -6rem;
            //    margin-right: -6rem;
            //    padding-left: 6rem;
            //    padding-right: 6rem;
            //
            //    @include media('<laptop') {
            //        margin-left: -1.5rem;
            //        margin-right: -1.5rem;
            //        padding-left: 1.5rem;
            //        padding-right: 1.5rem;
            //    }
            //}

            .cs-container__inner {
                max-width: 100%;
            }
        }

        &--expand_hp_collection_bkg {
            @include media('>=tablet') {
                padding: 0 0 40px;
                position: relative;
                margin-top: 90px;
            }

            &::after {
                @include media('>=tablet') {
                    background: $color_GrayF8;
                    content: "";
                    width: 60%;
                    height: 50%;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                }
            }
        }

        &--expand_hp_recently_viewed_products {
            @include media('>=tablet') {
                padding: 0 0 40px;
                position: relative;
                margin-top: 90px;
            }

            &::after {
                @include media('>=tablet') {
                    background: $color_GrayF8;
                    content: "";
                    width: 100%;
                    height: 50%;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                }
            }
        }

        &--contract {
            @include container-wrapper();
        }

        &--flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include media('>=tablet') {
                flex-wrap: nowrap;
            }

            &:after {
                display: none;
            }
        }

        .cms-home .column.main & {
            max-width: 100%;
        }
    }

    &__aside {
        width: 100%;

        @include media('>=tablet') {
            width: 40%;
        }

        @include media('>=laptop') {
            width: 32%;
        }

        @include media('>=desktop') {
            width: 30%;
        }
    }

    &__main-column {
        width: 56%;
        position: relative;

        @include media('>=laptop') {
            width: 65%;
        }

        @include media('>=desktop') {
            width: 67%;
        }

        @include media('<tablet') {
            max-width: 100% !important;
            width: 100% !important;
            //min-height: 30rem;
        }

        // Bundle products do not have sticky buybox and should have another layout.
        // On tablet gallery covered buybox and as a result it was impossible to click at anything there.
        // Code below should be reviewed during refactoring containers and pdp (PDP 2.0)
        .page-product-bundle & {
            @include media('>=tablet', '<laptop') {
                width: 100%;
                margin-right: 0;
            }
        }

        &--space-top {
            @include media('>=laptop') {
                padding-top: 3rem;
            }
        }
    }

    &__reviews {
        @extend .#{$ns}container__inner;

        @include media('>=tablet') {
            background: $color_white;
            margin-top: 3rem;
            padding: 0 2rem;
        }

        @include media('>=desktop') {
            padding: 2rem 0 0;
        }

        &:after {
            content: '';
            display: table;
            clear: both;
        }
        padding-bottom: 2rem;
    }

    &__cart-coupons {
        display: inline-flex;
        flex-wrap: wrap;

        @include media('>=laptop') {
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        @include media('<phoneLg') {
            width: 100%;
        }
    }

    &--contrast {
        background-color: $color_gallery;

        @include media('>=laptop') {
            background-image: url('../images/background.png');
            backgroud-size: cover;
            background-repeat: no-repeat;
        }
    }

    &--top-bar {
        background-color: $container_background-top-bar;
        max-width: none;
        padding: 0;
        height: 30px !important;
    }

    &--header {
        padding: 0;
        height: 9.3rem !important;

        @include media('>=tablet') {
            height: 13.5rem !important;
        }
    }

    &--spacious {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media('>=laptop') {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        .catalog-category-view &,
        .catalogsearch-result-index & {
            @include media('>=tablet') {
                padding-top: 0;
            }
        }
    }

    &--image-teaser-window-width,
    &--store-locator {
        padding-left: 0;
        padding-right: 0;
    }

    &--grid-teaser {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    &--hero-teaser {
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
    }

    &--noscroll {
        overflow: hidden;
    }

    &--pdp {
        @include media('>=laptop') {
            padding-top: 0;
        }
    }

    &--usps {
        #{$root}--headline {
            padding: 0;
        }
    }

    &--create-account-page,
    &--login-page,
    &--success-page,
    &--logout-page {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media('>=laptop') {
            padding-top: 3rem;
            padding-bottom: 5rem;
        }
    }

    &--my-account-page {
        @include media('>=tablet', '<laptop') {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    &--ontop {
        z-index: 301;
    }

    &--viewport-width {
        max-width: none;
    }

    &--page-pdp-details-aw_pq_ajax_container {
        @include media('<tablet') {
            padding: 0;
        }
    }

    &--cart-page {
        #{$root}--products-carousel {
            padding-left: 0;
            padding-right: 0;
        }

        #{$root}--headline {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--cms-teaser {
        margin-top: 2rem;
    }

    @supports (display: grid) and (grid-auto-flow: row) {
        &--grid-teaser {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            overflow: visible;
        }
    }

    &--footer-usps {
        .#{$ns}image-teaser__content {
            margin: 0 !important;
        }
    }
}

.cms-page-view,
.cms-no-route {
    #maincontent {
        padding: 0 1.5rem;
        width: 100%;
        max-width: 172rem;
        margin: 0 auto;

        @include media('>=laptop') {
            padding: 0 6rem;
        }
    }
}

@import 'config/colors';
@import 'config/variables';
@import 'vendors/include-media';
@import 'components/typography/mixin';

$topbar_background: $color_Black38 !default;
$topbar_height: 30px !default;

$topbar_item-font-size: 12px !default;
$topbar_item-font-size-mobile: 12px !default;
$topbar_item-text-color: $color_white !default;
$topbar_item-hours-color: $color_black !default;
$topbar_item-border: 0.2rem solid $color_white !default;
$topbar_item-border-transform: $transform_skew-400 !default;
$topbar_item-phone_number-color: $color_black !default;

$topbar_link-color-action: $color_primary-800 !default;
$topbar_link-color: $color_primary-500 !default;
$topbar_link-decoration: none !default;
$topbar_link-hover-decoration: $topbar_link-decoration !default;

// Change in etc/view.xml if necessary
$header_is-sticky-on-mobile: deep-get(
        $view-xml,
        vars,
        Magento_Theme,
        header,
        mobile_is_sticky
) !default;

.#{$ns}topbar {
    $root: &;

    background: $topbar_background;
    //display: none;
    width: 100%;
    max-width: 100%;
    font-size: $topbar_item-font-size-mobile;

    //@include media('>=laptop') {
    //    display: flex;
    //    z-index: 302;
    //    font-size: $topbar_item-font-size;
    //}

    //@if ($header_is-sticky-on-mobile) {
    //    @include media('<laptop') {
    //        position: fixed;
    //        left: 0;
    //        right: 0;
    //        top: 0;
    //        z-index: 300 !important;
    //        height: $topbar_height;
    //    }
    //}

    &--hidden {
        transform: translateY(-105%);
    }

    .page-print & {
        display: none;
    }

    &__wrapper {
        align-items: center;
        height: $topbar_height;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: $color_Black38;
        z-index: 301;
        transition: top 0.5s;

        .page-wrapper-scroll & {
            top: -30px;
        }
    }

    &-carousel__carousel {
        padding: 0 80px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
    }

    &__item {
        color: $topbar_item-text-color;
        height: 100%;

        &--usps {
            margin-right: auto;
            margin-left: auto;

            //@include media('<laptopLg') {
            //    display: none !important;
            //}

            .page-wrapper--checkout & {
                display: none !important;
            }

            //@include media('<laptop') {
                display: block;
            //}
        }

        &--hotline {
            align-items: center;

            @include media('<laptopLg') {
                flex: 1;
                justify-content: flex-end;
            }
        }

        &--language {
            height: 100%;
            display: none !important;
        }
    }

    &__list,
    &__item > ul {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__list-item {
        margin-bottom: 0;
        height: $topbar_height;
        display: flex;
        align-items: center;
        position: relative;
        flex-shrink: 0;

        @include media('>=laptop') {
            height: $topbar_height !important;
        }

        @include media('<laptop') {
            justify-content: center;
            padding: 0;
            margin: 0;
            opacity: 0;
        }

        &:last-child {
            margin-right: 0;
            padding-right: 0;
        }

        &.swiper-slide-active {
            @include media('<laptop') {
                opacity: 1;
            }
        }

        p {
            margin: 0;
            display: block;
            width: 100%;
            white-space: normal;
            text-align: center;
            line-height: normal;
        }
    }

    &__text {
        color: $topbar_item-text-color;
    }

    &__link {
        @include link(
            $color: $topbar_link-color,
            $color-hover: $topbar_link-color-action,
            $decoration: $topbar_link-decoration,
            $decoration-hover: $topbar_link-hover-decoration
        );

        margin-right: 0.9rem;

        &:hover {
            color: $topbar_item-hours-color;
        }

        @include media('<laptop') {
            display: none;
        }
    }

    &__hotline {
        display: flex;
        align-items: center;
    }

    &__phone-number {
        color: $topbar_item-hours-color;
        font-weight: 700;
        margin-right: 0.9rem;

        &:hover {
            color: $topbar_item-hours-color;
        }
    }

    &-carousel,
    &-carousel__wrapper {
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
        height: $topbar_height;
    }

    &-carousel {
        @include media('<laptop') {
            padding: 0 65px;
        }
    }

    &-button {
        &-next,
        &-prev {
            position: absolute;
            right: 50px;
            top: 9px;
            cursor: pointer;

            img {
                display: block;
            }
        }

        &-prev {
            right: auto;
            left: 50px;
        }

        &-close {
            position: absolute;
            right: 12px;
            top: 9px;
            cursor: pointer;

            @include media('>=laptop') {
                right: 36px;
            }

            img {
                display: block;
            }
        }
    }
}

.cs-header__b2b-item{
    pointer-events: none;
    opacity: 0.5;
}

@import 'config/variables';
@import 'vendors/include-media';

.#{$ns}logo {
    width: 70px;
    height: 29px;

    @include media('>=tablet') {
        width: 157px;
        height: 66px;
    }

    @include media('>=laptop') {
        position: absolute;
        left: 14px;
        //transition: left 0.5s;
        //margin-left: -125px;

        .page-wrapper-scroll & {
            left: 41px;
        }
    }

    //@include media('>=laptopLg') {
    //    margin-left: -132px;
    //}
    //
    //@include media('>=desktop') {
    //    margin-left: -135px;
    //}

    &__image {
        height: 100%; // Fixes height: auto bug on chrome.

        &--desktop {
            display: none;

            .checkout-index-index & {
                display: block;
            }

            @include media('>=tablet') {
                display: block;
            }
        }

        &--mobile {
            display: block;

            @include media('>=tablet') {
                display: none;
            }

            .checkout-index-index & {
                display: none;
            }
        }
    }
}

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-header-user-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  order: 5; }
  @media (max-width: 63.99em) {
    .cs-header-user-nav {
      order: 3; } }
  .cs-header-user-nav__item {
    margin-bottom: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
    .cs-header-user-nav__item--search {
      display: none !important; }
      @media (min-width: 64em) {
        .cs-header-user-nav__item--search {
          display: none; } }
    @media (max-width: 63.99em) {
      .cs-header-user-nav__item--account {
        display: none !important; } }
  .cs-header-user-nav__link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 1rem; }
    .cs-header-user-nav__link:hover {
      text-decoration: none; }
      .cs-header-user-nav__link:hover .cs-header-user-nav__icon path {
        fill: #3F3F41; }
      .cs-header-user-nav__link:hover .cs-header-user-nav__label {
        color: #696969; }
    .cs-header-user-nav__link--search:hover {
      cursor: pointer; }
  .cs-header-user-nav__icon-wrapper {
    position: relative; }
  .cs-header-user-nav__icon {
    width: 1.7rem;
    height: 1.9rem;
    display: block;
    margin: 0 auto; }
    @media (min-width: 64em) {
      .cs-header-user-nav__icon {
        width: 23px; } }
    .cs-header-user-nav__icon path {
      fill: #3F3F41; }
    .cs-header-user-nav__icon--wishlist {
      width: 1.9rem;
      height: 1.7rem; }
  .cs-header-user-nav__label {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    margin-top: 0.3rem;
    color: #696969;
    text-decoration: none;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
  .cs-header-user-nav__qty-counter {
    display: inline-flex;
    background-color: #383838;
    color: #fff;
    transform: skew(-15deg);
    border: 0;
    border-radius: 0.2rem;
    padding: 0 0.6rem;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 100%;
    font-weight: 700;
    height: 1.8em;
    background-clip: padding-box;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    opacity: 0;
    transition: opacity 0.3s; }
    @media (min-width: 48em) {
      .cs-header-user-nav__qty-counter {
        right: -1rem; } }
    .cs-header-user-nav__qty-counter--wishlist {
      background-color: #fed400;
      color: #000; }
    .cs-header-user-nav__qty-counter--minicart {
      background-color: #e41f12;
      color: #fff; }
    .cs-header-user-nav__qty-counter--have-items {
      opacity: 1; }
  .cs-header-user-nav__qty-counter-span {
    transform: skew(15deg);
    min-width: 1ch; }

.cs-offcanvas-toggle {
  position: relative;
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  align-self: stretch;
  height: 100%;
  width: 100%; }
  @media (min-width: 64em) {
    .cs-offcanvas-toggle {
      padding: 0 35px 0 0; } }
  .cs-offcanvas-toggle, .cs-offcanvas-toggle:active, .cs-offcanvas-toggle:focus, .cs-offcanvas-toggle:hover {
    border: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0; }
  .cs-offcanvas-toggle:after {
    content: ""; }
    @media (max-width: 63.99em) {
      .cs-offcanvas-toggle:after {
        width: 23px;
        height: 16px;
        background-image: url("../images/menu.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        top: 50%;
        left: 50%; } }
    @media (min-width: 64em) {
      .cs-offcanvas-toggle:after {
        position: absolute;
        height: 2px;
        width: 100%;
        transform: scale(0);
        background: transparent;
        transition: transform 0.3s;
        bottom: 0;
        left: 0;
        transform-origin: left; } }
  .cs-offcanvas-toggle--active, .cs-offcanvas-toggle--active:active, .cs-offcanvas-toggle--active:focus, .cs-offcanvas-toggle--active:hover {
    background: transparent; }
  .cs-offcanvas-toggle--active:after {
    transform: scale(1); }
  .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon {
    transform: rotate(-45deg);
    width: 2.6rem; }
    .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon, .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon:before, .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon:after {
      background: #3F3F41; }
    .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon:before, .cs-offcanvas-toggle--active .cs-offcanvas-toggle__icon:after {
      transform: rotate(90deg); }
  @media (max-width: 63.99em) {
    .cs-offcanvas-toggle-desktop {
      display: none !important; } }
  @media (min-width: 64em) {
    .cs-offcanvas-toggle-mobile {
      display: none !important; } }
  .cs-offcanvas-toggle__icon {
    padding: 0;
    margin: 0;
    position: relative; }
    .cs-offcanvas-toggle__icon, .cs-offcanvas-toggle__icon:before, .cs-offcanvas-toggle__icon:after {
      width: 1.8rem;
      height: 0.2rem;
      background: #3F3F41;
      transition: background-color 0.3s, transform 0.3s; }
      @media (min-width: 64em) {
        .page-wrapper-scroll .cs-offcanvas-toggle__icon, .page-wrapper-scroll .cs-offcanvas-toggle__icon:before, .page-wrapper-scroll .cs-offcanvas-toggle__icon:after {
          width: 2.5rem; } }
    @media (min-width: 64em) {
      .page-wrapper-scroll .cs-offcanvas-toggle__icon {
        width: 1.9rem; } }
    .cs-offcanvas-toggle__icon:before, .cs-offcanvas-toggle__icon:after {
      content: '';
      display: block;
      position: absolute; }
    .cs-offcanvas-toggle__icon:before {
      transform: translateY(-250%);
      transform: translate3d(0, -250%, 0); }
      @media (min-width: 64em) {
        .page-wrapper-scroll .cs-offcanvas-toggle__icon:before {
          transform: translateY(-300%);
          transform: translate3d(0, -300%, 0); } }
    .cs-offcanvas-toggle__icon:after {
      transform: translateY(250%);
      transform: translate3d(0, 250%, 0); }
      @media (min-width: 64em) {
        .page-wrapper-scroll .cs-offcanvas-toggle__icon:after {
          transform: translateY(300%);
          transform: translate3d(0, 300%, 0); } }
    @media (max-width: 63.99em) {
      .cs-offcanvas-toggle__icon {
        display: none !important; } }
  .cs-offcanvas-toggle__text {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    color: #3F3F41;
    transition: color 0.3s;
    order: 1;
    margin-left: 18px; }
    .cs-offcanvas-toggle--active .cs-offcanvas-toggle__text {
      color: #3F3F41; }
    @media (min-width: 48em) {
      .cs-offcanvas-toggle__text {
        bottom: 2rem; } }
    @media (max-width: 63.99em) {
      .cs-offcanvas-toggle__text {
        display: none !important; } }
    .page-wrapper-scroll .cs-offcanvas-toggle__text {
      display: none !important; }
  .cs-offcanvas-toggle-account .cs-offcanvas-toggle__icon {
    display: none !important; }
  @media (max-width: 47.99em) {
    .cs-offcanvas-toggle-account {
      padding: 1.5rem 1rem; } }
  .cs-offcanvas-toggle-account:after {
    content: "";
    width: 17px;
    height: 18px;
    background-image: url("../images/account.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    top: 50%;
    left: 50%; }

.cs-offcanvas-toggle-desktop {
  position: relative;
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  align-self: stretch;
  height: 100%;
  width: 100%;
  cursor: pointer; }
  @media (min-width: 64em) {
    .cs-offcanvas-toggle-desktop {
      padding: 0 15px 0 0; } }
  .cs-offcanvas-toggle-desktop, .cs-offcanvas-toggle-desktop:active, .cs-offcanvas-toggle-desktop:focus, .cs-offcanvas-toggle-desktop:hover {
    border: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0; }
  .cs-offcanvas-toggle-desktop:after {
    content: ""; }
    @media (max-width: 63.99em) {
      .cs-offcanvas-toggle-desktop:after {
        width: 23px;
        height: 16px;
        background-image: url("../images/menu.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        top: 50%;
        left: 50%; } }
    @media (min-width: 64em) {
      .cs-offcanvas-toggle-desktop:after {
        position: absolute;
        height: 2px;
        width: 100%;
        transform: scale(0);
        background: transparent;
        transition: transform 0.3s;
        bottom: 0;
        left: 0;
        transform-origin: left; } }
  .cs-offcanvas-toggle-desktop--active, .cs-offcanvas-toggle-desktop--active:active, .cs-offcanvas-toggle-desktop--active:focus, .cs-offcanvas-toggle-desktop--active:hover {
    background: transparent; }
  .cs-offcanvas-toggle-desktop--active:after {
    transform: scale(1); }
  .cs-offcanvas-toggle-desktop--active .cs-offcanvas-toggle-desktop__icon {
    transform: rotate(-45deg);
    width: 2.6rem; }
    .cs-offcanvas-toggle-desktop--active .cs-offcanvas-toggle-desktop__icon, .cs-offcanvas-toggle-desktop--active .cs-offcanvas-toggle-desktop__icon:before, .cs-offcanvas-toggle-desktop--active .cs-offcanvas-toggle-desktop__icon:after {
      background: #3F3F41; }
    .cs-offcanvas-toggle-desktop--active .cs-offcanvas-toggle-desktop__icon:before, .cs-offcanvas-toggle-desktop--active .cs-offcanvas-toggle-desktop__icon:after {
      transform: rotate(90deg); }
  .cs-offcanvas-toggle-desktop__icon {
    padding: 0;
    margin: 0;
    position: relative; }
    .cs-offcanvas-toggle-desktop__icon, .cs-offcanvas-toggle-desktop__icon:before, .cs-offcanvas-toggle-desktop__icon:after {
      width: 1.8rem;
      height: 0.2rem;
      background: #3F3F41;
      transition: background-color 0.3s, transform 0.3s; }
    .cs-offcanvas-toggle-desktop__icon:before, .cs-offcanvas-toggle-desktop__icon:after {
      content: '';
      display: block;
      position: absolute; }
    .cs-offcanvas-toggle-desktop__icon:before {
      transform: translateY(-250%);
      transform: translate3d(0, -250%, 0); }
    .cs-offcanvas-toggle-desktop__icon:after {
      transform: translateY(250%);
      transform: translate3d(0, 250%, 0); }
    @media (max-width: 63.99em) {
      .cs-offcanvas-toggle-desktop__icon {
        display: none !important; } }
  .cs-offcanvas-toggle-desktop__text {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    color: #3F3F41;
    transition: color 0.3s;
    order: 1;
    margin-left: 18px; }
    .cs-offcanvas-toggle-desktop--active .cs-offcanvas-toggle-desktop__text {
      color: #3F3F41; }
    @media (min-width: 48em) {
      .cs-offcanvas-toggle-desktop__text {
        bottom: 2rem; } }
    @media (max-width: 63.99em) {
      .cs-offcanvas-toggle-desktop__text {
        display: none !important; } }
    .page-wrapper-scroll .cs-offcanvas-toggle-desktop__text {
      display: none !important; }
  .cs-offcanvas-toggle-desktop-account .cs-offcanvas-toggle__icon {
    display: none !important; }
  @media (max-width: 47.99em) {
    .cs-offcanvas-toggle-desktop-account {
      padding: 1.5rem 1rem; } }
  .cs-offcanvas-toggle-desktop-account:after {
    content: "";
    width: 17px;
    height: 18px;
    background-image: url("../images/account.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    top: 50%;
    left: 50%; }

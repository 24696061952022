/**
 * Footer component customization.
 * @see /Magento_Theme/templates/footer/columns.phtml for templates.
 */
@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';
@import 'components/pseudoicon/mixin';
@import 'components/typography/mixin';

$footer_text-color: $color_Black38 !default;
$footer_background: $color_white !default;
$footer_edge-gutter: $page_edge-gutter !default;

$footer_section-edge-gutter: $page_edge-gutter !default;
$footer_section-vertical-offset: 2em !default;

$footer_section-separator-border: 1px solid $color_gray !default;

$footer_section-title-headline-level: $font-size_headline-4 !default;
$footer_section-title-padding: $footer_section-vertical-offset
    $footer_section-edge-gutter !default;
$footer_section-title-color: $color_gray !default;
$footer_section-title-font-size: 1.2em !default;
$footer_section-title-transform: uppercase !default;

$footer_section-pseudoicon-include: true !default;

$footer_promo-display-on-mobile: false !default;
$footer_logo-display: block !default;
$footer_logo-width: 13.5em !default;
$footer_logo-height: 2.3em !default;

.#{$ns}footer {
    $root: &;
    font-size: 1.2rem;
    color: $footer_text-color;
    background: $color_GrayF8;
    overflow: hidden;

    @include media('>=laptop') {
        background: $footer_background;
        font-size: 1.6rem;
    }

    &__top {
        background: $color_GreenD4;
        margin: 0 -1.5rem;
        padding: 0 37px 34px;

        @include media('>=tablet') {
            padding: 0;
        }

        @include media('>=laptop') {
            margin: 0 -6rem;
        }
    }

    &__top-desktop {
        @include media('<tablet') {
            display: none !important;
        }
    }

    &__top-mobile {
        @include media('>=tablet') {
            display: none !important;
        }
    }

    &__top-cols {
        @include media('>=tablet') {
            display: flex;
            max-width: 1920px;
            margin: 0 auto;
        }

        &:after {
            content: "";
            clear: both;
            display: block;
        }
    }

    &__top-col {
        @include media('<tablet') {
            padding: 0 9px;
            width: calc(50% + 9px);
            float: left;
            margin-left: -9px;
        }

        @include media('>=tablet') {
            width: 33.3333%;
            max-width: 33.3333%;
            padding: 25px 2.5%;
            display: flex;
            order: 0;
        }

        @include media('>=laptop') {
            padding: 45px 4.5%;
        }

        @include media('>=laptopLg') {
            padding: 74px 4.5%;
        }

        &:last-child {
            @include media('<tablet') {
                margin-left: 0;
                margin-right: -9px;
            }

            @include media('>=tablet') {
                order: 2;
            }
        }
    }

    &__top-col-bkg {
        background-image: url('../images/footer_quote_bkg.png');
        background-repeat: no-repeat;
        background-size: cover;

        @include media('<tablet') {
            padding: 22px 37px;
            margin: 0 -37px -6px;
            width: calc(100% + 74px);
        }

        @include media('>=tablet') {
            order: 1;
        }
    }

    &__top-block {
        background: $color_white;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);

        .cs-footer__top-col-bkg & {
            @include media('<tablet') {
                box-shadow: none;
            }
        }

        @include media('>=tablet') {
            padding: 20px 12.3%;
            box-shadow: none;
        }

        @include media('>=laptop') {
            padding: 30px 12.3%;
        }

        @include media('>=laptopLg') {
            padding: 40px 12.3%;
        }
    }

    &__top-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 85px;

        @include media('<tablet') {
            text-align: center;

            .cs-footer__top-col-bkg & {
                min-height: 142px;
            }
        }

        @include media('>=tablet') {
            min-height: 246px;
            justify-content: flex-end;
        }

        h2 {
            font-size: 12px;
            line-height: 14px;
            font-weight: 600;
            letter-spacing: 0.01em;
            margin: 0;
            text-transform: none;

            .cs-footer__top-col-bkg & {
                @include media('<tablet') {
                    font-size: 14px;
                    line-height: 17px;
                }
            }

            @include media('>=tablet') {
                font-size: 18px;
                line-height: 22px;
            }
        }

        p {
            color: $color_black;
            font-size: 10px;
            line-height: 12px;
            opacity: 0.18;
            margin: 7px auto 0;

            @include media('<tablet') {
                max-width: 110px;
            }

            @include media('>=tablet') {
                font-size: 16px;
                line-height: 19px;
                margin: 28px 0 37px;
            }

            .cs-footer__top-col-bkg & {
                @include media('<tablet') {
                    font-size: 12px;
                    line-height: 14px;
                    max-width: 100%;
                }
            }
        }

        a.cs-footer__top-link {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.03em;
            color: $color_GrayD1;
            width: 100%;
            max-width: 126px;
            padding: 10px 5px;
            border: 1px solid $color_GrayCB;
            border-radius: 2px;
            text-align: center;
            text-decoration: none;
            margin-top: 9px;

            @include media('>=tablet') {
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.135em;
                padding: 18px;
                max-width: 232px;
                margin-top: 0;
            }

            &:hover {
                background: $color_Black38;
                color: $color_GrayD1;
                text-decoration: none;
            }
        }
    }

    &__content {
        padding: 0;
        max-width: 150rem;

        @include media('>=tablet') {
            display: flex;
            flex-direction: column;
        }
    }

    &__item {
        &--links {
            @include media('>=tablet') {
                margin-top: 50px;
            }

            @include media('>=laptop') {
                display: flex;
                margin-top: 125px;
                margin-left: -25px;
                margin-right: -25px;
                flex-basis: auto;
                flex-grow: 5;
            }

            .cs-footer__section {
                @include media('>=laptop') {
                    padding: 0 25px;
                }
            }
        }

        &--extras {
            @include media('>=laptop') {
                display: block;
                margin: 0 auto;
            }
        }
    }

    &__section {
        max-height: 100%;
        transition: max-height 0.3s;
        overflow: hidden;

        @include media('>=tablet') {
            flex: 1;
        }

        &[aria-expanded=false] {
            @include media('<laptop') {
                max-height: 54px;
            }
        }

        &[data-collapsible=true],
        &[role=tab] {
            @include media('<laptop') {
                border-bottom: 1px solid $color_GrayD7;
            }
        }

        &--socials {
            @include media('>=laptop') {
                margin-top: 15.5rem;
            }

            .cs-footer__section-title {
                display: none !important;
            }
        }

        &--stores-switcher {
            @include media('>=laptop') {
                margin-top: 4rem;
                max-width: 20rem;
            }
        }

        &--newsletter {
            @include media('>=tablet') {
                display: flex;
                align-items: flex-end;
                margin-top: 60px;
                max-width: 1120px;
                margin-left: auto;
                margin-right: auto;
            }

            @include media('<tablet') {
                margin: 0 -1.5rem;
            }

            .cs-footer__section-title {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                padding: 0 0 5px;
                color: $color_Black38;
                flex-shrink: 0;
                padding-right: 70px;

                @include media('<tablet') {
                    display: none !important;
                }
            }
        }
    }

    &__section-title,
    [data-role='title'] {
        @if $footer_section-title-headline-level != 'none' {
            @include headline($level: $footer_section-title-headline-level);
        }
        position: relative;
        margin: 0;
        border-top: $footer_section-separator-border;
        padding: $footer_section-title-padding;
        color: $footer_section-title-color;
        font-size: $footer_section-title-font-size;
        font-weight: 400;
        text-transform: $footer_section-title-transform;
        letter-spacing: 0.08rem;

        @include media('<tablet') {
            #{$root}__section[data-mage-init] &,
            #{$root}__section[data-collapsible] & {
                cursor: pointer;

                @if $footer_section-pseudoicon-include {
                    @include pseudoicon();
                }
            }

            #{$root}__section--socials & {
                @include visually-hidden();
            }
        }

        @include media('>=tablet') {
            border-top: none;
            padding-left: 0;
        }

        @include media('>=laptop') {
            padding-top: 0;
            padding-right: 0;
        }
    }

    &__section-content,
    [data-role='content'] {
        padding: 0 $footer_section-edge-gutter $footer_section-edge-gutter;
        font-size: 1.2em;

        @include media('<tablet') {
            #{$root}__section[data-mage-init] &,
            #{$root}__section[data-collapsible] & {
                display: none;
            }
        }

        @include media('>=tablet') {
            padding-left: 0;
        }

        @include media('>=laptop') {
            padding-right: 0;
            padding-bottom: 0;
        }
    }

    &__shop-logo {
        display: $footer_logo-display;
        width: $footer_logo-width;
        height: $footer_logo-height;
        min-width: $footer_logo-width;
        max-width: 100%;
        max-height: 100%;
    }

    &-scrolltotop {
        cursor: pointer;
        position: fixed;
        bottom: 30px;
        right: 15px;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        padding: 10px 12px;
        background: rgba(56, 56, 56, 0.88);
        z-index: 10;
        display: none;

        @include media('>=tablet') {
            width: 49px;
            height: 49px;
            padding: 14px 15px;
            right: 30px;
        }

        @include media('>=laptop') {
            bottom: 100px;
            right: 75px;
        }

        @include media('>=laptopLg') {
            right: 30px;
        }

        svg {
            fill: white;
            transform: rotate(-90deg);
        }
    }
}

.cs-container {
    &--footer {
        max-width: none;
        margin-top: 15px;

        @include media('>=tablet') {
            margin-top: 40px;
        }
    }
}

::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: $color_transparent;
}

::-webkit-scrollbar {
    width: 3px;
    background-color: $color_transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $color_GrayD2D2D2;
}

@include media('>=tablet') {
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: $color_transparent;
    }

    ::-webkit-scrollbar {
        width: 10px;
        background-color: $color_transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: $color_GrayD2D2D2;
    }
}

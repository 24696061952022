/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/*
 * Because price-box is used in multiple components like tile, buybox etc,
 * we need to move variables to separate file to have possibility to include them
 * in each component without generate price-box component styles multiple times
*/
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/***
 * Using psuedo elements to maintain an elements aspect ratio, even as it scales
 * It's using only for IE11 and Edge (version which doesn't support native CSS grid) browsers
 * For browsers which support native CSS grid, we are using mixins contain `reset` in mixin name
 * to override default mixins (progressive enhancement)
 * https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
 * @param {number} $width: width of the element;
 * @param {number} $height: height of the element;
 * @param {string} $contentClass: class of the direct child element to be positioned absolutely;
 * @param {boolean} $autoposition: tells if item shall take 100% of parent size or if it can be wider/higher if needed;
 */
/**
 * String to number converter
 * https://www.sassmeister.com/gist/9fa19d254864f33d4a80
 * @author Hugo Giraudel
 * @param {String | Number} $value - Value to be parsed
 * @return {Number}
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
.cs-product-tile {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 2px;
  background-color: #fff; }
  @media (min-width: 64em) {
    .cs-product-tile:hover {
      z-index: 5;
      transition: z-index 0.0001s; } }
  .cs-product-tile--clickable:hover {
    cursor: pointer; }
  .cs-product-tile--hidden .cs-product-tile__main,
  .cs-product-tile--hidden .cs-product-tile__addons {
    visibility: hidden !important; }
  .cs-product-tile--grid.cs-product-tile--static .cs-product-tile__addons {
    display: none; }
  .cs-product-tile__container {
    display: flex;
    height: 100%;
    width: 100%; }
    .cs-product-tile--grid .cs-product-tile__container {
      flex-direction: column;
      padding: 1.8rem 0.5rem; }
      @media (min-width: 48em) {
        .cs-product-tile--grid .cs-product-tile__container {
          padding: 1.8rem; } }
    .cs-product-tile--list .cs-product-tile__container {
      flex-direction: row;
      padding: 1.8rem 0; }
  .cs-product-tile--list .cs-product-tile__link {
    display: flex;
    width: 100%; }
  .cs-product-tile--grid .cs-product-tile__link {
    display: block;
    height: 100%; }
  .cs-product-tile__thumbnail {
    padding: 0;
    display: flex; }
  .cs-product-tile__thumbnail-link {
    display: block;
    position: relative;
    overflow: hidden; }
    .cs-product-tile--grid .cs-product-tile__thumbnail-link {
      width: 100%; }
    .cs-product-tile--list .cs-product-tile__thumbnail-link {
      padding: 0 1.8rem 0 0;
      height: 100%;
      flex-grow: initial; }
      @media (min-width: 48em) {
        .cs-product-tile--list .cs-product-tile__thumbnail-link {
          padding: 0; } }
      .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__thumbnail-link {
        padding: 0 1.8rem 0 0; }
        @media (min-width: 64em) {
          .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__thumbnail-link {
            padding: 0; } }
    .cs-product-tile__thumbnail-link:hover, .cs-product-tile__thumbnail-link:visited {
      color: inherit;
      text-decoration: inherit; }
  .cs-product-tile__figure {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden; }
    .cs-product-tile--grid .cs-product-tile__figure {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      top: 0;
      position: relative; }
      .cs-product-tile--grid .cs-product-tile__figure:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 125.71429%; }
      .cs-product-tile--grid .cs-product-tile__figure .cs-product-tile__thumbnail-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      @media (min-width: 1674px) {
        .cs-product-tile--grid .cs-product-tile__figure:before {
          padding-top: 107%; } }
    .cs-product-tile--list .cs-product-tile__figure {
      width: 10rem;
      justify-content: center;
      align-items: flex-start; }
      @media (max-width: 29.99em) {
        .cs-product-tile--list .cs-product-tile__figure {
          width: 5rem; } }
      .cs-product-tile--list .cs-product-tile__figure:before {
        content: none; }
  .cs-product-tile__image {
    width: auto;
    height: auto;
    max-width: 100%;
    margin: auto; }
    .cs-product-tile--grid .cs-product-tile__image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center; }
    .cs-product-tile--list .cs-product-tile__image {
      max-height: 100%;
      position: relative !important;
      margin-top: 0; }
    .cs-product-tile__image .product.top_left,
    .cs-product-tile__image .category.top_left {
      position: absolute; }
  .cs-product-tile__img {
    max-width: 100%;
    height: auto;
    width: auto;
    max-height: 100%; }
  .cs-product-tile__badges {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap; }
    .cs-product-tile--list .cs-product-tile__badges--grid {
      display: none; }
    .cs-product-tile--grid .cs-product-tile__badges--list {
      display: none; }
    .cs-product-tile--grid .cs-product-tile__badges {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0.3rem;
      flex-direction: column; }
    .cs-product-tile--list .cs-product-tile__badges {
      margin: 0 0 0 0.3rem;
      justify-content: flex-start; }
  .cs-product-tile__badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: skew(-15deg);
    background-color: #383838;
    color: #fff;
    border: 0;
    border-radius: 2px;
    padding: 0 0.7rem 0 0.3rem;
    font-size: 1.1rem;
    font-weight: 700;
    min-height: 2em;
    text-transform: uppercase; }
    .cs-product-tile__badge:before {
      content: '';
      width: 1rem;
      position: absolute;
      min-height: inherit;
      height: 100%;
      top: 0;
      left: 0;
      background-color: inherit;
      transform: skew(15deg);
      border-radius: 2px 0 0 2px;
      border: inherit;
      border-right: 0;
      transform-origin: bottom left; }

@media (min-width: 23.125em) and (max-width: 29.99em) {
  .cs-product-tile__badge {
    font-size: 0.9rem; } }
    .cs-product-tile__badge--discount {
      background-color: #e41f12;
      color: #fff; }
      .cs-product-tile__badge--discount .cs-product-tile__badge-text {
        line-height: normal;
        transform: skew(15deg); }
        .cs-product-tile__badge--discount .cs-product-tile__badge-text + .cs-product-tile__badge--discount .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
    .cs-product-tile__badge--new {
      background-color: #fed400;
      color: #000; }
      .cs-product-tile__badge--new .cs-product-tile__badge-text {
        line-height: normal;
        transform: skew(15deg); }
        .cs-product-tile__badge--new .cs-product-tile__badge-text + .cs-product-tile__badge--new .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
    .cs-product-tile__badge--free-shipping {
      background-color: #37b841;
      color: #fff; }
      .cs-product-tile__badge--free-shipping .cs-product-tile__badge-text {
        line-height: normal;
        transform: skew(15deg); }
        .cs-product-tile__badge--free-shipping .cs-product-tile__badge-text + .cs-product-tile__badge--free-shipping .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
    .cs-product-tile__badge--popular {
      background-color: #37b841;
      color: #fff; }
      .cs-product-tile__badge--popular .cs-product-tile__badge-text {
        line-height: normal;
        transform: skew(15deg); }
        .cs-product-tile__badge--popular .cs-product-tile__badge-text + .cs-product-tile__badge--popular .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
    .cs-product-tile__badge--dailydeal {
      background-color: #e41f12;
      color: #fff; }
      .cs-product-tile__badge--dailydeal .cs-product-tile__badge-text {
        line-height: normal;
        transform: skew(15deg); }
        .cs-product-tile__badge--dailydeal .cs-product-tile__badge-text + .cs-product-tile__badge--dailydeal .cs-product-tile__badge-text {
          margin-left: 0.5rem; }
  .cs-product-tile__badge-item {
    display: flex; }
    .cs-product-tile--grid .cs-product-tile__badge-item {
      margin: 0 0 0.5rem;
      width: 100%; }
    .cs-product-tile--list .cs-product-tile__badge-item {
      margin: 0 1rem 0.3rem 0; }
    .cs-product-tile__badge-item:empty {
      display: none; }
  .cs-product-tile__badge-text {
    line-height: normal;
    transform: skew(15deg); }
    .cs-product-tile__badge-text + .cs-product-tile__badge-text {
      margin-left: 0.5rem; }
  .cs-product-tile--list .cs-product-tile__gallery {
    display: none !important; }
  .cs-product-tile__content {
    padding: 0;
    display: flex;
    align-items: stretch; }
    .cs-product-tile--grid .cs-product-tile__content {
      flex-direction: column;
      flex: 1; }
    .cs-product-tile--list .cs-product-tile__content {
      position: relative;
      flex: 1 1 auto;
      flex-direction: column-reverse; }
      @media (min-width: 48em) {
        .cs-product-tile--list .cs-product-tile__content {
          flex-direction: row-reverse; } }
      .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__content {
        flex-direction: column-reverse; }
        @media (min-width: 64em) {
          .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__content {
            flex-direction: row-reverse; } }
  .cs-product-tile__addons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff; }
    .cs-product-tile--list .cs-product-tile__addons {
      padding: 0;
      margin: 1rem 0 0;
      position: relative;
      z-index: 1;
      opacity: initial;
      box-shadow: none;
      transform: none;
      overflow: visible; }
      @media (min-width: 48em) {
        .cs-product-tile--list .cs-product-tile__addons {
          flex: 0 0 25rem;
          padding: 0 0 0 1.8rem;
          margin: 0;
          flex-direction: column;
          border-left: 1px solid #f0f0f0; } }
      .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__addons {
        padding: 0;
        margin: 1rem 0 0;
        flex: 1 0 auto;
        flex-direction: row;
        border-left: none; }
        @media (min-width: 64em) {
          .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__addons {
            padding: 0 0 0 1.8rem;
            margin: 0;
            flex: 0 0 25rem;
            flex-direction: column;
            border-left: 1px solid #f0f0f0; } }
  .cs-product-tile__addons-top {
    width: 100%; }
    .cs-product-tile--list .cs-product-tile__addons-top {
      flex: 1; }
  .cs-product-tile__addons-sides {
    display: flex;
    width: 100%; }
  .cs-product-tile__addons-right {
    padding-left: 1rem;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column; }
    .ie11 .cs-product-tile__addons-right {
      width: 30%; }
    .cs-product-tile--grid .cs-product-tile__addons-right {
      align-items: flex-end; }
    .cs-product-tile--list .cs-product-tile__addons-right {
      align-items: flex-start;
      padding-left: 0;
      order: -1; }
  .cs-product-tile__addons-bottom {
    width: 100%; }
    .cs-product-tile--list .cs-product-tile__addons-bottom {
      display: none; }
      @media (min-width: 48em) {
        .cs-product-tile--list .cs-product-tile__addons-bottom {
          display: block; } }
      .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__addons-bottom {
        display: none; }
        @media (min-width: 64em) {
          .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__addons-bottom {
            display: block; } }
  .cs-product-tile__addons-left {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column; }
    .ie11 .cs-product-tile__addons-left {
      width: 70%; }
    .cs-product-tile--grid .cs-product-tile__addons-left {
      align-items: flex-start; }
    .cs-product-tile--list .cs-product-tile__addons-left {
      align-items: flex-end;
      padding-right: 0;
      order: 2; }
  .cs-product-tile__review--grid {
    margin: 1rem 0 0; }
    .cs-product-tile--list .cs-product-tile__review--grid {
      display: none; }
  .cs-product-tile__review--list {
    margin: 0 0 1rem; }
    @media (min-width: 48em) {
      .cs-product-tile__review--list {
        position: absolute;
        top: 0;
        right: 2rem; }
        .cs-product-tile--list .cs-product-tile__review--list + .cs-product-tile__name {
          padding-right: 12rem; } }
    .cs-products-list--with-sidebar .cs-product-tile__review--list {
      position: relative;
      top: initial;
      right: initial; }
      @media (min-width: 64em) {
        .cs-products-list--with-sidebar .cs-product-tile__review--list {
          position: absolute;
          top: 0;
          right: 2rem;
          margin: 0 0 1rem; }
          .cs-product-tile--list .cs-products-list--with-sidebar .cs-product-tile__review--list + .cs-product-tile__name {
            padding-right: 12rem; } }
    .cs-product-tile--grid .cs-product-tile__review--list {
      display: none; }
  .cs-product-tile__review:empty {
    margin: 0 !important; }
  .cs-product-tile__stock {
    display: flex;
    white-space: nowrap;
    font-size: 1.2rem;
    line-height: 1.4rem;
    align-items: center;
    justify-content: center; }
    .cs-product-tile__stock:before {
      content: "";
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      margin-right: 0.8rem; }
    .cs-product-tile__stock--1:before {
      background-color: #76AF5B; }
    .cs-product-tile__stock--2:before {
      background-color: #58A5DE; }
    .cs-product-tile__stock--3:before {
      background-color: #F9EB60; }
    .cs-product-tile__stock--4:before {
      background-color: #E8A44B; }
    .cs-product-tile__stock--5:before {
      background-color: #812B75; }
    .cs-product-tile--grid .cs-product-tile__stock {
      margin: 3rem 0 1.6rem; }
    .cs-product-tile--list .cs-product-tile__stock {
      margin: 0 1rem 0 0; }
  .cs-product-tile--list .cs-product-tile__dailydeal--grid {
    display: none !important; }
  .cs-product-tile__dailydeal--list {
    position: relative;
    width: auto;
    margin-bottom: 0; }
    .cs-product-tile--grid .cs-product-tile__dailydeal--list {
      display: none !important; }
    .cs-product-tile__dailydeal--list.cs-dailydeal--tile {
      position: relative;
      width: auto;
      bottom: 0; }
  .cs-product-tile--grid .cs-product-tile__dailydeal {
    margin: 0; }
  .cs-product-tile--list .cs-product-tile__dailydeal {
    margin: 1rem 0 0 0; }
  .cs-product-tile__qty {
    margin-bottom: 1rem;
    width: 11rem;
    margin-left: auto; }
  .cs-product-tile__main {
    position: relative;
    display: flex;
    align-items: stretch; }
    .cs-product-tile--grid .cs-product-tile__main {
      flex-direction: column;
      justify-content: center; }
    .cs-product-tile--list .cs-product-tile__main {
      flex-direction: row;
      flex: 1 1 100%; }
      @media (min-width: 48em) {
        .cs-product-tile--list .cs-product-tile__main {
          padding: 0 2rem; } }
      .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__main {
        padding: 0; }
        @media (min-width: 64em) {
          .cs-products-list--with-sidebar .cs-product-tile--list .cs-product-tile__main {
            padding: 0 2rem; } }
  .cs-product-tile__details {
    flex-shrink: 0; }
    .cs-product-tile--list .cs-product-tile__details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      padding: 0; }
  .cs-product-tile__swatches {
    display: flex; }
    .cs-product-tile--grid .cs-product-tile__swatches {
      margin: 1.5rem 0 1rem;
      min-height: 2.6rem; }
      .cs-product-tile--grid .cs-product-tile__swatches,
      .cs-product-tile--grid .cs-product-tile__swatches .swatch-attribute-options {
        justify-content: center; }
    .cs-product-tile--list .cs-product-tile__swatches {
      width: 100%;
      margin: 0 0 0.5rem; }
      .cs-product-tile--list .cs-product-tile__swatches:empty {
        margin: 0; }
      .cs-product-tile--list .cs-product-tile__swatches,
      .cs-product-tile--list .cs-product-tile__swatches .swatch-attribute-options {
        justify-content: flex-start; }
    .cs-product-tile__swatches--list:empty {
      margin: 0 !important; }
    .ie11 .cs-product-tile__swatches {
      width: 100%; }
  .cs-product-tile__brand {
    height: 2rem;
    margin-top: 1.8rem;
    text-align: center; }
    .cs-product-tile__brand--empty {
      height: 0; }
    .cs-product-tile--list .cs-product-tile__brand {
      margin: 0;
      order: -2;
      text-align: left; }
  .cs-product-tile__brand-text {
    font-size: 1.2rem;
    color: #888; }
  .cs-product-tile__addons-sku {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: #232222; }
    @media (min-width: 48em) {
      .cs-product-tile__addons-sku {
        font-size: 14px;
        line-height: 17px; } }
    .cs-product-tile__addons-sku p {
      margin: 0; }
      .cs-product-tile__addons-sku p:before {
        content: attr(data-label); }
        @media (min-width: 48em) {
          .cs-product-tile__addons-sku p:before {
            display: none; } }
      @media (max-width: 47.99em) {
        .cs-product-tile__addons-sku p label {
          display: none; } }
  .cs-product-tile__name {
    line-height: 2.2rem;
    font-weight: 500;
    font-size: 1.8rem;
    overflow: hidden;
    width: 100%;
    letter-spacing: 0.03em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 44px; }
    .cs-product-tile--grid .cs-product-tile__name {
      margin: 0.6rem 0; }
      @media (min-width: 48em) {
        .cs-product-tile--grid .cs-product-tile__name {
          margin: 1.6rem 0 1.4rem; } }
    .cs-product-tile--grid .cs-product-tile__details > .cs-product-tile__brand + .cs-product-tile__name {
      margin-top: 0; }
    .cs-product-tile--grid .cs-product-tile__details > .cs-product-tile__brand--empty + .cs-product-tile__name {
      height: 6.6rem; }
    .cs-product-tile--list .cs-product-tile__name {
      margin: 0 0 1rem;
      text-align: left;
      order: -1; }
    .cs-product-tile__name p {
      margin: 0; }
  .cs-product-tile__name-link {
    color: #212121;
    transition: color 0.15s linear;
    /* disabled because autoprefixer can't build this */
    /* stylelint-disable value-no-vendor-prefix */
    /* stylelint-disable property-no-vendor-prefix */
    /* autoprefixer: ignore next */
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    line-clamp: 2;
    max-width: 100%;
    /* stylelint-enable value-no-vendor-prefix */
    /* stylelint-enable property-no-vendor-prefix */ }
    .cs-product-tile__name-link:visited {
      color: #212121; }
    .cs-product-tile__name-link:hover {
      color: #232222;
      text-decoration: none; }
  .cs-product-tile__description {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.4;
    color: #696969;
    margin: 0 0 1rem; }
    .cs-product-tile--grid .cs-product-tile__description {
      display: none; }
    .cs-product-tile--list .cs-product-tile__description {
      width: 100%;
      text-align: left; }
    .cs-product-tile__description:empty {
      margin: 0 !important; }
  .cs-product-tile__additional--list {
    margin: auto 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; }
    @media (min-width: 48em) {
      .cs-product-tile__additional--list {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between; } }
    .cs-products-list--with-sidebar .cs-product-tile__additional--list {
      flex-direction: column;
      align-items: flex-start; }
      @media (min-width: 64em) {
        .cs-products-list--with-sidebar .cs-product-tile__additional--list {
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between; } }
  .ie11 .cs-product-tile__addto {
    width: 100%; }
  .cs-product-tile--list .cs-product-tile__addto--grid {
    display: none; }
  .cs-product-tile--grid .cs-product-tile__addto--list {
    display: none; }
  .cs-product-tile--grid .cs-product-tile__addto {
    margin: 0;
    width: 100%; }
    .cs-product-tile--grid .cs-product-tile__addto .cs-links-block-addto__item {
      width: 50%; }
      .cs-product-tile--grid .cs-product-tile__addto .cs-links-block-addto__item--wishlist {
        order: 1; }
  .cs-product-tile--list .cs-product-tile__addto {
    margin: 1rem 0 0; }
  .cs-product-tile__addto .towishlist.loggedin {
    font-size: 1.3rem;
    position: absolute;
    top: 3.5rem;
    right: 0.5rem;
    height: auto;
    background: none; }
    @media (min-width: 48em) {
      .cs-product-tile__addto .towishlist.loggedin {
        right: 1.8rem; } }
  .cs-product-tile__addto .cs-links-block-addto__icon {
    margin: 0; }
  .cs-product-tile__addto .cs-links-block-addto__link-label {
    display: none !important; }
  .cs-dashboard__bottom-wishlist .cs-product-tile__addto,
  .cs-dashboard__wishlist-products-grid .cs-product-tile__addto {
    display: none; }
  .cs-product-tile__addto:empty {
    margin: 0 !important; }
  .ie11 .cs-product-tile__addto .cs-links-block-addto__item--wishlist {
    width: 100%; }
  .ie11 .cs-product-tile__addto .cs-links-block-addto--grid {
    width: 100%; }
  .cs-product-tile--grid .cs-product-tile__addtocart {
    margin: 1rem 0 0;
    display: flex; }
    .cs-product-tile--grid .cs-product-tile__addtocart .cs-addtocart__wrapper {
      width: 50%;
      padding-left: 2px; }
    .cs-product-tile--grid .cs-product-tile__addtocart .cs-product-tile__qty {
      width: 50%;
      margin-bottom: 0; }
  .cs-product-tile--list .cs-product-tile__addtocart {
    margin: 0.5rem 0 0; }
  .form-wishlist-items:not(.loggedin) .cs-product-tile__addtocart {
    display: none !important; }
  .cs-product-tile__addtocart-button {
    width: 100%;
    height: 46px; }
  .cs-product-tile__wishlist-remove-icon {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.8rem; }
    @media (pointer: fine) {
      .cs-product-tile__wishlist-remove-icon {
        display: none; } }
  .cs-product-tile__wishlist-remove-icon-image {
    width: 1.2rem;
    height: 1.2rem;
    fill: #888; }
  .cs-product-tile__wishlist-comment {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
    .cs-product-tile--list .cs-product-tile__wishlist-comment {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      white-space: nowrap;
      /* 1 */ }
  .cs-product-tile__wishlist-actions {
    margin: 1rem 0 0;
    display: flex;
    justify-content: space-between; }
  .cs-product-tile__price {
    line-height: 1.2; }
    .cs-product-tile--list .cs-product-tile__price--grid {
      display: none; }
    .cs-product-tile--grid .cs-product-tile__price--list {
      display: none; }
    .cs-product-tile__price [data-label="Incl. tax"]::before {
      display: none !important; }
    .cs-product-tile__price .price-box {
      display: flex; }
      .cs-product-tile__price .price-box .price {
        font-size: 1.8rem;
        font-weight: 500;
        color: #383838;
        letter-spacing: 0.03em; }
      .cs-product-tile__price .price-box .old-price .price {
        font-size: 1.5rem;
        font-weight: 700;
        color: #696969;
        text-decoration: line-through; }
      .cs-product-tile__price .price-box .special-price .price {
        font-size: 1.5rem;
        font-weight: 700;
        color: #e41f12; }
      .cs-product-tile__price .price-box .minimal-price {
        margin: 0;
        display: flex; }
      .cs-product-tile--grid .cs-product-tile__price .price-box {
        margin: 0;
        align-items: center;
        justify-content: flex-start; }
        .cs-product-tile--grid .cs-product-tile__price .price-box .old-price {
          order: -1; }
        .cs-product-tile--grid .cs-product-tile__price .price-box .minimal-price {
          align-items: center; }
        .cs-product-tile--grid .cs-product-tile__price .price-box .old-price .price {
          margin-right: 1rem; }
      .cs-product-tile--list .cs-product-tile__price .price-box {
        justify-content: flex-end;
        margin: 0;
        text-align: right;
        flex-direction: column; }
        .cs-product-tile--list .cs-product-tile__price .price-box .price-wrapper {
          justify-content: flex-end; }
        .cs-product-tile--list .cs-product-tile__price .price-box .old-price {
          order: -1; }
        .cs-product-tile--list .cs-product-tile__price .price-box .price-from {
          margin-right: 0; }
        .cs-product-tile--list .cs-product-tile__price .price-box .minimal-price {
          flex-direction: column; }
    .cs-product-tile__price--dailydeal-countdown .price-box:not(.price-final_price_without_daily_deal) {
      display: none; }
    .cs-product-tile__price--dailydeal-countdown .price-box.price-box--visible {
      display: flex; }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-headline {
  font-size: 1rem;
  clear: both;
  padding: 2em 0;
  margin: 2em 0;
  margin: 0;
  padding: 0;
  margin: 0 0 3rem; }
  @media (max-width: 47.99em) {
    .cs-headline {
      margin: 0;
      padding: 10px 0; } }
  @media (min-width: 48em) {
    .cs-headline {
      margin: 0 0 4rem; } }
  .cs-headline--skip-spacing {
    margin: 0; }
  .block.products-upsell .cs-headline,
  .block.products-recently .cs-headline {
    margin: 0; }
    @media (max-width: 47.99em) {
      .block.products-upsell .cs-headline,
      .block.products-recently .cs-headline {
        padding: 10px 0 0;
        min-height: 42px; } }
    .block.products-upsell .cs-headline .cs-headline__title,
    .block.products-recently .cs-headline .cs-headline__title {
      margin: 0; }
      @media (max-width: 47.99em) {
        .block.products-upsell .cs-headline .cs-headline__title,
        .block.products-recently .cs-headline .cs-headline__title {
          padding-right: 100px; } }
  .cs-headline__title {
    display: block;
    align-items: center;
    margin: 0;
    font-size: 4rem;
    font-weight: 600;
    color: #212121;
    text-transform: uppercase;
    margin-bottom: 16px; }
    @media (min-width: 48em) {
      .cs-headline__title {
        text-align: center; } }
    @media (max-width: 47.99em) {
      .cs-headline__title {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.15px;
        text-transform: none;
        margin: 0; } }
  .cs-headline__subtitle {
    color: #000;
    font-size: 1.6rem;
    font-weight: 400;
    text-transform: none;
    margin: 0;
    display: none;
    text-align: center;
    opacity: 0.18; }
    @media (min-width: 48em) {
      .cs-headline__subtitle {
        display: block; } }

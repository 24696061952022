/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-catalogues_top {
  text-align: center; }

.cs-catalogues_top_title {
  font-family: "Arizonia";
  font-size: 56px;
  font-weight: 400;
  letter-spacing: 0.07em;
  color: #D1C9AB;
  margin: 0; }
  @media (min-width: 48em) {
    .cs-catalogues_top_title {
      font-size: 82px; } }

.cs-catalogues_top_subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1px;
  color: #383838;
  margin: 20px 0 23px; }
  @media (min-width: 48em) {
    .cs-catalogues_top_subtitle {
      font-size: 40px;
      line-height: 48px; } }

.cs-catalogues_top_description {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #000;
  opacity: 0.18;
  max-width: 677px;
  margin: 0 auto 35px;
  text-align: left; }
  @media (min-width: 64em) {
    .cs-catalogues_top_description {
      margin-bottom: 100px; } }

@media (min-width: 64em) {
  .cs-catalogues_bottom {
    padding: 0 56px; } }

.cs-catalogues_bottom.active .cs-catalogues_view-all {
  display: none !important; }

.cs-catalogues_bottom.active .cs-catalogues_bottom_pagination,
.cs-catalogues_bottom.active .cs-catalogues_bottom-list {
  height: auto; }

.cs-catalogues_bottom-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)); }

.cs-catalogues_bottom-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1; }
  @media (min-width: 64em) {
    .cs-catalogues_bottom-container .swiper-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      transition-property: transform;
      box-sizing: content-box; } }
  @media (max-width: 63.99em) {
    .cs-catalogues_bottom-container .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr)); } }
  @media (max-width: 47.99em) {
    .cs-catalogues_bottom-container .swiper-wrapper {
      grid-template-columns: minmax(0, 1fr); } }

.cs-catalogues_bottom_item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  margin: 0;
  padding: 0;
  list-style: none;
  height: auto;
  display: flex;
  justify-content: center; }
  @media (max-width: 63.99em) {
    .cs-catalogues_bottom_item {
      padding: 0 10px; } }

.cs-catalogues_bottom_item_content {
  text-align: center; }
  .cs-catalogues_bottom_item_content img {
    margin: 0 0 27px; }
  .cs-catalogues_bottom_item_content h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #232222;
    margin: 0 0 17px; }
  .cs-catalogues_bottom_item_content a {
    background: #383838;
    border: 1px solid #383838;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    height: 48px;
    line-height: 48px;
    width: 100%;
    max-width: 232px;
    font-size: 14px;
    letter-spacing: 0.03em;
    margin-bottom: 75px; }
    .cs-catalogues_bottom_item_content a:hover {
      color: #383838;
      background: #fff; }

.cs-catalogues-button-prev, .cs-catalogues-button-next {
  background: #383838;
  border-radius: 2px;
  width: 56px;
  height: 52px;
  position: absolute;
  top: 50%;
  margin-top: -95px;
  left: 0; }
  .cs-catalogues-button-prev:not(.swiper-button-disabled), .cs-catalogues-button-next:not(.swiper-button-disabled) {
    cursor: pointer; }
  .cs-catalogues-button-prev:before, .cs-catalogues-button-next:before {
    content: "";
    border-bottom: 1px solid #fff;
    width: 26px;
    height: 1px;
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .cs-catalogues-button-prev:after, .cs-catalogues-button-next:after {
    content: "";
    width: 6px;
    height: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent #fff #fff transparent;
    transform: rotate(135deg);
    position: absolute;
    left: 15px;
    top: 22px; }

.cs-catalogues-button-next {
  left: auto;
  right: 0; }
  .cs-catalogues-button-next:after {
    transform: rotate(-45deg);
    left: auto;
    right: 15px; }

.cs-catalogues_bottom_pagination, .cs-catalogues_bottom-list {
  height: 0;
  overflow: hidden; }

.cs-catalogues_view-all {
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  background: #fff;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #383838;
  height: 58px;
  line-height: 58px;
  width: 100%;
  max-width: 232px;
  margin: 0 auto;
  text-align: center; }
  .cs-catalogues_view-all span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    padding-right: 35px;
    position: relative; }
    .cs-catalogues_view-all span:before {
      content: "";
      border-bottom: 1px solid #383838;
      width: 26px;
      height: 1px;
      position: absolute;
      top: 8px;
      right: 0; }
    .cs-catalogues_view-all span:after {
      content: "";
      width: 5px;
      height: 5px;
      border-style: solid;
      border-width: 1px;
      border-color: transparent #383838 #383838 transparent;
      transform: rotate(-45deg);
      position: absolute;
      right: 0;
      top: 6px; }
  .cs-catalogues_view-all:hover {
    background: #383838;
    color: #fff; }
    .cs-catalogues_view-all:hover span:before {
      border-color: #fff; }
    .cs-catalogues_view-all:hover span:after {
      border-color: transparent #fff #fff transparent; }

.simple-pagination ul {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  text-align: center; }

.simple-pagination li {
  display: inline-block;
  margin-right: 14px;
  vertical-align: top; }
  .simple-pagination li a,
  .simple-pagination li span {
    color: #383838;
    font-size: 16px;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid #E7E7E7;
    border-radius: 50%;
    background-color: #fff; }
    .simple-pagination li a:hover,
    .simple-pagination li span:hover {
      color: #fff;
      background-color: #383838;
      border-color: #383838; }

.simple-pagination .current {
  color: #fff;
  background-color: #383838;
  border-color: #383838; }

.simple-pagination .prev,
.simple-pagination .next {
  font-size: 0;
  position: relative; }
  .simple-pagination .prev:before,
  .simple-pagination .next:before {
    content: "";
    border-style: solid;
    border-width: 1px;
    border-color: transparent #D7D5D4 #D7D5D4 transparent;
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    top: 16px;
    left: 15px;
    transform: rotate(-45deg); }

.simple-pagination .prev:before {
  border-color: #D7D5D4 transparent transparent #D7D5D4;
  left: auto;
  right: 14px; }

@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';
@import 'components/typography/mixin';

$footer-links_list-item-margin: 0 0 0.3rem !default;

$footer-links_link-font-size: inherit !default;
$footer-links_link-color: $color_Black38 !default;
$footer-links_link-hover-color: $footer-links_link-color !default;
$footer-links_link-decoration: none !default;
$footer-links_link-hover-decoration: underline !default;
$footer-links_link-padding-mobile: 0.5em 0 !default;
$footer-links_link-padding-desktop: 0 !default;

.#{$ns}footer-links {
    $root: &;

    @include reset-list-style();

    &__item {
        margin: $footer-links_list-item-margin;
        position: relative;

        @include media('<laptop') {
            margin: 0;
        }

        &:last-child {
            @include media('<laptop') {
                padding-bottom: 18px;
            }
        }
    }

    &__item-flex {
        display: flex;

        @include media('>=laptop') {
            margin-left: -5px;
            margin-right: -5px;
        }

        img {
            padding-right: 10px;

            @include media('>=laptop') {
                width: 33.3333%;
                padding: 0 5px;
            }
        }
    }

    &__item-button {
        a {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.04em;
            width: 100%;
            align-items: center;
            display: flex !important;
            justify-content: center;
            height: 45px;
            border: 1px solid $color_Black38;
            border-radius: 4px;
            margin-top: 12px;

            @include media('>=tablet') {
                height: 58px;
            }

            &:hover,
            &:active {
                text-decoration: none !important;
            }
        }
    }

    a {
        @include link(
            $color: $footer-links_link-color,
            $color-hover: $footer-links_link-hover-color,
            $decoration: $footer-links_link-decoration,
            $decoration-hover: $footer-links_link-hover-decoration
        );

        &,
        &:visited {
            display: block;
            padding: $footer-links_link-padding-mobile;
            font-size: $footer-links_link-font-size;

            @include media('>=laptop') {
                padding: $footer-links_link-padding-desktop;
                display: inline-block;
            }
        }
    }

    &-title {
        color: $color_Black38;
        font-size: 14px;
        line-height: 17px;
        text-transform: none;
        font-weight: 400;
        margin: 0 0 20px;
        letter-spacing: 0.03em;

        @include media('<laptop') {
            margin: 0;
            padding: 18px 0;
        }

        @include media('>=tablet') {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }

        &[data-role='title'] {
            border-top: 0 none;

            .cs-footer & {
                color: $color_Black38;
                font-size: 14px;
                line-height: 17px;
                text-transform: none;
                font-weight: 400;
                margin: 0 0 20px;
                letter-spacing: 0.03em;

                @include media('<laptop') {
                    margin: 0;
                    padding: 18px 0;
                }

                @include media('>=tablet') {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                }

                @include media('>=laptop') {
                    padding-bottom: 0;
                }
            }

            &::after {
                @include media('<laptop') {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-style: solid;
                    border-color: $color_prussian-blue;
                    border-width: 0 2px 2px 0;
                    position: absolute;
                    transform: rotate(225deg);
                    transition: transform 0.3s;
                    top: 10px;
                    right: 2px;
                }
            }

            &:not([role=tab]){
                &::after {
                    display: none;
                }
            }
        }

        //&[aria-expanded=true] {
        //    .cs-footer & {
        //        margin: 0 0 20px;
        //    }
        //}

        &[aria-expanded=false] {
            + .cs-footer-links {
                @include media('<laptop') {
                    max-height: 0;
                }
            }

            &::after {
                @include media('<laptop') {
                    transform: rotate(45deg);
                    top: 5px;
                }
            }
        }

        .cs-footer__section[data-collapsible=true] &,
        .cs-footer__section[role=tab] & {
            &::after {
                @include media('<laptop') {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-style: solid;
                    border-color: $color_Black38;
                    border-width: 0 1px 1px 0;
                    position: absolute;
                    transform: rotate(45deg);
                    transition: transform 0.3s;
                    top: 18px;
                    right: 15px;
                }
            }
        }

        &[aria-expanded=true] {
            .cs-footer__section[data-collapsible=true] & {

                &::after {
                    @include media('<laptop') {
                        transform: rotate(225deg);
                        top: 24px;
                    }
                }
            }
        }

        .cs-footer__section[aria-expanded=true] & {
            &::after {
                @include media('<laptop') {
                    transform: rotate(225deg);
                    top: 24px;
                }
            }
        }

        .cs-footer__item--promo & {
            margin-bottom: 10px;
        }
    }
}

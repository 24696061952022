@import 'config/variables';
@import 'vendors/include-media';
@import 'components/button/mixin';
@import 'etc/view.scss';
@import 'utils/deep-get/deep-get.scss';
@import 'components/pseudoicon/mixin';

$minicart-offcanvas: deep-get(
        $view-xml,
        vars,
        Magento_Checkout,
        minicart_offcanvas,
        enabled
) !default;

$minicart-offcanvas-carousel: deep-get(
        $view-xml,
        vars,
        Magento_Checkout,
        minicart_offcanvas,
        products_carousel,
        enabled
) !default;

$minicart_offcanvas-wrapper: 0 !default;
$minicart_offcanvas-padding: 1.5rem 2rem !default;
$minicart_offcanvas-title-padding: 1.8rem 0 !default;
$minicart_offcanvas-item-padding: 1.5rem 2rem !default;
$minicart_offcanvas-free-shipping-indicator: false !default;
$minicart_offcanvas-offset-iphone-fix: 40rem !default;

$minicart__close-include-pseudoicon: true !default;
$minicart__close-width: 2rem !default;
$minicart__close-line-width: 0.2rem !default;
$minicart__close-color: $color_text-600 !default;
$minicart__close-color-hover: $color_text-500 !default;

$minicart_offcanvas-carousel-background: $color_background-200 !default;
$minicart_offcanvas-carousel-spacing: 2rem !default;
$minicart_offcanvas-carousel-position: 'center' !default; // Default value: center. Possible values: center / top / bottom
$minicart_offcanvas-carousel-position-offset: $minicart_offcanvas-carousel-spacing !default;
$minicart_offcanvas-carousel-button-position: 'center' !default; // Default value: center. Possible values: center / left / right
$minicart_offcanvas-carousel-button-border: 1px solid $color_border-500 !default;
$minicart_offcanvas-carousel-button-spacing: 3rem !default;
/* prettier-ignore */
$minicart_offcanvas-carousel-transition: opacity $transition-default-time ease-in !default;
$minicart_offcanvas-carousel-spinner-size: 11rem !default;

$minicart_padding: 2rem 0 !default;

$minicart_close-button-color: $color_text-200 !default;

$minicart_item-border: 1px solid $color_black !default;
$minicart_item-headline-color: $color_text-800 !default;
$minicart_item-padding: 1.5rem !default;

$minicart_background: $color_background-500 !default;
$minicart_box-shadow: 0 0 21px 0 rgba($color_background-800, 0.27) !default;

$minicart_width: 40rem !default;

$minicart_content-background: $color_background-200 !default;
$minicart_action-wrapper_box-shadow: 0 -5px 5px -5px
rgba($color_background-800, 0.27) !default;

$minicart_button-checkout-type: $button_default-type !default;
$minicart_button-checkout-icon-pos: $button_default-icon-pos !default;

$minicart_button-viewcart-type: 'secondary' !default;
$minicart_button-viewcart-icon-pos: $button_default-icon-pos !default;

$minicart_button-carousel-type: 'secondary' !default;
$minicart_button-carousel-icon-pos: $button_default-icon-pos !default;

.#{$ns}loginpopup {
    $root: &;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__wrapper {
        padding: 10.5rem 3.8rem 3.8rem 4.7rem;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color_GrayF8;
        box-sizing: content-box;
        border: 0;
        box-shadow: none;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: $color_mineshaft;
        line-height: inherit;
        margin: 0;
        padding: 0;
        text-decoration: none;
        text-shadow: none;
        font-weight: 400;
        width: 100%;
        height: 5.8rem;
        cursor: pointer;
    }

    &__close-icon {
        background-image: url('../images/login_close.png');
        background-position: center center;
        background-repeat: no-repeat;
        width: 1.9rem;
        height: 1.9rem;
        display: inline-block;
        margin: 0 4px;
    }

    &__close-text {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    &__title-wrapper {
        position: relative;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: $color_mineshaft;
        display: flex;
        align-items: center;
        margin: 0 0 35px;

        &:before {
            content: "";
            background-image: url('../images/account.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100%;
            width: 14px;
            height: 15px;
            display: block;
            margin-right: 11px;
        }
    }

    &__items-total {
        margin-left: 0.5rem;

        &:not(#{$root}__items-total--empty) {
            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }

        &--empty {
            .count {
                display: none;
            }
        }

        @if $minicart-offcanvas {
            color: $color_text-500;
            font-weight: 400;
        }
    }

    &__edit-link {
        font-weight: 700;

        .#{$ns}link__icon {
            padding: 0 0 0 1rem;
        }
    }

    &__content {
        height: auto !important;
        background-color: $minicart_content-background;
        position: relative;

        .load.indicator:before {
            height: unset;
        }
    }

    &__list {
        padding: 0;
        list-style: none;
        margin-bottom: 0;

        &--scroll {
            height: auto !important;
            max-height: calc(100vh - 36rem);
            overflow-y: auto;

            @include media('>=tablet') {
                max-height: calc(100vh - 38rem);

                .is-tablet & {
                    max-height: calc(100vh - 43rem);
                }
            }

            @if $minicart_offcanvas-free-shipping-indicator {
                /* iPhone X and Xs Max */
                /* prettier-ignore */
                @media only screen
                and (min-device-width: 375px)
                and (min-device-height: 812px)
                and (-webkit-device-pixel-ratio: 3)
                and (orientation: portrait) {
                    max-height: calc(100vh - #{$minicart_offcanvas-offset-iphone-fix}) !important;
                }

                /* iPhone XR */
                /* prettier-ignore */
                @media only screen
                and (min-device-width: 414px)
                and (min-device-height: 896px)
                and (-webkit-device-pixel-ratio: 2)
                and (orientation: portrait) {
                    max-height: calc(100vh - #{$minicart_offcanvas-offset-iphone-fix}) !important;
                }
            }
        }
    }

    &__list-item {
        padding: $minicart_item-padding;
        border-bottom: $minicart_item-border;
        margin-bottom: 0;

        &:last-child {
            border-bottom: none;
        }

        @if $minicart-offcanvas {
            padding: $minicart_offcanvas-item-padding;
        }
    }

    &__actions-wrapper {
        padding: $minicart_padding;
        position: relative;

        @if $minicart-offcanvas {
            padding: $minicart_offcanvas-padding;
            box-shadow: $minicart_action-wrapper_box-shadow;
        }
    }

    &__actions {
        margin-top: 1rem;

        &:first-child {
            margin-top: 0;
        }
    }

    &__empty {
        padding: $minicart_padding;
        display: inline-block;
        font-size: 1.4rem;
        text-align: center;
    }

    &__subtotal {
        font-weight: 700;
        font-size: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;
    }

    &__subtotal-price {
        .price {
            font-weight: 700;
            font-size: 1.5rem !important;

            @if $minicart-offcanvas {
                font-size: 2.1rem !important;
            }
        }
    }

    &__button-checkout {
        @include button(
            $type: $minicart_button-checkout-type,
            $icon_pos: $minicart_button-checkout-icon-pos
        );

        width: 100%;

        &-span {
            @include button_span(
                $type: $minicart_button-checkout-type,
                $icon_pos: $minicart_button-checkout-icon-pos
            );
            /* stylelint-disable-next-line */
        }

        &-icon {
            @include button_icon(
                $type: $minicart_button-checkout-type,
                $icon_pos: $minicart_button-checkout-icon-pos
            );
            /* stylelint-disable-next-line */
        }
    }

    &__button-viewcart {
        @include button(
            $type: $minicart_button-viewcart-type,
            $icon_pos: $minicart_button-viewcart-icon-pos
        );

        width: 100%;

        &-span {
            @include button_span(
                $type: $minicart_button-viewcart-type,
                $icon_pos: $minicart_button-viewcart-icon-pos
            );
            /* stylelint-disable-next-line */
        }

        &-icon {
            @include button_icon(
                $type: $minicart_button-viewcart-type,
                $icon_pos: $minicart_button-viewcart-icon-pos
            );
            /* stylelint-disable-next-line */
        }
    }

    &-wrapper {
        .ui-dialog {
            display: block !important;
        }
    }

    &__toggle {
        display: inline-block;

        &:hover {
            text-decoration: underline;
        }
    }

    &__button-carousel {
        &-wrapper {
            display: flex;
            justify-content: center;
            padding-top: $minicart_offcanvas-carousel-button-spacing;
            margin: $minicart_offcanvas-carousel-button-spacing 0;
            border-top: $minicart_offcanvas-carousel-button-border;

            @if $minicart_offcanvas-carousel-button-position == 'left' {
                justify-content: flex-start;
            } @else if $minicart_offcanvas-carousel-button-position == 'right' {
                justify-content: flex-end;
            }
        }

        @include button(
            $type: $minicart_button-carousel-type,
            $icon_pos: $minicart_button-carousel-icon-pos
        );

        &-span {
            @include button_span(
                $type: $minicart_button-carousel-type,
                $icon_pos: $minicart_button-carousel-icon-pos
            );
            /* stylelint-disable-next-line */
        }

        &-icon {
            @include button_icon(
                $type: $minicart_button-carousel-type,
                $icon_pos: $minicart_button-carousel-icon-pos
            );
            /* stylelint-disable-next-line */
        }
    }

    &__messages {
        padding: 0 2rem;
    }

    &-offcanvas__wrapper {
        padding: $minicart_offcanvas-wrapper;
    }

    .fieldset {
        padding: 0;
        border: 0;
        margin: 0;
    }

    .field {
        .label {
            font-weight: 500;
            display: none !important;

            @include media('>=tablet') {
                font-size: 18px;
                line-height: 26px;
            }
        }

        .select,
        .input-text {
            padding: 1.85rem 2.4rem;
            border-radius: 2px;
            border-color: rgba(203, 203, 203, 0.5);
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: rgba(150, 150, 150, 0.8);
        }

        + .field {
            margin-top: 0.8rem;
        }
    }

    .actions-toolbar {
        .action.primary {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            width: 100%;
            border: 1px solid $color_Black38;
            border-radius: 2px;
            display: block;
            text-align: center;
            padding: 1.6rem;

            &.login {
                background: $color_Black38;
                color: $color_white;
                margin: 4.3rem 0 0;
            }

            &.create {
                background: $color_white;
                color: $color_Black38;
                margin: 1rem 0 0;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .secondary {
            text-align: right;
            margin-top: 14px;
        }

        .action.remind {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.03em;
            color: $color_mineshaft;
        }
    }

    .amform-form,
    .amform-parent {
        .action.primary {
            padding: 1.05em 1em;
            border-radius: 4px !important;
            border: 1px solid transparent;
            width: 100%;
            color: $color_white;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.275em;
            text-align: center;
            cursor: pointer;
            margin-top: 15px;
        }
    }
}

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* prettier-ignore */
.cs-loginpopup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }
  .cs-loginpopup__wrapper {
    padding: 10.5rem 3.8rem 3.8rem 4.7rem; }
  .cs-loginpopup__close {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8F8F8;
    box-sizing: content-box;
    border: 0;
    box-shadow: none;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #212121;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    width: 100%;
    height: 5.8rem;
    cursor: pointer; }
  .cs-loginpopup__close-icon {
    background-image: url("../images/login_close.png");
    background-position: center center;
    background-repeat: no-repeat;
    width: 1.9rem;
    height: 1.9rem;
    display: inline-block;
    margin: 0 4px; }
  .cs-loginpopup__close-text {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .cs-loginpopup__title-wrapper {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #212121;
    display: flex;
    align-items: center;
    margin: 0 0 35px; }
    .cs-loginpopup__title-wrapper:before {
      content: "";
      background-image: url("../images/account.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;
      width: 14px;
      height: 15px;
      display: block;
      margin-right: 11px; }
  .cs-loginpopup__items-total {
    margin-left: 0.5rem;
    color: #696969;
    font-weight: 400; }
    .cs-loginpopup__items-total:not(.cs-loginpopup__items-total--empty):before {
      content: '('; }
    .cs-loginpopup__items-total:not(.cs-loginpopup__items-total--empty):after {
      content: ')'; }
    .cs-loginpopup__items-total--empty .count {
      display: none; }
  .cs-loginpopup__edit-link {
    font-weight: 700; }
    .cs-loginpopup__edit-link .cs-link__icon {
      padding: 0 0 0 1rem; }
  .cs-loginpopup__content {
    height: auto !important;
    background-color: #fff;
    position: relative; }
    .cs-loginpopup__content .load.indicator:before {
      height: unset; }
  .cs-loginpopup__list {
    padding: 0;
    list-style: none;
    margin-bottom: 0; }
    .cs-loginpopup__list--scroll {
      height: auto !important;
      max-height: calc(100vh - 36rem);
      overflow-y: auto; }
      @media (min-width: 48em) {
        .cs-loginpopup__list--scroll {
          max-height: calc(100vh - 38rem); }
          .is-tablet .cs-loginpopup__list--scroll {
            max-height: calc(100vh - 43rem); } }
  .cs-loginpopup__list-item {
    padding: 1.5rem;
    border-bottom: 1px solid #000;
    margin-bottom: 0;
    padding: 1.5rem 2rem; }
    .cs-loginpopup__list-item:last-child {
      border-bottom: none; }
  .cs-loginpopup__actions-wrapper {
    padding: 2rem 0;
    position: relative;
    padding: 1.5rem 2rem;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.27); }
  .cs-loginpopup__actions {
    margin-top: 1rem; }
    .cs-loginpopup__actions:first-child {
      margin-top: 0; }
  .cs-loginpopup__empty {
    padding: 2rem 0;
    display: inline-block;
    font-size: 1.4rem;
    text-align: center; }
  .cs-loginpopup__subtotal {
    font-weight: 700;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem; }
  .cs-loginpopup__subtotal-price .price {
    font-weight: 700;
    font-size: 1.5rem !important;
    font-size: 2.1rem !important; }
  .cs-loginpopup__button-checkout {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    width: 100%; }
    .cs-loginpopup__button-checkout:hover, .cs-loginpopup__button-checkout:focus, .cs-loginpopup__button-checkout:active {
      background-color: #383838 !important; }
    .cs-loginpopup__button-checkout:before, .cs-loginpopup__button-checkout:after {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      right: -1px; }
    .cs-loginpopup__button-checkout:before {
      width: 3.8em;
      border-radius: 0 2px 2px 0;
      z-index: -1;
      background: linear-gradient(100deg, transparent 0%, transparent 15%, #383838 15%, #383838 100%); }
    .cs-loginpopup__button-checkout:after {
      width: 3em;
      min-width: auto;
      background-color: #383838;
      transition: width 0.25s linear;
      z-index: -2;
      border-radius: 2px; }
    .cs-loginpopup__button-checkout:hover:after {
      width: calc(100% + 2px); }
    .cs-loginpopup__button-checkout::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-loginpopup__button-checkout, .cs-loginpopup__button-checkout:visited {
      cursor: pointer;
      border-radius: 2px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #383838; }
      .cs-loginpopup__button-checkout *, .cs-loginpopup__button-checkout:visited * {
        color: #fff;
        fill: #fff; }
    .cs-loginpopup__button-checkout:hover:not([disabled]), .cs-loginpopup__button-checkout:focus:not([disabled]), .cs-loginpopup__button-checkout:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #383838; }
      .cs-loginpopup__button-checkout:hover:not([disabled]) *, .cs-loginpopup__button-checkout:focus:not([disabled]) *, .cs-loginpopup__button-checkout:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-loginpopup__button-checkout[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-loginpopup__button-checkout-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 400;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal;
      padding-left: 0;
      padding-right: 4em;
      /* stylelint-disable-next-line */ }
    .cs-loginpopup__button-checkout-icon {
      max-width: 1em;
      max-height: 1.4rem;
      z-index: 2;
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-right: 0;
      right: 1em;
      /* stylelint-disable-next-line */ }
  .cs-loginpopup__button-viewcart {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    width: 100%; }
    .cs-loginpopup__button-viewcart::before, .cs-loginpopup__button-viewcart::after {
      content: none; }
    .cs-loginpopup__button-viewcart::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-loginpopup__button-viewcart, .cs-loginpopup__button-viewcart:visited {
      cursor: pointer;
      border-radius: 2px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid #383838;
      color: #383838;
      background-color: #fff; }
      .cs-loginpopup__button-viewcart *, .cs-loginpopup__button-viewcart:visited * {
        color: #383838;
        fill: #383838; }
    .cs-loginpopup__button-viewcart:hover:not([disabled]), .cs-loginpopup__button-viewcart:focus:not([disabled]), .cs-loginpopup__button-viewcart:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid #383838;
      color: #fff;
      background-color: #383838; }
      .cs-loginpopup__button-viewcart:hover:not([disabled]) *, .cs-loginpopup__button-viewcart:focus:not([disabled]) *, .cs-loginpopup__button-viewcart:active:not([disabled]) * {
        color: #fff;
        fill: #383838; }
    .cs-loginpopup__button-viewcart[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-loginpopup__button-viewcart-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 400;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal;
      padding-left: 0;
      padding-right: 4em;
      /* stylelint-disable-next-line */ }
    .cs-loginpopup__button-viewcart-icon {
      max-width: 1em;
      max-height: 1.4rem;
      z-index: 2;
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-right: 0;
      right: 1em;
      /* stylelint-disable-next-line */ }
  .cs-loginpopup-wrapper .ui-dialog {
    display: block !important; }
  .cs-loginpopup__toggle {
    display: inline-block; }
    .cs-loginpopup__toggle:hover {
      text-decoration: underline; }
  .cs-loginpopup__button-carousel {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color; }
    .cs-loginpopup__button-carousel-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 3rem;
      margin: 3rem 0;
      border-top: 1px solid #dcdcdc; }
    .cs-loginpopup__button-carousel::before, .cs-loginpopup__button-carousel::after {
      content: none; }
    .cs-loginpopup__button-carousel::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-loginpopup__button-carousel, .cs-loginpopup__button-carousel:visited {
      cursor: pointer;
      border-radius: 2px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid #383838;
      color: #383838;
      background-color: #fff; }
      .cs-loginpopup__button-carousel *, .cs-loginpopup__button-carousel:visited * {
        color: #383838;
        fill: #383838; }
    .cs-loginpopup__button-carousel:hover:not([disabled]), .cs-loginpopup__button-carousel:focus:not([disabled]), .cs-loginpopup__button-carousel:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid #383838;
      color: #fff;
      background-color: #383838; }
      .cs-loginpopup__button-carousel:hover:not([disabled]) *, .cs-loginpopup__button-carousel:focus:not([disabled]) *, .cs-loginpopup__button-carousel:active:not([disabled]) * {
        color: #fff;
        fill: #383838; }
    .cs-loginpopup__button-carousel[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-loginpopup__button-carousel-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 400;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal;
      padding-left: 0;
      padding-right: 4em;
      /* stylelint-disable-next-line */ }
    .cs-loginpopup__button-carousel-icon {
      max-width: 1em;
      max-height: 1.4rem;
      z-index: 2;
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-right: 0;
      right: 1em;
      /* stylelint-disable-next-line */ }
  .cs-loginpopup__messages {
    padding: 0 2rem; }
  .cs-loginpopup-offcanvas__wrapper {
    padding: 0; }
  .cs-loginpopup .fieldset {
    padding: 0;
    border: 0;
    margin: 0; }
  .cs-loginpopup .field .label {
    font-weight: 500;
    display: none !important; }
    @media (min-width: 48em) {
      .cs-loginpopup .field .label {
        font-size: 18px;
        line-height: 26px; } }
  .cs-loginpopup .field .select,
  .cs-loginpopup .field .input-text {
    padding: 1.85rem 2.4rem;
    border-radius: 2px;
    border-color: rgba(203, 203, 203, 0.5);
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: rgba(150, 150, 150, 0.8); }
  .cs-loginpopup .field + .field {
    margin-top: 0.8rem; }
  .cs-loginpopup .actions-toolbar .action.primary {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    width: 100%;
    border: 1px solid #383838;
    border-radius: 2px;
    display: block;
    text-align: center;
    padding: 1.6rem; }
    .cs-loginpopup .actions-toolbar .action.primary.login {
      background: #383838;
      color: #fff;
      margin: 4.3rem 0 0; }
    .cs-loginpopup .actions-toolbar .action.primary.create {
      background: #fff;
      color: #383838;
      margin: 1rem 0 0; }
      .cs-loginpopup .actions-toolbar .action.primary.create:hover {
        text-decoration: none; }
  .cs-loginpopup .actions-toolbar .secondary {
    text-align: right;
    margin-top: 14px; }
  .cs-loginpopup .actions-toolbar .action.remind {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #212121; }
  .cs-loginpopup .amform-form .action.primary,
  .cs-loginpopup .amform-parent .action.primary {
    padding: 1.05em 1em;
    border-radius: 4px !important;
    border: 1px solid transparent;
    width: 100%;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.275em;
    text-align: center;
    cursor: pointer;
    margin-top: 15px; }

@import 'config/variables';
@import 'utils/component-spacing';
@import 'components/button/mixin';

$button-fallback_type: $button_default-type !default;
$button-fallback_icon-pos: $button_default-icon-pos !default;

$button-component_type: $button_default-type !default;
$button-component_icon-pos: $button_default-icon-pos !default;
$button-component_horizontal-position: 50% !default;
$button-component_horizontal-translation: translateX(-50%) !default;

.#{$ns}button {
    @include button(
        $type: $button-fallback_type,
        $icon_pos: $button-fallback_icon-pos
    );

    &__span {
        @include button_span(
            $type: $button-fallback_type,
            $icon_pos: $button-fallback_icon-pos
        );
    }

    &__icon {
        @include button_icon(
            $type: $button-fallback_type,
            $icon_pos: $button-fallback_icon-pos
        );
    }
}

.#{$ns}button-component {
    @include button(
        $type: $button-component_type,
        $icon_pos: $button-component_icon-pos
    );
    @include component-spacing();

    position: relative;
    left: $button-component_horizontal-position;
    transform: $button-component_horizontal-translation;

    &__span {
        @include button_span(
            $type: $button-component_type,
            $icon_pos: $button-component_icon-pos
        );
    }

    &__icon {
        @include button_icon(
            $type: $button-component_type,
            $icon_pos: $button-component_icon-pos
        );
    }

    &__amount {
        &:before {
            content: '(';
        }
        &:after {
            content: ')';
        }
    }
}

.ui-switchbutton {
    display: inline-block;
    padding: 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.ui-switchbutton input {
    display: none
}

.ui-switchbutton label {
    white-space: nowrap;
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    width: auto;
    overflow: hidden;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

.ui-switchbutton label.ui-switchbutton-disabled {
    right: 0;
    text-align: right;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.6)
}

.ui-switchbutton label.ui-switchbutton-disabled span {
    display: inline-block;
    text-align: center;
    padding-left: 2px;
    padding-right: 3px
}

.ui-switchbutton.ui-switchbutton-no-labels label span {
    width: 1em
}

.ui-switchbutton label.ui-switchbutton-enabled {
    left: 0;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6)
}

.ui-switchbutton label.ui-switchbutton-enabled span {
    display: inline-block;
    text-align: center;
    padding-left: 3px;
    padding-right: 2px
}

.ui-switchbutton .ui-switchbutton-handle {
    display: block;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer
}

.ui-switchbutton.ui-switchbutton-disabled {
    opacity: .4;
    -ms-filter: alpha(opacity=40);
    filter: alpha(opacity=40);
    filter: Alpha(Opacity=40);
    cursor: default
}

.ui-switchbutton.ui-switchbutton-disabled label, .ui-switchbutton.ui-switchbutton-disabled .ui-switchbutton-handle {
    cursor: default
}

.ui-switchbutton-default {
    height: 24px
}

.ui-switchbutton-default label {
    font-size: 14px;
    line-height: 12px;
    font-weight: bold;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    height: 20px;
    padding-top: 4px
}

.ui-switchbutton-default label.ui-switchbutton-disabled {
    color: #8b8b8b;
    background: $color_GrayE7;
}

.ui-switchbutton-default label.ui-switchbutton-enabled {
    color: white;
    background: #488fe4;
    padding-right: 4px
}

.ui-switchbutton-default .ui-switchbutton-handle {
    height: 12px;
    background: $color_Black38;
    border: 1px solid $color_Black38;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.ui-switchbutton-thin {
    height: 12px;
    border-radius: 6px;
    overflow: hidden;
}

.ui-switchbutton-thin label {
    font-size: 13px;
    line-height: 16px;
    height: 12px;
    padding-top: 2px
}

.ui-switchbutton-thin .ui-switchbutton-handle {
    height: 12px
}

.ui-switchbutton-ios5 label.ui-switchbutton-disabled {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px
}

.ui-switchbutton-ios5 label.ui-switchbutton-enabled {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topright: 0;
    border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0
}

.ui-switchbutton-ios5 label.ui-switchbutton-enabled {
    padding-right: 0
}

.ui-switchbutton-ios5.ui-state-active label.ui-switchbutton-enabled {
    padding-right: 10px
}

.ui-switchbutton-ios5 .ui-switchbutton-handle {
    height: 22px;
    width: 22px !important;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px
}

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-offcanvas {
  position: relative;
  z-index: 301; }
  .cs-offcanvas__drawer, .cs-offcanvas__overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    top: 5rem; }
    @media (min-width: 48em) {
      .cs-offcanvas__drawer, .cs-offcanvas__overlay {
        top: 8.3rem;
        bottom: 0; } }
    @media (min-width: 64em) {
      .cs-offcanvas__drawer, .cs-offcanvas__overlay {
        display: none; } }
    .cs-offcanvas--mini-cart .cs-offcanvas__drawer, .cs-offcanvas--mini-cart .cs-offcanvas__overlay {
      display: block;
      top: 0; }
    .cs-offcanvas--loginpopup .cs-offcanvas__drawer, .cs-offcanvas--loginpopup .cs-offcanvas__overlay {
      display: block;
      top: 0; }
    .cs-offcanvas--navigation .cs-offcanvas__drawer, .cs-offcanvas--navigation .cs-offcanvas__overlay {
      top: 0;
      height: 100%; }
  .cs-offcanvas__overlay {
    width: 100%;
    height: calc(100% - 5rem);
    background-color: transparent;
    transition: background-color 0.3s, transform 1ms 0.3s;
    transform: scale(0);
    cursor: pointer; }
    @media (min-width: 48em) {
      .cs-offcanvas__overlay {
        height: 100%; } }
    .cs-offcanvas--mini-cart .cs-offcanvas__overlay {
      height: 100%; }
  .cs-offcanvas .overlay--visible {
    transform: scale(1);
    background: rgba(27, 25, 25, 0.18);
    transition: background-color 0.3s; }
  .cs-offcanvas__drawer {
    position: fixed;
    background: #fff;
    transition: transform 0.3s;
    width: 90%;
    max-width: 40rem; }
    .cs-offcanvas--side_left .cs-offcanvas__drawer {
      left: 0;
      right: auto;
      transform: translate3d(-105%, 0, 0); }
    .cs-offcanvas--side_right .cs-offcanvas__drawer {
      left: auto;
      right: 0;
      transform: translate3d(105%, 0, 0); }
    .cs-offcanvas__drawer--navigation {
      left: 0;
      right: auto;
      transform: translate3d(-105%, 0, 0); }
    .cs-offcanvas--loginpopup .cs-offcanvas__drawer {
      max-width: 44.9rem; }
    .cs-offcanvas--navigation .cs-offcanvas__drawer {
      width: calc(100% - 56px);
      padding-top: 70px;
      padding-bottom: 45px; }
  .cs-offcanvas .drawer--visible {
    transform: translate3d(0, 0, 0) !important; }
  .cs-offcanvas__spinner {
    position: absolute;
    width: 6rem;
    height: 6rem;
    left: 50%;
    top: 20%;
    margin-left: -3rem; }
  .cs-offcanvas-mobile_menu_links {
    background: #F5F5F5; }
  .cs-offcanvas-mobile_menu_social_links {
    background: #fff;
    border-top: 1px solid rgba(33, 33, 33, 0.08);
    padding: 30px 10px 22px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cs-offcanvas-mobile_menu_social_link {
    margin: 0 11px; }
  .cs-offcanvas-mobile_menu_static_links {
    padding: 10px 0; }
    .cs-offcanvas-mobile_menu_static_links .cs-offcanvas-navigation__text {
      display: flex;
      align-items: center; }
      .cs-offcanvas-mobile_menu_static_links .cs-offcanvas-navigation__text img {
        margin-right: 13px; }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.ui-slider-horizontal {
  background: #dcdcdc;
  height: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
  border-radius: 1rem; }
  .ui-slider-horizontal .ui-slider-handle {
    width: 1rem;
    height: 1rem;
    background: #383838;
    transition: transform 0.3s, background 0.3s;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    margin-left: -0.5rem; }
    .ui-slider-horizontal .ui-slider-handle.ui-state-hover, .ui-slider-horizontal .ui-slider-handle.ui-state-active, .ui-slider-horizontal .ui-slider-handle.ui-state-focus {
      transform: scale(1.5);
      background: #383838; }

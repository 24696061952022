/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.page-title {
  font-family: "CF Asty Pro", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.6rem;
  color: #212121;
  font-weight: 700;
  text-transform: none;
  margin: 1rem 0 2rem;
  padding-bottom: 1rem;
  text-transform: none;
  font-weight: 600; }
  @media (min-width: 48em) {
    .page-title {
      font-size: 2.6rem; } }
  .page-title__headline {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    display: inline; }
  .page-title__span {
    color: #696969;
    font-weight: 400;
    padding: 0; }

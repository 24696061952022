/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-page-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 64em) {
    .cs-page-bottom {
      justify-content: flex-start;
      height: 56px;
      justify-content: space-between; } }
  .page-print .cs-page-bottom {
    display: none; }
  .cs-page-bottom__image {
    height: 3rem;
    width: auto; }
    .cs-page-bottom__image--circle {
      width: 4.7rem;
      height: 4.7rem;
      min-width: 4.7rem;
      min-height: 4.7rem;
      margin-right: 1rem; }
  .cs-page-bottom__text {
    font-size: 1.2rem;
    margin-bottom: 0; }
    .cs-page-bottom__text--small {
      font-size: 1rem; }
    @media (min-width: 64em) {
      .cs-page-bottom__text {
        font-size: 1.3rem; } }
  @media (max-width: 63.99em) {
    .cs-page-bottom__group_desktop {
      display: none !important; } }
  .cs-page-bottom__group {
    display: flex;
    align-items: center; }
    @media (min-width: 64em) {
      .cs-page-bottom__group {
        width: auto;
        padding: 0; } }
    @media (min-width: 64em) {
      .cs-page-bottom__group:first-child {
        margin-bottom: auto; } }
    .cs-page-bottom__group--info {
      display: none;
      flex-direction: row-reverse; }
      @media (min-width: 64em) {
        .cs-page-bottom__group--info {
          display: flex;
          flex-basis: 40%;
          justify-content: flex-end;
          padding-right: 1.5rem; }
          .cs-page-bottom__group--info .cs-page-bottom__text {
            max-width: 20rem; } }
      @media (min-width: 64em) {
        .cs-page-bottom__group--info {
          flex-basis: 17%;
          padding-right: 0; }
          .cs-page-bottom__group--info .cs-page-bottom__text {
            max-width: 100%; } }
    .cs-page-bottom__group--shipping {
      display: none; }
      @media (min-width: 64em) {
        .cs-page-bottom__group--shipping {
          display: flex;
          flex-basis: 55%;
          padding-left: 1.5rem; } }
      @media (min-width: 64em) {
        .cs-page-bottom__group--shipping {
          flex-basis: 33%;
          padding-left: 4rem; } }
    .cs-page-bottom__group--payment {
      flex-basis: 50%; }
      @media (max-width: 63.99em) {
        .cs-page-bottom__group--payment .cs-page-bottom__text {
          display: none; } }
      @media (min-width: 64em) {
        .cs-page-bottom__group--payment {
          padding-left: 4rem; } }
    @media (min-width: 64em) {
      .cs-page-bottom__group--shipping, .cs-page-bottom__group--payment {
        position: relative; }
        .cs-page-bottom__group--shipping:before, .cs-page-bottom__group--payment:before {
          margin: 0 1rem;
          content: '';
          position: absolute;
          left: 1rem;
          top: 50%;
          width: 0.2rem;
          height: 2rem;
          background: #dcdcdc;
          transform: translateY(-50%); } }
  .cs-page-bottom__logo {
    display: flex;
    flex-shrink: 1;
    height: 2rem; }
    @media (max-width: 63.99em) {
      .cs-page-bottom__logo {
        width: auto; } }
    .cs-page-bottom__logo--hermes {
      width: 12.2rem; }
    .cs-page-bottom__logo--dhl {
      height: auto;
      max-height: 2rem;
      min-width: 8.6rem;
      padding-left: 10px; }
    .cs-page-bottom__logo--paypal {
      width: 6.2rem; }
    .cs-page-bottom__logo--visa {
      height: auto;
      max-height: 2rem;
      min-width: 5.5rem; }
    .cs-page-bottom__logo--mastercard {
      width: 3.3rem; }
    .cs-page-bottom__logo--klarna-sofort {
      height: auto;
      max-height: 2.5rem;
      min-height: 2.5rem; }
    .cs-page-bottom__logo--klarna {
      width: 7rem; }
    .cs-page-bottom__logo--gls {
      width: 5rem; }
    .cs-page-bottom__logo--giropay {
      width: 4.8rem; }
    .cs-page-bottom__logo--ec {
      width: 1.8rem; }
    .cs-page-bottom__logo--paydirekt {
      width: 6rem; }
    .cs-page-bottom__logo--lastschrift {
      width: 6.15rem; }
    .cs-page-bottom__logo--vorkasse {
      width: 8.3rem; }
    .cs-page-bottom__logo--rechnung {
      width: 6.15rem; }
    .cs-page-bottom__logo + .cs-page-bottom__logo {
      margin-left: 0.5rem; }

.cs-container--page-bottom {
  max-width: none;
  padding-top: 82px;
  background: #F8F8F8; }
  @media (max-width: 63.99em) {
    .cs-container--page-bottom {
      padding-bottom: 17px; } }
  @media (min-width: 48em) {
    .cs-container--page-bottom {
      padding-top: 51px; } }
  @media (min-width: 64em) {
    .cs-container--page-bottom {
      background: #fff; } }

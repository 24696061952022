/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-links-block-addto {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%; }
  .page-product-bundle .cs-links-block-addto {
    margin-top: 1rem; }
  .cs-product-tile__addon .cs-links-block-addto {
    padding-top: 0; }
  .cs-links-block-addto--centered {
    justify-content: center; }
  .cs-links-block-addto__item {
    margin: 0; }
    .cs-links-block-addto__item:nth-child(2n) {
      text-align: right; }
      .cs-links-block-addto__item:nth-child(2n) .cs-links-block-addto__icon {
        order: 2;
        margin-left: 0.5rem; }
    .cs-links-block-addto__item--share {
      position: relative; }
      .cs-links-block-addto__item--share .addthis_toolbox {
        display: none;
        position: absolute;
        right: 0;
        top: 12px;
        background: #F8F8F8;
        padding: 14px 10px;
        overflow: hidden;
        left: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        z-index: 1; }
        .cs-links-block-addto__item--share .addthis_toolbox a {
          margin: 0; }
          .cs-links-block-addto__item--share .addthis_toolbox a .at-icon-wrapper {
            vertical-align: top; }
      .cs-links-block-addto__item--share.active .addthis_toolbox {
        display: flex; }
  .cs-links-block-addto__link {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #232222;
    transition: color 0.15s linear; }
    .cs-links-block-addto__link:hover {
      color: #232222;
      text-decoration: none; }
    .cs-product-tile__addto .cs-links-block-addto__link {
      background-color: #F8F8F8;
      height: 45px;
      justify-content: center;
      margin: 1.2rem 0 4rem; }
      .cs-product-tile__addto .cs-links-block-addto__link.towishlist:not(.loggedin) {
        background-color: #383838; }
      .cs-product-tile__addto .cs-links-block-addto__link.toshare-tile {
        position: relative; }
        .cs-product-tile__addto .cs-links-block-addto__link.toshare-tile:hover .cs-links-block-addto__link-label {
          background-color: rgba(56, 56, 56, 0.87);
          border-radius: 3px;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.03em;
          color: #fff;
          display: block !important;
          position: absolute;
          left: 0;
          right: 0;
          bottom: calc(100% + 10px);
          line-height: 53px;
          text-align: center; }
          .cs-product-tile__addto .cs-links-block-addto__link.toshare-tile:hover .cs-links-block-addto__link-label:after {
            content: "";
            width: 10px;
            height: 10px;
            border-style: solid;
            border-width: 5px;
            border-color: transparent rgba(56, 56, 56, 0.87) rgba(56, 56, 56, 0.87) transparent;
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: rotate(45deg); }
  .cs-links-block-addto__icon {
    width: 2rem;
    height: 1.7rem;
    margin-right: 0.4rem; }
    .cs-links-block-addto__icon path {
      fill: #888; }
    .cs-links-block-addto__icon--wishlist path {
      fill: none;
      stroke: #fff;
      stroke-width: 20px; }
      .cs-links-block-addto__link.loggedin .cs-links-block-addto__icon--wishlist path {
        stroke: #3F3F41;
        stroke-width: 20px; }
      .cs-links-block-addto__link.wishlisticon.loggedin .cs-links-block-addto__icon--wishlist path {
        fill: #3F3F41; }
    .cs-links-block-addto__icon--compare path {
      fill: #383838; }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
.cs-minicart-product {
  display: flex; }
  .cs-minicart-product__image-container {
    align-items: center;
    display: flex;
    margin-right: 1.6rem; }
    @media (min-width: 48em) {
      .cs-minicart-product__image-container {
        flex-shrink: 0; } }
    @media (max-width: 47.99em) {
      .cs-minicart-product__image-container {
        width: 50px; } }
    .cs-minicart-product__image-container img {
      width: auto !important;
      height: auto !important; }
    .checkout-index-index .cs-minicart-product__image-container {
      width: 50px !important;
      height: auto !important; }
  .cs-minicart-product__image {
    max-height: 100%;
    width: auto; }
  .cs-minicart-product__overview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    width: 60%;
    padding-right: 10px; }
  .cs-minicart-product__details {
    width: 100%;
    display: flex; }
    .checkout-index-index .cs-minicart-product__details {
      flex-direction: column; }
  .cs-minicart-product__brand-wrapper {
    text-align: left; }
  .cs-minicart-product__brand {
    font-size: 1.2rem;
    color: #888; }
  .cs-minicart-product__name-wrapper {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #000;
    flex-basis: 55%;
    padding-bottom: 1.2rem;
    text-align: left;
    font-size: 1.4rem;
    flex-basis: 100% !important; }
    @media (min-width: 30em) {
      .cs-minicart-product__name-wrapper {
        flex-basis: 70%; } }
  .cs-minicart-product__name {
    color: #000; }
    .cs-minicart-product__name:visited, .cs-minicart-product__name:hover {
      text-decoration: none;
      color: #000; }
  .cs-minicart-product__name-qty {
    display: inline-block; }
    .cs-minicart-product__name-qty:before {
      content: "("; }
    .cs-minicart-product__name-qty:after {
      content: ")"; }
    .cs-minicart-product__name-qty .count:before {
      content: "x"; }
  .cs-minicart-product__price .price {
    font-size: 1.4rem;
    font-weight: 400;
    color: #383838; }
  .cs-minicart-product__price .price-including-tax,
  .cs-minicart-product__price .price-excluding-tax {
    font-size: 1.6rem; }
    .cs-minicart-product__price .price-including-tax .price,
    .cs-minicart-product__price .price-excluding-tax .price {
      font-weight: 400;
      font-size: 1.6rem; }
  .cs-minicart-product__price .price {
    font-weight: 400; }
  .cs-minicart-product__price .price-including-tax:before {
    display: none; }
  .cs-minicart-product__price-label, .cs-minicart-product__options-subtitle {
    display: none; }
  .cs-minicart-product__options-title {
    font-size: 1.2rem;
    color: #484848;
    cursor: pointer;
    font-size: 1.3rem;
    color: #212121; }
    .cs-minicart-product__options-title[aria-expanded='true'] > .cs-minicart-product__options-title-svg {
      transform: rotate(180deg);
      vertical-align: initial; }
  .cs-minicart-product__options-title-svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    transition: transform 0.3s cubic-bezier(0, 0.66, 0.32, 0.99);
    vertical-align: middle; }
  .cs-minicart-product__options-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: -0.4rem;
    flex-basis: 100%;
    padding-bottom: 1.2rem; }
  .cs-minicart-product__options-group {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center; }
    .cs-minicart-product__options-group--qty {
      margin-top: 0.5rem; }
      .cs-minicart-product__options-wrapper + .cs-minicart-product__options-group--qty {
        margin-top: 1.5rem; }
  .cs-minicart-product__options-content {
    width: 100%;
    padding-top: 0.5rem; }
  .cs-minicart-product__options-label {
    font-size: 1.2rem;
    color: #888;
    font-weight: 400;
    margin-bottom: 0;
    text-align: left;
    padding-right: 0.5rem;
    font-size: 1.1rem;
    font-weight: 700;
    color: #212121;
    text-transform: uppercase; }
    .cs-minicart-product__options-label:after {
      content: ':'; }
  .cs-minicart-product__options-value {
    font-size: 1.2rem;
    color: #888;
    margin-bottom: 0;
    width: 100%; }
    .cs-minicart-product__options-value__value {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .cs-minicart-product__options-value__value .price {
        color: #383838; }
  .cs-minicart-product__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%; }
  .cs-minicart-product__action-wrapper.primary {
    display: none; }
  .cs-minicart-product__action-wrapper.secondary {
    width: 15px;
    height: 15px;
    overflow: hidden;
    flex-shrink: 0; }
    .cs-minicart-product__action-wrapper.secondary .action.delete {
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      transform: rotate(45deg); }
      .cs-minicart-product__action-wrapper.secondary .action.delete:before, .cs-minicart-product__action-wrapper.secondary .action.delete:after {
        content: "";
        width: 100%;
        height: 0.1rem;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0) !important;
        background: #484848;
        backface-visibility: hidden; }
      .cs-minicart-product__action-wrapper.secondary .action.delete:after {
        transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
      .cs-minicart-product__action-wrapper.secondary .action.delete svg {
        display: none; }
  .cs-minicart-product__qty,
  .cs-minicart-product .cs-qty-increment {
    padding: 0 40px; }
    .cs-minicart-product__qty input.item-qty,
    .cs-minicart-product .cs-qty-increment input.item-qty {
      width: 0;
      height: 26px;
      opacity: 0;
      overflow: hidden;
      padding: 0;
      border: 0;
      max-width: 0;
      font-size: 0; }
  .cs-minicart-product .cs-qty-increment .cs-qty-increment__button {
    border-color: #383838;
    border-radius: 0;
    max-width: 4rem;
    height: 26px; }
    .cs-minicart-product .cs-qty-increment .cs-qty-increment__button * {
      fill: #383838; }
    .cs-minicart-product .cs-qty-increment .cs-qty-increment__button:hover, .cs-minicart-product .cs-qty-increment .cs-qty-increment__button:active {
      background-color: #383838;
      border-color: #383838; }
      .cs-minicart-product .cs-qty-increment .cs-qty-increment__button:hover *, .cs-minicart-product .cs-qty-increment .cs-qty-increment__button:active * {
        fill: #fff; }
    .cs-minicart-product .cs-qty-increment .cs-qty-increment__button--decrement {
      right: auto;
      left: 0; }
  .cs-minicart-product .cs-qty-increment .cs-qty-increment__icon--decrement {
    width: 1rem;
    height: 0.15rem; }
  .cs-minicart-product .cs-qty-increment .cs-qty-increment__icon--increment {
    width: 0.838rem;
    height: 0.835rem; }
  .cs-minicart-product__qty-label {
    display: none; }
  .cs-minicart-product__qty-input {
    font-size: 1em;
    line-height: 1.275em;
    padding: 0.75em 1em;
    color: #212121;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    transition: border-color 0.5s;
    margin: 0 0 0.2em;
    width: 100%;
    background-clip: padding-box;
    font-family: inherit;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 0;
    height: 3rem;
    width: 4rem;
    max-width: 4rem;
    font-size: 1.2rem; }
    .cs-minicart-product__qty-input:hover:not([disabled]), .cs-minicart-product__qty-input:focus:not([disabled]) {
      border: 1px solid #383838;
      box-shadow: none; }
    .cs-minicart-product__qty-input::placeholder {
      opacity: 1;
      color: #888; }
    .cs-minicart-product__qty-input[disabled] {
      opacity: 0.65; }
  .cs-minicart-product__qty-button {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    padding: 0;
    width: 3rem;
    height: 3rem;
    margin-left: 0.5rem; }
    .cs-minicart-product__qty-button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-minicart-product__qty-button, .cs-minicart-product__qty-button:visited {
      cursor: pointer;
      border-radius: 2px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #383838; }
      .cs-minicart-product__qty-button *, .cs-minicart-product__qty-button:visited * {
        color: #fff;
        fill: #fff; }
    .cs-minicart-product__qty-button:hover:not([disabled]), .cs-minicart-product__qty-button:focus:not([disabled]), .cs-minicart-product__qty-button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #383838; }
      .cs-minicart-product__qty-button:hover:not([disabled]) *, .cs-minicart-product__qty-button:focus:not([disabled]) *, .cs-minicart-product__qty-button:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-minicart-product__qty-button[disabled] {
      opacity: 0.65;
      cursor: auto; }
  .cs-minicart-product__qty-button-span {
    margin-top: 0;
    z-index: 1;
    font-weight: 400;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    display: none; }
  .cs-minicart-product__qty-button-icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: relative;
    top: 0.1em; }
  .cs-minicart-product__action:hover .cs-minicart-product__options-title-svg *,
  .cs-minicart-product__action:hover .cs-minicart-product__action-icon *, .cs-minicart-product__options-title:hover .cs-minicart-product__options-title-svg *,
  .cs-minicart-product__options-title:hover .cs-minicart-product__action-icon * {
    fill: #232222; }
  .cs-minicart-product__action-icon {
    width: 1.6rem;
    height: 1.6rem;
    width: 2rem;
    height: 2rem; }
  .cs-minicart-product__action-icon *, .cs-minicart-product__options-title-svg * {
    fill: #383838; }
  .cs-minicart-product__action-label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
  .cs-minicart-product__options-title:hover .cs-minicart-product__options-title-svg * {
    fill: #212121; }
  .cs-minicart-product__options-title-svg * {
    fill: #212121; }

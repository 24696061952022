/**
 * Footer component customization.
 * @see /Magento_Theme/templates/footer/columns.phtml for templates.
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-footer {
  font-size: 1.2rem;
  color: #383838;
  background: #F8F8F8;
  overflow: hidden; }
  @media (min-width: 64em) {
    .cs-footer {
      background: #fff;
      font-size: 1.6rem; } }
  .cs-footer__top {
    background: #D4E2D4;
    margin: 0 -1.5rem;
    padding: 0 37px 34px; }
    @media (min-width: 48em) {
      .cs-footer__top {
        padding: 0; } }
    @media (min-width: 64em) {
      .cs-footer__top {
        margin: 0 -6rem; } }
  @media (max-width: 47.99em) {
    .cs-footer__top-desktop {
      display: none !important; } }
  @media (min-width: 48em) {
    .cs-footer__top-mobile {
      display: none !important; } }
  @media (min-width: 48em) {
    .cs-footer__top-cols {
      display: flex;
      max-width: 1920px;
      margin: 0 auto; } }
  .cs-footer__top-cols:after {
    content: "";
    clear: both;
    display: block; }
  @media (max-width: 47.99em) {
    .cs-footer__top-col {
      padding: 0 9px;
      width: calc(50% + 9px);
      float: left;
      margin-left: -9px; } }
  @media (min-width: 48em) {
    .cs-footer__top-col {
      width: 33.3333%;
      max-width: 33.3333%;
      padding: 25px 2.5%;
      display: flex;
      order: 0; } }
  @media (min-width: 64em) {
    .cs-footer__top-col {
      padding: 45px 4.5%; } }
  @media (min-width: 80em) {
    .cs-footer__top-col {
      padding: 74px 4.5%; } }
  @media (max-width: 47.99em) {
    .cs-footer__top-col:last-child {
      margin-left: 0;
      margin-right: -9px; } }
  @media (min-width: 48em) {
    .cs-footer__top-col:last-child {
      order: 2; } }
  .cs-footer__top-col-bkg {
    background-image: url("../images/footer_quote_bkg.png");
    background-repeat: no-repeat;
    background-size: cover; }
    @media (max-width: 47.99em) {
      .cs-footer__top-col-bkg {
        padding: 22px 37px;
        margin: 0 -37px -6px;
        width: calc(100% + 74px); } }
    @media (min-width: 48em) {
      .cs-footer__top-col-bkg {
        order: 1; } }
  .cs-footer__top-block {
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15); }
    @media (max-width: 47.99em) {
      .cs-footer__top-col-bkg .cs-footer__top-block {
        box-shadow: none; } }
    @media (min-width: 48em) {
      .cs-footer__top-block {
        padding: 20px 12.3%;
        box-shadow: none; } }
    @media (min-width: 64em) {
      .cs-footer__top-block {
        padding: 30px 12.3%; } }
    @media (min-width: 80em) {
      .cs-footer__top-block {
        padding: 40px 12.3%; } }
  .cs-footer__top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 85px; }
    @media (max-width: 47.99em) {
      .cs-footer__top-content {
        text-align: center; }
        .cs-footer__top-col-bkg .cs-footer__top-content {
          min-height: 142px; } }
    @media (min-width: 48em) {
      .cs-footer__top-content {
        min-height: 246px;
        justify-content: flex-end; } }
    .cs-footer__top-content h2 {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      letter-spacing: 0.01em;
      margin: 0;
      text-transform: none; }
      @media (max-width: 47.99em) {
        .cs-footer__top-col-bkg .cs-footer__top-content h2 {
          font-size: 14px;
          line-height: 17px; } }
      @media (min-width: 48em) {
        .cs-footer__top-content h2 {
          font-size: 18px;
          line-height: 22px; } }
    .cs-footer__top-content p {
      color: #000;
      font-size: 10px;
      line-height: 12px;
      opacity: 0.18;
      margin: 7px auto 0; }
      @media (max-width: 47.99em) {
        .cs-footer__top-content p {
          max-width: 110px; } }
      @media (min-width: 48em) {
        .cs-footer__top-content p {
          font-size: 16px;
          line-height: 19px;
          margin: 28px 0 37px; } }
      @media (max-width: 47.99em) {
        .cs-footer__top-col-bkg .cs-footer__top-content p {
          font-size: 12px;
          line-height: 14px;
          max-width: 100%; } }
    .cs-footer__top-content a.cs-footer__top-link {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: #D1D1D1;
      width: 100%;
      max-width: 126px;
      padding: 10px 5px;
      border: 1px solid #CBCBCB;
      border-radius: 2px;
      text-align: center;
      text-decoration: none;
      margin-top: 9px; }
      @media (min-width: 48em) {
        .cs-footer__top-content a.cs-footer__top-link {
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.135em;
          padding: 18px;
          max-width: 232px;
          margin-top: 0; } }
      .cs-footer__top-content a.cs-footer__top-link:hover {
        background: #383838;
        color: #D1D1D1;
        text-decoration: none; }
  .cs-footer__content {
    padding: 0;
    max-width: 150rem; }
    @media (min-width: 48em) {
      .cs-footer__content {
        display: flex;
        flex-direction: column; } }
  @media (min-width: 48em) {
    .cs-footer__item--links {
      margin-top: 50px; } }
  @media (min-width: 64em) {
    .cs-footer__item--links {
      display: flex;
      margin-top: 125px;
      margin-left: -25px;
      margin-right: -25px;
      flex-basis: auto;
      flex-grow: 5; } }
  @media (min-width: 64em) {
    .cs-footer__item--links .cs-footer__section {
      padding: 0 25px; } }
  @media (min-width: 64em) {
    .cs-footer__item--extras {
      display: block;
      margin: 0 auto; } }
  .cs-footer__section {
    max-height: 100%;
    transition: max-height 0.3s;
    overflow: hidden; }
    @media (min-width: 48em) {
      .cs-footer__section {
        flex: 1; } }
    @media (max-width: 63.99em) {
      .cs-footer__section[aria-expanded=false] {
        max-height: 54px; } }
    @media (max-width: 63.99em) {
      .cs-footer__section[data-collapsible=true], .cs-footer__section[role=tab] {
        border-bottom: 1px solid #D7D5D4; } }
    @media (min-width: 64em) {
      .cs-footer__section--socials {
        margin-top: 15.5rem; } }
    .cs-footer__section--socials .cs-footer__section-title {
      display: none !important; }
    @media (min-width: 64em) {
      .cs-footer__section--stores-switcher {
        margin-top: 4rem;
        max-width: 20rem; } }
    @media (min-width: 48em) {
      .cs-footer__section--newsletter {
        display: flex;
        align-items: flex-end;
        margin-top: 60px;
        max-width: 1120px;
        margin-left: auto;
        margin-right: auto; } }
    @media (max-width: 47.99em) {
      .cs-footer__section--newsletter {
        margin: 0 -1.5rem; } }
    .cs-footer__section--newsletter .cs-footer__section-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      padding: 0 0 5px;
      color: #383838;
      flex-shrink: 0;
      padding-right: 70px; }
      @media (max-width: 47.99em) {
        .cs-footer__section--newsletter .cs-footer__section-title {
          display: none !important; } }
  .cs-footer__section-title,
  .cs-footer [data-role='title'] {
    font-family: "CF Asty Pro", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.5rem;
    color: #212121;
    font-weight: 700;
    text-transform: none;
    margin: 1rem 0 2rem;
    position: relative;
    margin: 0;
    border-top: 1px solid #888;
    padding: 2em 1.5rem;
    color: #888;
    font-size: 1.2em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.08rem; }
    @media (max-width: 47.99em) {
      .cs-footer__section[data-mage-init] .cs-footer__section-title,
      .cs-footer__section[data-collapsible] .cs-footer__section-title, .cs-footer__section[data-mage-init]
      .cs-footer [data-role='title'],
      .cs-footer__section[data-collapsible]
      .cs-footer [data-role='title'] {
        cursor: pointer;
        position: relative; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title:before, .cs-footer__section[data-mage-init] .cs-footer__section-title:after,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:before,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:after, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:before, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:after,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:before,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:after {
          content: '';
          position: absolute;
          top: 50%;
          width: 1.2rem;
          height: 0.2rem;
          left: auto;
          background-color: #888;
          transition: transform 0.3s ease-out, opacity 0.15s ease-out, background-color 0.15s linear;
          backface-visibility: hidden; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title:before, .cs-footer__section[data-mage-init] .cs-footer__section-title:after,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:before,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:after, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:before, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:after,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:before,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:after {
          right: 1.5rem;
          transform: translate3d(0, -50%, 0) !important; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title:after,
        .cs-footer__section[data-collapsible] .cs-footer__section-title:after, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title']:after,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title']:after {
          transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title[aria-expanded="true"]:before,
        .cs-footer__section[data-collapsible] .cs-footer__section-title[aria-expanded="true"]:before, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title'][aria-expanded="true"]:before,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title'][aria-expanded="true"]:before {
          transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
          opacity: 0; }
        .cs-footer__section[data-mage-init] .cs-footer__section-title[aria-expanded="true"]:after,
        .cs-footer__section[data-collapsible] .cs-footer__section-title[aria-expanded="true"]:after, .cs-footer__section[data-mage-init]
        .cs-footer [data-role='title'][aria-expanded="true"]:after,
        .cs-footer__section[data-collapsible]
        .cs-footer [data-role='title'][aria-expanded="true"]:after {
          transform: translate3d(0, -50%, 0) rotate(0) !important; }
      .cs-footer__section--socials .cs-footer__section-title, .cs-footer__section--socials
      .cs-footer [data-role='title'] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        white-space: nowrap;
        /* 1 */ } }
    @media (min-width: 48em) {
      .cs-footer__section-title,
      .cs-footer [data-role='title'] {
        border-top: none;
        padding-left: 0; } }
    @media (min-width: 64em) {
      .cs-footer__section-title,
      .cs-footer [data-role='title'] {
        padding-top: 0;
        padding-right: 0; } }
  .cs-footer__section-content,
  .cs-footer [data-role='content'] {
    padding: 0 1.5rem 1.5rem;
    font-size: 1.2em; }
    @media (max-width: 47.99em) {
      .cs-footer__section[data-mage-init] .cs-footer__section-content,
      .cs-footer__section[data-collapsible] .cs-footer__section-content, .cs-footer__section[data-mage-init]
      .cs-footer [data-role='content'],
      .cs-footer__section[data-collapsible]
      .cs-footer [data-role='content'] {
        display: none; } }
    @media (min-width: 48em) {
      .cs-footer__section-content,
      .cs-footer [data-role='content'] {
        padding-left: 0; } }
    @media (min-width: 64em) {
      .cs-footer__section-content,
      .cs-footer [data-role='content'] {
        padding-right: 0;
        padding-bottom: 0; } }
  .cs-footer__shop-logo {
    display: block;
    width: 13.5em;
    height: 2.3em;
    min-width: 13.5em;
    max-width: 100%;
    max-height: 100%; }
  .cs-footer-scrolltotop {
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    right: 15px;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    padding: 10px 12px;
    background: rgba(56, 56, 56, 0.88);
    z-index: 10;
    display: none; }
    @media (min-width: 48em) {
      .cs-footer-scrolltotop {
        width: 49px;
        height: 49px;
        padding: 14px 15px;
        right: 30px; } }
    @media (min-width: 64em) {
      .cs-footer-scrolltotop {
        bottom: 100px;
        right: 75px; } }
    @media (min-width: 80em) {
      .cs-footer-scrolltotop {
        right: 30px; } }
    .cs-footer-scrolltotop svg {
      fill: white;
      transform: rotate(-90deg); }

.cs-container--footer {
  max-width: none;
  margin-top: 15px; }
  @media (min-width: 48em) {
    .cs-container--footer {
      margin-top: 40px; } }

::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: transparent; }

::-webkit-scrollbar {
  width: 3px;
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #D2D2D2; }

@media (min-width: 48em) {
  ::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: transparent; }
  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent; }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #D2D2D2; } }

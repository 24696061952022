/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Mixins for flexible containers declaration.
 */
/**
 * Defines page container. This mixin makes sure that the content has always equal padding
 * for mobile screens on both size of the device.
 */
/**
 * Negates parent container's padding and sets it own.
 * Somethimes this has to be done to make background even to other containers' content.
 */
/**
 * Defines container wrapper that makes sure contnet doesn't extend above certain width and is always centered.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
.cs-header {
  font-size: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 301;
  transition: top 0.5s, height 0.5s;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.09);
  height: 5rem; }
  @media (min-width: 48em) {
    .cs-header {
      height: 8.3rem; } }
  @media (min-width: 64em) {
    .cs-header {
      height: 13.5rem;
      padding: 0 10px; } }
  @media (min-width: 80em) {
    .cs-header {
      padding: 0 6rem; } }
  .cs-container--top-bar + .cs-container--header .cs-header {
    top: 30px; }
  .page-wrapper-scroll .cs-container--top-bar + .cs-container--header .cs-header {
    top: 0; }
  @media (min-width: 64em) {
    .page-wrapper-scroll .cs-header {
      height: 9.6rem; } }
  .cs-header__content {
    height: 5rem;
    z-index: 300;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    will-change: z-index, height, position;
    background-color: #fff;
    position: relative;
    max-width: 180rem;
    margin: 0 auto;
    top: 0; }
    @media (min-width: 48em) {
      .cs-header__content {
        height: 8.3rem; } }
    @media (min-width: 64em) {
      .cs-header__content {
        flex-wrap: nowrap;
        height: 13.5rem;
        padding-top: 56px;
        padding-bottom: 15px;
        position: relative;
        transition: padding-top 0.5s; }
        .page-wrapper-scroll .cs-header__content {
          padding-top: 15px; } }
    @media (min-width: 64em) {
      .page-wrapper-scroll .cs-header__content {
        height: 9.6rem; } }
  .cs-header__offcanvas-toggle {
    height: 100%;
    flex: 0 1 4rem;
    order: 0; }
    @media (max-width: 63.99em) {
      .cs-header__offcanvas-toggle-account {
        order: 2; } }
    @media (min-width: 64em) {
      .cs-header__offcanvas-toggle-account {
        display: none; } }
  .cs-header__logo {
    display: flex;
    flex: 1 1;
    margin-bottom: 0;
    height: 100%;
    padding: 0.7rem 0;
    align-items: center; }
    @media (max-width: 63.99em) {
      .cs-header__logo {
        order: 1; } }
    @media (min-width: 64em) {
      .cs-header__logo {
        flex: 0 1 auto;
        padding: 0 14px;
        order: 2;
        width: 185px;
        flex-shrink: 0;
        position: relative; }
        .page-wrapper-scroll .cs-header__logo {
          position: static; } }
    .page-print .cs-header__logo {
      max-width: 160rem;
      width: 100%;
      margin: 0 auto;
      position: relative;
      height: auto;
      padding: 2rem 0; }
      .page-print .cs-header__logo:after {
        content: '';
        display: table;
        clear: both; }
  .cs-header__links-prefix {
    order: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: auto; }
    @media (max-width: 63.99em) {
      .cs-header__links-prefix {
        display: none !important; } }
    .page-wrapper-scroll .cs-header__links-prefix {
      display: none !important; }
    @media (min-width: 80em) {
      .cs-header__links-prefix .cs-offcanvas-navigation__item {
        padding: 0;
        margin-right: 20px; } }
    @media (min-width: 90em) {
      .cs-header__links-prefix .cs-offcanvas-navigation__item {
        margin-right: 40px; } }
    @media (min-width: 120em) {
      .cs-header__links-prefix .cs-offcanvas-navigation__item {
        margin-right: 70px; } }
  .cs-header__links-suffix {
    order: 3;
    display: flex;
    align-items: center;
    justify-content: space-around; }
    @media (max-width: 63.99em) {
      .cs-header__links-suffix {
        display: none !important; } }
    .page-wrapper-scroll .cs-header__links-suffix {
      display: none !important; }
    @media (min-width: 80em) {
      .cs-header__links-suffix .cs-offcanvas-navigation__item {
        padding: 0;
        margin-left: 20px; } }
    @media (min-width: 90em) {
      .cs-header__links-suffix .cs-offcanvas-navigation__item {
        margin-left: 40px; } }
    @media (min-width: 120em) {
      .cs-header__links-suffix .cs-offcanvas-navigation__item {
        margin-left: 70px; } }
  .cs-header__language {
    margin-right: 10px;
    margin-left: auto; }
    @media (max-width: 63.99em) {
      .cs-header__language {
        order: 1; } }
    @media (min-width: 64em) {
      .cs-header__language {
        order: 4; } }
    .page-wrapper-scroll .cs-header__language {
      display: none !important; }
  .cs-header__b2b-items {
    display: none; }
    @media (min-width: 64em) {
      .cs-header__b2b-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 25px;
        right: 0;
        width: 193px; }
        .page-wrapper-scroll .cs-header__b2b-items {
          display: none !important; } }
  .cs-header__b2b-item--b2b_switcher {
    color: #3F3F41;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.08em;
    text-transform: uppercase; }
    .cs-header__b2b-item--b2b_switcher .b2b-common-text {
      margin-right: 8px; }
    .cs-header__b2b-item--b2b_switcher .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 12px; }
      .cs-header__b2b-item--b2b_switcher .switch .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #E7E7E7;
        -webkit-transition: .4s;
        transition: .4s; }
        .cs-header__b2b-item--b2b_switcher .switch .slider:before {
          position: absolute;
          content: "";
          height: 12px;
          width: 12px;
          left: 0;
          bottom: 0;
          background-color: #383838;
          -webkit-transition: .4s;
          transition: .4s; }
        .cs-header__b2b-item--b2b_switcher .switch .slider.round {
          border-radius: 34px; }
          .cs-header__b2b-item--b2b_switcher .switch .slider.round:before {
            border-radius: 50%; }
      .cs-header__b2b-item--b2b_switcher .switch input {
        opacity: 0;
        width: 0;
        height: 0; }
        .cs-header__b2b-item--b2b_switcher .switch input:checked + .slider {
          background-color: #488fe4; }
          .cs-header__b2b-item--b2b_switcher .switch input:checked + .slider:before {
            -webkit-transform: translateX(18px);
            -ms-transform: translateX(18px);
            transform: translateX(18px); }
  .cs-header__b2b-item--logout {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.03em;
    color: #3F3F41;
    padding-right: 20px;
    position: relative;
    white-space: nowrap; }
    .cs-header__b2b-item--logout:after {
      content: "";
      background-image: url("../images/power.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 11px;
      height: 10px;
      position: absolute;
      top: 1px;
      right: 0;
      display: block; }
  .cs-header__search {
    display: flex;
    width: 100%;
    background-color: #fff;
    padding: 0 1.5rem 1.5rem;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 300;
    top: 100%; }
    @media (max-width: 63.99em) {
      .cs-header__search {
        box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.09); } }
    @media (min-width: 64em) {
      .cs-header__search {
        position: absolute;
        padding: 0;
        top: 15px;
        left: auto;
        width: 431px;
        transform: translateX(0); }
        .page-wrapper-scroll .cs-header__search {
          top: 50%;
          transform: translate(-50%, -50%);
          width: 521px;
          left: 50%; }
        .cs-header__b2b-items + .cs-header__search {
          right: 218px; }
        .page-wrapper-scroll .cs-header__b2b-items + .cs-header__search {
          right: 0; } }
  .cs-header-search__close {
    display: none; }
  .cs-header__user-nav {
    display: flex;
    justify-content: flex-end; }
  .cs-header__back-button {
    border-right: 1px solid #dcdcdc;
    height: 100%;
    display: flex;
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
    min-width: 4rem; }
    @media (min-width: 23.125em) {
      .cs-header__back-button {
        padding: 0 1.5rem; } }
    @media (min-width: 48em) {
      .cs-header__back-button {
        display: none; } }
  .cs-header__back-button-icon {
    fill: #888;
    height: 2.5rem; }
    @media (max-width: 23.115em) {
      .cs-header__back-button-icon {
        height: 2rem; } }

form.amform-form .action.primary {
  cursor: pointer;
  background: #383838;
  border-color: #383838;
  color: #fff;
  padding: 0.75em 1em; }
  form.amform-form .action.primary:hover {
    background: #383838;
    border-color: #383838; }

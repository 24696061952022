/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-footer-links {
  margin: 0;
  padding: 0;
  list-style: none; }
  .cs-footer-links__item {
    margin: 0 0 0.3rem;
    position: relative; }
    @media (max-width: 63.99em) {
      .cs-footer-links__item {
        margin: 0; } }
    @media (max-width: 63.99em) {
      .cs-footer-links__item:last-child {
        padding-bottom: 18px; } }
  .cs-footer-links__item-flex {
    display: flex; }
    @media (min-width: 64em) {
      .cs-footer-links__item-flex {
        margin-left: -5px;
        margin-right: -5px; } }
    .cs-footer-links__item-flex img {
      padding-right: 10px; }
      @media (min-width: 64em) {
        .cs-footer-links__item-flex img {
          width: 33.3333%;
          padding: 0 5px; } }
  .cs-footer-links__item-button a {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    width: 100%;
    align-items: center;
    display: flex !important;
    justify-content: center;
    height: 45px;
    border: 1px solid #383838;
    border-radius: 4px;
    margin-top: 12px; }
    @media (min-width: 48em) {
      .cs-footer-links__item-button a {
        height: 58px; } }
    .cs-footer-links__item-button a:hover, .cs-footer-links__item-button a:active {
      text-decoration: none !important; }
  .cs-footer-links a, .cs-footer-links a:visited {
    color: #383838;
    text-decoration: none; }
  .cs-footer-links a:hover, .cs-footer-links a:focus, .cs-footer-links a:active, .cs-footer-links a:visited:hover, .cs-footer-links a:visited:focus {
    color: #383838;
    text-decoration: underline; }
  .cs-footer-links a, .cs-footer-links a:visited {
    display: block;
    padding: 0.5em 0;
    font-size: inherit; }
    @media (min-width: 64em) {
      .cs-footer-links a, .cs-footer-links a:visited {
        padding: 0;
        display: inline-block; } }
  .cs-footer-links-title {
    color: #383838;
    font-size: 14px;
    line-height: 17px;
    text-transform: none;
    font-weight: 400;
    margin: 0 0 20px;
    letter-spacing: 0.03em; }
    @media (max-width: 63.99em) {
      .cs-footer-links-title {
        margin: 0;
        padding: 18px 0; } }
    @media (min-width: 48em) {
      .cs-footer-links-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.04em;
        text-transform: uppercase; } }
    .cs-footer-links-title[data-role='title'] {
      border-top: 0 none; }
      .cs-footer .cs-footer-links-title[data-role='title'] {
        color: #383838;
        font-size: 14px;
        line-height: 17px;
        text-transform: none;
        font-weight: 400;
        margin: 0 0 20px;
        letter-spacing: 0.03em; }
        @media (max-width: 63.99em) {
          .cs-footer .cs-footer-links-title[data-role='title'] {
            margin: 0;
            padding: 18px 0; } }
        @media (min-width: 48em) {
          .cs-footer .cs-footer-links-title[data-role='title'] {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.04em;
            text-transform: uppercase; } }
        @media (min-width: 64em) {
          .cs-footer .cs-footer-links-title[data-role='title'] {
            padding-bottom: 0; } }
      @media (max-width: 63.99em) {
        .cs-footer-links-title[data-role='title']::after {
          content: "";
          width: 10px;
          height: 10px;
          border-style: solid;
          border-color: #062033;
          border-width: 0 2px 2px 0;
          position: absolute;
          transform: rotate(225deg);
          transition: transform 0.3s;
          top: 10px;
          right: 2px; } }
      .cs-footer-links-title[data-role='title']:not([role=tab])::after {
        display: none; }
    @media (max-width: 63.99em) {
      .cs-footer-links-title[aria-expanded=false] + .cs-footer-links {
        max-height: 0; } }
    @media (max-width: 63.99em) {
      .cs-footer-links-title[aria-expanded=false]::after {
        transform: rotate(45deg);
        top: 5px; } }
    @media (max-width: 63.99em) {
      .cs-footer__section[data-collapsible=true] .cs-footer-links-title::after,
      .cs-footer__section[role=tab] .cs-footer-links-title::after {
        content: "";
        width: 10px;
        height: 10px;
        border-style: solid;
        border-color: #383838;
        border-width: 0 1px 1px 0;
        position: absolute;
        transform: rotate(45deg);
        transition: transform 0.3s;
        top: 18px;
        right: 15px; } }
    @media (max-width: 63.99em) {
      .cs-footer__section[data-collapsible=true] .cs-footer-links-title[aria-expanded=true]::after {
        transform: rotate(225deg);
        top: 24px; } }
    @media (max-width: 63.99em) {
      .cs-footer__section[aria-expanded=true] .cs-footer-links-title::after {
        transform: rotate(225deg);
        top: 24px; } }
    .cs-footer__item--promo .cs-footer-links-title {
      margin-bottom: 10px; }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/*
 * Because price-box is used in multiple components like tile, buybox etc,
 * we need to move variables to separate file to have possibility to include them
 * in each component without generate price-box component styles multiple times
*/
.cs-autocomplete {
  width: 100%;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
  z-index: 200;
  margin-top: -1px; }
  .cs-autocomplete .autocomplete-list dd, .cs-autocomplete__item {
    background: #fff;
    padding: 0.5rem 1rem;
    margin: 0;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    transition: background-color 0.15s linear;
    font-size: 1.29rem; }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-autocomplete .autocomplete-list dd, .cs-autocomplete__item {
    padding: 1rem; } }
    .cs-autocomplete .autocomplete-list dd:hover, .cs-autocomplete .autocomplete-list dd.selected, .cs-autocomplete__item:hover, .cs-autocomplete__item.selected {
      background-color: #f0f0f0;
      cursor: pointer; }
    .cs-autocomplete .autocomplete-list dd:last-child, .cs-autocomplete__item:last-child {
      border-bottom: none; }
    .cs-autocomplete .autocomplete-list dd-image-container, .cs-autocomplete__item-image-container {
      padding: 0;
      margin-right: 1rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
    .cs-autocomplete .autocomplete-list dd-image, .cs-autocomplete__item-image {
      width: 4.5rem;
      height: 4.5rem; }
    .cs-autocomplete .autocomplete-list dd-details, .cs-autocomplete__item-details {
      flex: 1 1 100%;
      margin: 0; }
      .cs-autocomplete .autocomplete-list dd-details .f-fix, .cs-autocomplete__item-details .f-fix {
        height: 5.8rem;
        display: flex;
        flex-direction: column;
        justify-content: center; }
      .cs-autocomplete .autocomplete-list dd-details img, .cs-autocomplete__item-details img {
        display: block; }
    .cs-autocomplete .autocomplete-list dd-brand, .cs-autocomplete__item-brand {
      font-size: 1.2rem;
      color: #888; }
    .cs-autocomplete .autocomplete-list dd-name, .cs-autocomplete__item-name {
      font-size: 1.29rem;
      color: #000;
      font-weight: 700; }
    .cs-autocomplete .autocomplete-list dd-price, .cs-autocomplete__item-price {
      margin: 0.3rem 0 0; }
      .notLoggedin .cs-autocomplete .autocomplete-list dd-price, .notLoggedin .cs-autocomplete__item-price {
        display: none !important;
        opacity: 0 !important;
        width: 0 !important;
        height: 0 !important;
        overflow: hidden !important; }
  .cs-autocomplete .price-box {
    display: flex; }
    .cs-autocomplete .price-box > .price {
      color: #324dcf;
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0; }
    .cs-autocomplete .price-box .old-price > .price {
      text-decoration: line-through;
      color: #696969;
      font-size: 1.5rem;
      font-weight: 700; }
    .cs-autocomplete .price-box .special-price > .price {
      color: #e41f12;
      font-size: 1.5rem;
      font-weight: 700; }
    .cs-autocomplete .price-box .minimal-price-link {
      display: none; }
    .cs-autocomplete .price-box .old-price {
      order: -1; }
      .cs-autocomplete .price-box .old-price .price {
        margin-right: 1rem; }
  .cs-autocomplete__badges {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 1rem; }
  .cs-autocomplete__badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: skew(-15deg);
    background-color: #383838;
    color: #fff;
    border: 0;
    border-radius: 2px;
    padding: 0 0.7rem 0 0.3rem;
    font-size: 1.1rem;
    font-weight: 700;
    min-height: 2em;
    text-transform: uppercase; }
    .cs-autocomplete__badge:before {
      content: '';
      width: 1rem;
      position: absolute;
      min-height: inherit;
      height: 100%;
      top: 0;
      left: 0;
      background-color: inherit;
      transform: skew(15deg);
      border-radius: 2px 0 0 2px;
      border: inherit;
      border-right: 0;
      transform-origin: bottom left; }
    .ie11 .cs-autocomplete__badge:after {
      content: '';
      min-height: auto;
      font-size: 0; }
    .cs-autocomplete__badge + .cs-autocomplete__badge {
      margin-top: 0.5rem; }
    .cs-autocomplete__badge--popular {
      background-color: #37b841;
      color: #fff; }
      .cs-autocomplete__badge--popular .cs-autocomplete__badge-text {
        line-height: normal;
        transform: skew(15deg); }
        .cs-autocomplete__badge--popular .cs-autocomplete__badge-text + .cs-autocomplete__badge--popular .cs-autocomplete__badge-text {
          margin-left: 0.5rem; }
    .cs-autocomplete__badge--free-shipping {
      background-color: #37b841;
      color: #fff; }
      .cs-autocomplete__badge--free-shipping .cs-autocomplete__badge-text {
        line-height: normal;
        transform: skew(15deg); }
        .cs-autocomplete__badge--free-shipping .cs-autocomplete__badge-text + .cs-autocomplete__badge--free-shipping .cs-autocomplete__badge-text {
          margin-left: 0.5rem; }
  .cs-autocomplete__badge-text {
    line-height: normal;
    transform: skew(15deg); }
    .cs-autocomplete__badge-text + .cs-autocomplete__badge-text {
      margin-left: 0.5rem; }
    @media (min-width: 23.125em) {
      .cs-autocomplete__badge-text {
        white-space: nowrap; } }

.autocomplete-list {
  margin: -1px 0 1rem;
  border-top: 1px solid #dcdcdc; }
  .autocomplete-list:first-child {
    border-top: 0; }
  .autocomplete-list:last-child {
    margin-bottom: 0; }
  .autocomplete-list .amount {
    color: #888;
    font-size: 1.29rem;
    font-weight: 700;
    margin-left: 0.5rem; }
    .autocomplete-list .amount:not(:empty):after {
      content: ')'; }
    .autocomplete-list .amount:not(:empty):before {
      content: '('; }

.autocomplete-list-title {
  padding: 1.2rem 3rem 1rem 1rem;
  border-bottom: none;
  color: #888;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.29rem;
  letter-spacing: 1px;
  margin: 0; }
  .autocomplete-list-title:hover, .autocomplete-list-title.selected {
    background-color: transparent; }
  .autocomplete-list-title:not(:empty):first-child {
    border-top: transparent; }

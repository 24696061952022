/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Mixins for flexible containers declaration.
 */
/**
 * Defines page container. This mixin makes sure that the content has always equal padding
 * for mobile screens on both size of the device.
 */
/**
 * Negates parent container's padding and sets it own.
 * Somethimes this has to be done to make background even to other containers' content.
 */
/**
 * Defines container wrapper that makes sure contnet doesn't extend above certain width and is always centered.
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-addtocart {
  /* stylelint-disable */
  /* stylelint-enable */ }
  .cs-addtocart__wrapper {
    overflow: hidden; }
    @supports (clip-path: inset(0 0 0 0 round 2px)) {
      .cs-addtocart__wrapper {
        clip-path: inset(0 0 0 0 round 2px); } }
  .cs-addtocart__button {
    will-change: unset;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color; }
    .cs-addtocart__button:hover, .cs-addtocart__button:focus, .cs-addtocart__button:active {
      background-color: #383838 !important; }
    .cs-addtocart__button:before, .cs-addtocart__button:after {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      right: -1px; }
    .cs-addtocart__button:before {
      width: 3.8em;
      border-radius: 0 2px 2px 0;
      z-index: -1;
      background: linear-gradient(100deg, transparent 0%, transparent 15%, #383838 15%, #383838 100%); }
    .cs-addtocart__button:after {
      width: 3em;
      min-width: auto;
      background-color: #383838;
      transition: width 0.25s linear;
      z-index: -2;
      border-radius: 2px; }
    .cs-addtocart__button:hover:after {
      width: calc(100% + 2px); }
    .cs-addtocart__button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-addtocart__button, .cs-addtocart__button:visited {
      cursor: pointer;
      border-radius: 2px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #383838; }
      .cs-addtocart__button *, .cs-addtocart__button:visited * {
        color: #fff;
        fill: #fff; }
    .cs-addtocart__button:hover:not([disabled]), .cs-addtocart__button:focus:not([disabled]), .cs-addtocart__button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #383838; }
      .cs-addtocart__button:hover:not([disabled]) *, .cs-addtocart__button:focus:not([disabled]) *, .cs-addtocart__button:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-addtocart__button[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-addtocart__button[disabled] {
      opacity: 1; }
    .cs-addtocart__button .cs-addtocart__success-label, .cs-addtocart__button:hover .cs-addtocart__success-label, .cs-addtocart__button:focus .cs-addtocart__success-label, .cs-addtocart__button:active .cs-addtocart__success-label {
      color: #fff; }
    .cs-addtocart__button .cs-addtocart__success-icon *, .cs-addtocart__button:hover .cs-addtocart__success-icon *, .cs-addtocart__button:focus .cs-addtocart__success-icon *, .cs-addtocart__button:active .cs-addtocart__success-icon * {
      fill: #fff; }
    .cs-addtocart__button .cs-addtocart__fail-label, .cs-addtocart__button:hover .cs-addtocart__fail-label, .cs-addtocart__button:focus .cs-addtocart__fail-label, .cs-addtocart__button:active .cs-addtocart__fail-label {
      color: #fff; }
    .cs-addtocart__button .cs-addtocart__fail-icon *, .cs-addtocart__button:hover .cs-addtocart__fail-icon *, .cs-addtocart__button:focus .cs-addtocart__fail-icon *, .cs-addtocart__button:active .cs-addtocart__fail-icon * {
      fill: #fff; }
  .cs-addtocart__button-label, .cs-addtocart__success-label, .cs-addtocart__fail-label {
    margin-top: 0;
    z-index: 1;
    font-weight: 400;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-left: 0;
    padding-right: 4em;
    white-space: nowrap; }
  .cs-addtocart__configure-icon, .cs-addtocart__button-icon, .cs-addtocart__success-icon, .cs-addtocart__fail-icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em;
    max-width: none;
    max-height: none;
    width: 2.2rem;
    height: 2.2rem;
    /* stylelint-disable */
    right: 0.8em;
    /* stylelint-enable */ }
  .cs-addtocart__fail-icon {
    transition: none; }
  .cs-addtocart__button-label, .cs-addtocart__success-label {
    transition: opacity 0.15s linear, transform 1s cubic-bezier(0, 0.77, 0.18, 1); }
  .cs-addtocart__button-label {
    display: inline-block;
    transform: translate3d(0, 0, 0); }
  .cs-addtocart__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0) translate3d(-50%, -50%, 0);
    width: 40px;
    height: 10px;
    opacity: 0;
    transition: opacity 0.15s linear, transform 1s cubic-bezier(0, 0.77, 0.18, 1);
    transition-delay: 0.2s; }
    .cs-addtocart__loading-ball {
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      bottom: 0;
      background: #fff;
      border-radius: 50%; }
      .cs-addtocart__loading-ball--1 {
        left: 0; }
      .cs-addtocart__loading-ball--2 {
        left: 15px;
        animation-delay: 0.15s !important; }
      .cs-addtocart__loading-ball--3 {
        left: 30px;
        animation-delay: 0.3s !important; }
  .cs-addtocart__feedback-bg, .cs-addtocart__feedback-bg:after {
    content: '';
    position: absolute;
    left: -1em;
    top: -1px;
    bottom: -1px;
    width: calc(100% + 2.5em);
    transform: skew(-18deg) translate3d(-100%, 0, 0);
    transform-origin: 0 0;
    transition: transform 0.45s cubic-bezier(0.01, 0.65, 0.38, 1);
    background: #31a43a; }
    .cs-addtocart--no-transitions .cs-addtocart__feedback-bg, .cs-addtocart--no-transitions .cs-addtocart__feedback-bg:after {
      transition: none; }
    .cs-addtocart--fail .cs-addtocart__feedback-bg, .cs-addtocart--fail .cs-addtocart__feedback-bg:after {
      background: #cc1c10; }
  .cs-addtocart__feedback-bg:after {
    background: #37b841;
    z-index: 2;
    left: 0;
    width: 100%;
    transform: translate3d(-100%, 0, 0);
    transition-delay: 0.3s; }
    .cs-addtocart--fail .cs-addtocart__feedback-bg:after {
      background: #e41f12; }
  .cs-addtocart__success-label, .cs-addtocart__success-icon, .cs-addtocart__fail-label, .cs-addtocart__fail-icon {
    opacity: 0; }
  .cs-addtocart__success-icon, .cs-addtocart__fail-icon {
    height: 1.725em;
    transition-delay: 0.2s; }
  .cs-addtocart__success-label, .cs-addtocart__fail-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) translateX(-2em);
    transition-delay: 0.2s;
    white-space: nowrap;
    padding-right: 3.5rem; }
  .cs-product-tile__addtocart .cs-addtocart__feedback-bg, .cs-product-tile__addtocart .cs-addtocart__button-label, .cs-product-tile__addtocart .cs-addtocart__success-label, .cs-product-tile__addtocart .cs-addtocart__fail-label {
    display: none !important; }
  .cs-product-tile__addtocart .cs-addtocart__configure-icon, .cs-product-tile__addtocart .cs-addtocart__button-icon, .cs-product-tile__addtocart .cs-addtocart__success-icon, .cs-product-tile__addtocart .cs-addtocart__fail-icon {
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%); }
  .cs-addtocart__minicart {
    width: 100%;
    text-align: center; }
    .cs-addtocart__minicart--sticky, .cs-addtocart__minicart--unstick {
      max-width: 160rem;
      width: 100%;
      margin: 0 auto;
      width: 100%;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      z-index: 400;
      padding: 0;
      text-align: right;
      display: flex;
      justify-content: flex-end; }
      .cs-addtocart__minicart--sticky .ui-dialog, .cs-addtocart__minicart--unstick .ui-dialog {
        right: 0 !important; }
      .cs-addtocart__minicart--sticky .block-minicart:after, .cs-addtocart__minicart--unstick .block-minicart:after {
        right: 3.2rem !important; }
  .cs-addtocart__minicart--sticky .cs-addtocart__minicart-link,
  .cs-addtocart__minicart--unstick .cs-addtocart__minicart-link {
    text-align: center;
    padding: 2.5rem 1rem;
    background: #fff;
    box-shadow: 1px 3px 4.5rem 0 rgba(30, 48, 67, 0.34);
    animation: minicart-in 1s cubic-bezier(0, 0.77, 0.18, 1);
    position: relative; }
  .cs-addtocart__minicart--unstick .cs-addtocart__minicart-link {
    animation: minicart-out 0.5s cubic-bezier(0.55, 0.2, 0.89, 0.45); }
  .cs-addtocart__minicart-link:before {
    content: none !important; }
  .cs-addtocart__minicart-qty-badge-wrapper {
    position: absolute;
    opacity: 0;
    z-index: 600;
    transition: top 0.9s cubic-bezier(0.42, 0.31, 0.34, 0.93), left 0.9s cubic-bezier(0.42, 0.31, 0.34, 0.93);
    pointer-events: none; }
    .cs-addtocart__minicart-qty-badge-wrapper--animating {
      animation: move-qty-badge 1.2s cubic-bezier(0.42, 0.31, 0.34, 0.93);
      position: fixed;
      top: 0.5rem; }
      @media (min-width: 48em) {
        .cs-addtocart__minicart-qty-badge-wrapper--animating {
          top: 1.3rem; } }
      @media (min-width: 64em) {
        .cs-addtocart__minicart-qty-badge-wrapper--animating {
          position: absolute;
          top: 5.3rem; } }
      .cs-addtocart__minicart-qty-badge-wrapper--animating.cs-addtocart__minicart-qty-badge-wrapper--minicart-sticky {
        position: fixed;
        top: 1.8rem; }
    .cs-addtocart__minicart-qty-badge-wrapper .cs-addtocart__minicart-qty-badge {
      position: static;
      visibility: visible; }
  .cs-addtocart--loading .cs-addtocart__button-label, .cs-addtocart--done .cs-addtocart__button-label {
    opacity: 0;
    transform: translate3d(2em, 0, 0); }
  .cs-addtocart--loading .cs-addtocart__loading {
    opacity: 1;
    transform: scale(1) translate3d(-50%, -50%, 0) translateX(-1.25em); }
    .cs-addtocart--loading .cs-addtocart__loading-ball {
      animation: ball-in 1s infinite; }
  .cs-addtocart--done .cs-addtocart__button-icon {
    opacity: 0; }
  .cs-addtocart--done .cs-addtocart__feedback-bg {
    transform: skew(-18deg) translate3d(0, 0, 0); }
    .cs-addtocart--done .cs-addtocart__feedback-bg:after {
      transform: translate3d(-5.3rem, 0, 0); }
  .cs-addtocart--success .cs-addtocart__success-icon {
    opacity: 1; }
  .cs-addtocart--success .cs-addtocart__success-label {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0); }
  .cs-addtocart--fail .cs-addtocart__fail-icon {
    opacity: 1; }
  .cs-addtocart--fail .cs-addtocart__fail-label {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0); }
  .cs-addtocart--animation-done .cs-addtocart__button-label,
  .cs-addtocart--animation-done .cs-addtocart__button-icon {
    transition-delay: 0.5s; }
  .cs-addtocart--animation-done .cs-addtocart__feedback-bg {
    transition-delay: 0.2s; }
    .cs-addtocart--animation-done .cs-addtocart__feedback-bg, .cs-addtocart--animation-done .cs-addtocart__feedback-bg:after {
      transition: transform 0.4s cubic-bezier(0.86, 0.01, 0.83, 0.51); }
    .cs-addtocart--animation-done .cs-addtocart__feedback-bg:after {
      transition-delay: 0; }
  .cs-addtocart--animation-done .cs-addtocart__success-label,
  .cs-addtocart--animation-done .cs-addtocart__fail-icon {
    transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.86, 0.01, 0.83, 0.51);
    transition-delay: 0; }
  .cs-addtocart--animation-done .cs-addtocart__success-icon,
  .cs-addtocart--animation-done .cs-addtocart__fail-icon {
    transition-delay: 0; }

@keyframes ball-in {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes minicart-in {
  0% {
    transform: translate3d(0, -100%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes minicart-out {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(0, -100%, 0); } }

@keyframes move-qty-badge {
  0% {
    opacity: 1; }
  15% {
    transform: scale(1); }
  80% {
    opacity: 1;
    transform: scale(1.5); }
  100% {
    opacity: 0;
    transform: scale(1); } }
  .cs-addtocart .block-minicart {
    margin-bottom: 0; }

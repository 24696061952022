@import 'config/variables';
@import 'vendors/include-media';

.#{$ns}instagram-feed {
    &-swiper-container {
        @include media('>=tablet') {
            padding-left: 32.8% !important;
        }

        .swiper-wrapper {
            @include media('<tablet') {
                flex-wrap: wrap;
                flex-direction: column;
            }
            @include media('>=tablet') {
                transform: none;
                display: block;
                position: static;

                &::after {
                    content: "";
                    clear: both;
                    display: block;
                }
            }
        }

        .swiper-slide {
            @include media('<tablet') {
                height: calc((100% - 4px) / 2);

                /* Center slide text vertically */
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                padding: 2px;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    -o-object-fit: cover;
                    object-fit: cover;
                    font-family: 'object-fit: cover;';
                    width: 0;
                    min-width: 100%;
                    min-height: 100%;
                }
            }

            @include media('>=tablet') {
                width: 25%;
                height: auto;
                float: left;
                padding: 3px;
                display: none !important;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                    display: block !important;
                }

                &:first-child {
                    width: 32.8%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        -o-object-fit: cover;
                        object-fit: cover;
                        font-family: 'object-fit: cover;';
                        width: 0;
                        min-width: 100%;
                        min-height: 100%;
                    }
                }

                img {
                    vertical-align: top;
                    width: 100%;
                }
            }
        }
    }

    &-link {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &::before {
            background-image: url('../images/instagram_bkg_icon.png');
            background-size: auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: rgba(11, 11, 11, 0.64);
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 0.5s;
            z-index: 1
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }
}

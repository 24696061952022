@import 'config/variables';
@import 'vendors/include-media';

.#{$ns}offcanvas {
    &-tabs {
        display: flex;
        border-bottom: 1px solid rgba(33, 33, 33, 0.08);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }

    &-tab {
        width: 50%;
        text-align: center;
        background: $color_Black38;
        color: $color_white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;

        svg {
            fill: $color_white;
            margin-right: 12px;

            path {
                stroke: $color_white;
            }
        }

        &.active {
            background: $color_white;
            color: $color_Black38;

            svg {
                fill: $color_Black38;

                path {
                    stroke: $color_Black38;
                }
            }
        }
    }

    &-tabs-content {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .cs-stack-nav__trigger {
            display: none !important;
        }

        .nav.cs-stack-nav__nav {
            display: block !important;
        }
    }

    &-content {
        opacity: 0;
        height: 0;
        overflow: hidden;

        &.active {
            opacity: 1;
            z-index: 1;
            height: auto;
        }
    }

    .block-customer-login {
        @include media('<laptop') {
            margin: 0;
            padding: 0 12px;

            .fieldset {
                border: 0;
                padding: 0;
                margin: 0;
            }

            .field {
                .label {
                    display: none !important;
                }

                .input-text {
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 0.03em;
                    color: rgba(150, 150, 150, 0.8);
                    padding: 1.5rem 1.8rem;
                }

                + .field {
                    margin-top: 0.8rem;
                }
            }

            .actions-toolbar {
                .action.primary {
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.1px;
                    width: 100%;
                    border: 1px solid $color_Black38;
                    border-radius: 2px;
                    display: block;
                    text-align: center;
                    padding: 0.9rem;

                    &.login {
                        background: $color_Black38;
                        color: $color_white;
                        margin: 1.5rem 0 0;
                    }

                    &.create {
                        background: $color_white;
                        color: $color_Black38;
                        margin: 1rem 0 0;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .secondary {
                    margin-top: 20px;
                }

                .action.remind {
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 0.03em;
                    color: $color_mineshaft;
                }
            }
        }
    }

    &-tabs-close {
        &.cs-offcanvas-toggle {
            background: $color_GrayB4B4B4;
            color: $color_white;
            padding: 1.25rem;
            height: auto;

            &:after {
                display: none;
            }
        }
    }

    &-tabs-close-icon {
        background-image: url('../images/menu_close.png');
        background-position: center center;
        background-repeat: no-repeat;
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        margin: 0 8px;
    }
}

@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';

@import 'components/field/mixin';
@import 'components/button/mixin';

$input_padding: 0.715em 1em !default;
$input_border: 1px solid $color_alto !default;
$input_border-radius: $border-radius_base !default;

$newsletter_margin: 0 !default;
$newsletter_padding: 0 1.5rem !default;

$newsletter_form-max-width: 34rem !default;
$newsletter_form-height: 4.5rem !default;

$newsletter_button-type: $button_default-type !default;
$newsletter_button-icon-pos: 'only-icon' !default;

$newsletter_button-icon-width: 0.8em !default;
$newsletter_button-icon-height: 0.8em !default;

.#{$ns}newsletter {
    background: $color_white;
    margin: $newsletter_margin !important;
    width: 100%;

    @include media('>=tablet') {
        background: none;
    }

    &__description {
        width: 100%;
        padding: 36px;

        @include media('>=tablet') {
            padding: 11px 0;
        }
    }

    &__description_content {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.03em;
        max-width: 210px;
        margin: 0 auto;

        @include media('>=tablet') {
            max-width: 100%;
            font-size: 17px;
            line-height: 20px;
            text-align: left;
        }
    }

    &__form {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        @include media('>=tablet') {
            max-width: 100%;
        }
    }

    &__form-wrapper {
        display: flex;
        flex-basis: 100%;

        @include media('<tablet') {
            position: relative;
            padding: 0 1.5rem 1.5rem;

            &:after {
                content: "";
                display: block;
                bottom: 0;
                top: 30px;
                left: 0;
                right: 0;
                position: absolute;
                background: $color_GrayF8;
                bottom: 0;
            }
        }
    }

    &__field {
        flex-basis: 100%;

        @include media('<tablet') {
            position: relative;
            z-index: 1;
        }

        @include media('>=tablet') {
            padding-right: 67px;
        }
    }

    &__input {
        margin-top: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        height: $newsletter_form-height;
        margin-bottom: 0 !important;
        border-color: transparent !important;
        padding-left: 19px !important;
        padding-right: 19px !important;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07) !important;

        @include media('>=tablet') {
            height: 50px;
            box-shadow: none !important;
            border-bottom-color: rgba(0, 0, 0, 0.54) !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            background: none !important;
        }
    }

    &__label {
        @include visually-hidden();
    }

    &__actions {
        display: flex;
        align-items: stretch;
        height: $newsletter_form-height;
        width: 146px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07) !important;
        flex-shrink: 0;

        @include media('<laptop') {
            position: relative;
            z-index: 1;
        }

        @include media('>=tablet', '<laptop') {
            flex-shrink: 1;
        }

        @include media('>=tablet') {
            height: 50px;
            width: 259px;
        }
    }

    &__button {
        @include button(
            $type: $newsletter_button-type,
            $icon_pos: $newsletter_button-icon-pos
        );
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: $color_GrayF8 !important;
        background-color: $color_Black38 !important;

        @include media('>=tablet') {
            font-size: 16px;
            letter-spacing: 0.04em;
        }
    }

    &__button-icon {
        @include button-icon(
            $type: $newsletter_button-type,
            $icon_pos: $newsletter_button-icon-pos
        );

        width: $newsletter_button-icon-width;
        height: $newsletter_button-icon-height;
        margin-right: 0;
    }
}
